import addMenues_store from './components/addMenus.js'
import addRoutes_store from './components/addRoutes.js'
import addVehicle_store from './components/vehicle.js'
import addMenuCtr_store from './components/menuCtr.js'
import addUserInfo_store from './components/userInfo.js'

export default {
  menu: addMenues_store,
  route: addRoutes_store,
  vehicle: addVehicle_store,
  menuArr: addMenuCtr_store,
  user: addUserInfo_store,
}
