import store from "../../store/index";
import cryptoJs from "crypto-js";
import host from "../../api/static/host.js";
export default class Socket {
  constructor() {
    this.socketTask = null;
    this.token = null;
  }

  initEventHandle = () => {
    this.socketTask.onopen = (res) => {
      console.log("WebSocket连接已打开！", res);
      this.send(1);
    };
    //接收消息
    this.socketTask.onmessage = (res) => {
      console.log("接收：");
      const data = JSON.parse(this.decryptDes(res.data));
      if (data.operation == "ADMIN_INTERCITY_NEW_ORDER_MESSAGE") {
        store.commit("socketInfoData", { messageNumber: data.data });
      }
      if (data.operation == "ADMIN_INTERCITY_ORDER_PAY_SUCCESS") {
        console.log(data);
        store.commit("getOrderStatus", data);
      }
    };
    //监听错误
    this.socketTask.onerror = (ref) => {
      console.log("socket错误：" + ref);
    };
  };

  init(token) {
    token && (this.token = token);
    this.socketTask = new WebSocket(`${host.websocketURL}${token}`);
    this.initEventHandle();
  }

  send(operation) {
    let str = JSON.stringify({ token: this.token, operation: operation });
    this.socketTask.send(this.encryptDes(str));
  }
  // des加密
  encryptDes = (message, key = "inheritech_key") => {
    const keyHex = cryptoJs.enc.Utf8.parse(key);
    const option = { mode: cryptoJs.mode.ECB, padding: cryptoJs.pad.Pkcs7 };
    const encrypted = cryptoJs.DES.encrypt(message, keyHex, option);
    return encrypted.toString(); // 返回hex格式密文，如需返回base64格式：encrypted.toString()
  };
  // des解密
  decryptDes = (message, key = "inheritech_key") => {
    const keyHex = cryptoJs.enc.Utf8.parse(key);
    const decrypted = cryptoJs.DES.decrypt(
      message, // 若message是base64格式，则无需转16进制hex，直接传入message即可
      keyHex,
      {
        mode: cryptoJs.mode.ECB,
        padding: cryptoJs.pad.Pkcs7,
      }
    );
    return decrypted.toString(cryptoJs.enc.Utf8);
  };
}
