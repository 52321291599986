var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      ref: "select_element",
      attrs: {
        value: _vm.valueTitle,
        clearable: _vm.clearable,
        disabled: _vm.disabled,
      },
      on: { clear: _vm.clearHandle },
    },
    [
      _c(
        "el-option",
        {
          staticClass: "options",
          attrs: { value: _vm.valueTitle, label: _vm.valueTitle },
        },
        [
          _c("el-tree", {
            ref: "selectTree",
            class: _vm.multiple ? "multipleTree" : "soloTree",
            attrs: {
              id: "tree-option",
              "check-strictly": _vm.multiple,
              "show-checkbox": _vm.multiple,
              "check-on-click-node": _vm.multiple,
              "default-expand-all": _vm.multiple,
              "default-checked-keys": _vm.valueMultiple,
              accordion: _vm.accordion,
              data: _vm.companyTree,
              props: _vm.props,
              "node-key": _vm.props.value,
              "default-expanded-keys": _vm.defaultExpandedKey,
              "render-content": _vm.renderContent,
            },
            on: { "node-click": _vm.handleNodeClick, check: _vm.getKeys },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }