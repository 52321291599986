import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";
// import Layout from '@/views/layout/Home'
import EmptyLayout from "@/views/layout/EmptyLayout";
Vue.use(Router);

/**
 * 当点击本页面链接的导航，当前位置的冗余导航报错
 */
const originalPush = Router.prototype.push;

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new Router({
  routes: [
    {
      path: "/",
      redirect: "/login",
    },
    {
      path: "/login",
      component: (resolve) => require(["@/views/login/login.vue"], resolve),
      meta: {
        title: "登录",
      },
    },

    {
      path: "/404",
      component: (resolve) => require(["@/components/common/404.vue"], resolve),
      meta: {
        title: "404",
      },
    },
    {
      path: "/500",
      component: (resolve) => require(["@/components/common/500.vue"], resolve),
      meta: {
        title: "500",
      },
    },
    {
      path: "/layout",
      component: EmptyLayout,
      redirect: "noRedirect",
      children: [
        /** gps路由 */
        {
          path: "/projectTravel",
          name: "",
          component: (resolve) => require(["@/views/layout/Home.vue"], resolve),
          meta: {
            title: "",
          },
          children: [
            {
              path: "/projectTravel",
              redirect: "/projectTravel/scheduling",
            },
            {
              path: "/projectTravel/scheduling",
              component: (resolve) =>
                require(["@/components/basicDataMange/index.vue"], resolve),
              meta: {
                title: "调度中心",
              },
              children: [
                {
                  path: "/projectTravel/dispatchManage",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/scheduling/dispatchManage.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "调度任务管理",
                  },
                },
                {
                  path: "/projectTravel/vehicleScheduling",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/scheduling/vehicleScheduling.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "城际专车调度",
                  },
                },
                {
                  path: "/projectTravel/phoneOrder",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/scheduling/phoneOrder.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "电召下单",
                  },
                },
                {
                  path: "/projectTravel/phoneOrder/detial",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/scheduling/phoneOrderDetial.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "电召下单",
                  },
                },

                {
                  path: "/projectTravel/telebooking",
                  name: "telebooking",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/scheduling/telebooking.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "电召订票",
                  },
                },
              ],
            },
            {
              path: "/projectTravel/orderCenter",
              component: (resolve) =>
                require(["@/components/basicDataMange/index.vue"], resolve),
              meta: {
                title: "订单中心",
              },
              children: [
                {
                  path: "/projectTravel/ticketOrder",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/orderCenter/ticketOrder.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "城际专车售票订单",
                  },
                },
                {
                  path: "/projectTravel/carTicketOrder",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/orderCenter/carTicketOrder.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "汽车票售票订单",
                  },
                },
                {
                  path: "/projectTravel/charterOrder",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/orderCenter/charterOrder.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "包车订单",
                  },
                },
                {
                  path: "/projectTravel/CarRentalOrder",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/orderCenter/CarRentalOrder.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "租车订单",
                  },
                },
                {
                  path: "/projectTravel/customizedLineOrder",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/orderCenter/customizedLineOrder.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "定制班线订单",
                  },
                },
                {
                  path: "/projectTravel/busTravelOrder",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/orderCenter/busTravelOrder/busTravelOrder.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "公交出行订单",
                  },
                },
              ],
            },
            {
              path: "/projectTravel/accountCheckeManage",
              component: (resolve) =>
                require(["@/components/basicDataMange/index.vue"], resolve),
              meta: {
                title: "对账管理",
              },
              children: [
                {
                  path: "/projectTravel/fundAccountChecke",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/accountCheckeManage/fundAccountChecke.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "资金对账",
                  },
                },
                {
                  path: "/projectTravel/dealBill",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/accountCheckeManage/dealBill.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "交易账单",
                  },
                },
                {
                  path: "/projectTravel/divisionBill",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/accountCheckeManage/divisionBill.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "分账账单",
                  },
                },
              ],
            },
            {
              path: "/projectTravel/settlementCenter",
              component: (resolve) =>
                require(["@/components/basicDataMange/index.vue"], resolve),
              meta: {
                title: "结算中心",
              },
              children: [
                {
                  path: "/projectTravel/ticketSettlementNew",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/settlementCenter/ticketSettlementNew.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "订单结算",
                  },
                },
                {
                  path: "/projectTravel/businessSettlement",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/settlementCenter/businessSettlement.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "经营结算",
                  },
                },
                {
                  path: "/projectTravel/businessSettlementSetting",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/settlementCenter/businessSettlementSetting.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "经营结算设置",
                  },
                },
                {
                  path: "/projectTravel/ticketSettlement",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/settlementCenter/ticketSettlement.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "订单结算-旧",
                  },
                },
                {
                  path: "/projectTravel/ticketDetails",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/settlementCenter/ticketDetails.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "汽车票订票明细-旧",
                  },
                },
                {
                  path: "/projectTravel/IntercityBusTicketDetails",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/settlementCenter/IntercityBusTicketDetails.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "定制客运结算明细-旧",
                  },
                },
                {
                  path: "/projectTravel/ElectronicVoucher",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/settlementCenter/ElectronicVoucher.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "电子客运凭单",
                  },
                },
                {
                  path: "/projectTravel/billOrder",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/settlementCenter/billOrder.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "分账记录",
                  },
                },
                {
                  path: "/projectTravel/IntercityBusSettle",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/settlementCenter/IntercityBusSettle.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "定制客运单车结算",
                  },
                },
                {
                  path: "/projectTravel/callForCashDistribution",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/settlementCenter/callForCashDistribution.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "电召收现分账",
                  },
                },
              ],
            },
            {
              path: "/projectTravel/customizedPassengerTransport",
              component: (resolve) =>
                require(["@/components/basicDataMange/index.vue"], resolve),
              meta: {
                title: "报表中心",
              },
              children: [
                {
                  path: "/projectTravel/revenueProfile",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/customizedPassengerTransport/revenueProfile",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "营收概况",
                  },
                },
                {
                  path: "/projectTravel/statByRoute",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/customizedPassengerTransport/statByRoute",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "按路线统计",
                  },
                },
                {
                  path: "/projectTravel/statByMethod",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/customizedPassengerTransport/statByMethod",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "按购票方式统计",
                  },
                },
                {
                  path: "/projectTravel/distributionStatistics",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/customizedPassengerTransport/distributionStatistics.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "司机派单统计",
                  },
                },
                {
                  path: "/projectTravel/telebookingData",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/customizedPassengerTransport/telebookingData.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "电召订票",
                  },
                },
              ],
            },
            {
              path: "/projectTravel/vehicleManage",
              component: (resolve) =>
                require(["@/components/basicDataMange/index.vue"], resolve),
              meta: {
                title: "定制客运",
              },
              children: [
                {
                  path: "/projectTravel/intercityMainLine",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/vehicleManage/intercityMainLine.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "主线路管理",
                  },
                },
                {
                  path: "/projectTravel/lineManagement",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/vehicleManage/lineManagement.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "班线管理",
                  },
                },
                {
                  path: "/projectTravel/workforceManagement",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/vehicleManage/workforceManagement.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "排班管理",
                  },
                },
                {
                  path: "/projectTravel/ticketReserved",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/ticketReserved/ticketReserved.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "票位预留管理",
                  },
                },
                {
                  path: "/projectTravel/bindLine",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/vehicleManage/bindLine.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "线路绑定",
                  },
                },
                {
                  path: "/projectTravel/bindLine/addBindLine",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/vehicleManage/addBindLine/index.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "新增线路绑定",
                  },
                },
                {
                  path: "/projectTravel/onLineSeatSelection",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/vehicleManage/onLineSeatSelection.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "在线选座管理",
                  },
                },
                {
                  path: "/projectTravel/siteManagement",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/basicDataAdmin/siteManagement.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "途经点管理",
                  },
                },
              ],
            },
            {
              path: "/projectTravel/charterAdmin",
              component: (resolve) =>
                require(["@/components/basicDataMange/index.vue"], resolve),
              meta: {
                title: "包车",
              },
              children: [
                {
                  path: "/projectTravel/chaterCarDealerManage",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/charterAdmin/chaterCarDealerManage",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "车商管理",
                  },
                },
                {
                  path: "/projectTravel/chaterVehicleTypeManage",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/charterAdmin/chaterVehicleTypeManage",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "车型分类",
                  },
                },
                {
                  path: "/projectTravel/valuationManage",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/charterAdmin/valuationManage.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "计费规则",
                  },
                },
                {
                  path: "/projectTravel/stockManage",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/charterAdmin/stockManage.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "库存管理",
                  },
                },
                {
                  path: "/projectTravel/carterCityManage",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/basicDataAdmin/carterCityManage.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "包车城市",
                  },
                },
                {
                  path: "/projectTravel/carterPackageManage",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/basicDataAdmin/carterPackageManage.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "包车费用项目管理",
                  },
                },
                {
                  path: "/projectTravel/carterExpenseManage",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/basicDataAdmin/carterExpenseManage.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "计费项目",
                  },
                },
              ],
            },
            {
              path: "/projectTravel/carRentalAdmin",
              component: (resolve) =>
                require(["@/components/basicDataMange/index.vue"], resolve),
              meta: {
                title: "租车",
              },
              children: [
                {
                  path: "/projectTravel/rentalCarDealerManage",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/CarRentalAdmin/rentalCarDealerManage",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "车商管理",
                  },
                },
                {
                  path: "/projectTravel/rentalVehicleTypeManage",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/CarRentalAdmin/rentalVehicleTypeManage",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "车型分类",
                  },
                },
                {
                  path: "/projectTravel/CarRentalCityManage",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/basicDataAdmin/CarRentalCityManage.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "租车城市",
                  },
                },
                {
                  path: "/projectTravel/CarRentalPointManage",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/basicDataAdmin/CarRentalPointManage.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "租车网点",
                  },
                },
                {
                  path: "/projectTravel/carRentalValuationManage",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/CarRentalAdmin/carRentalValuationManage.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "计价管理",
                  },
                },
                {
                  path: "/projectTravel/carRentalStockManage",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/CarRentalAdmin/carRentalStockManage.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "库存管理",
                  },
                },
              ],
            },

            {
              path: "/projectTravel/customizedBusAdmin",
              component: (resolve) =>
                require(["@/components/basicDataMange/index.vue"], resolve),
              meta: {
                title: "定制班线管理",
              },
              children: [
                {
                  path: "/projectTravel/siteManage",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/customizedBusAdmin/siteManage.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "站点管理",
                  },
                },
                {
                  path: "/projectTravel/lineManage",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/customizedBusAdmin/lineManage.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "线路管理",
                  },
                },
              ],
            },
            {
              path: "/projectTravel/villagePassBusAdmin",
              component: (resolve) =>
                require(["@/components/basicDataMange/index.vue"], resolve),
              meta: {
                title: "村通客运",
              },
              children: [
                {
                  path: "/projectTravel/districtAndCountyManage",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/villagePassBusAdmin/districtAndCountyManage.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "县区管理",
                  },
                },
                {
                  path: "/projectTravel/villagePassLineManage",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/villagePassBusAdmin/villagePassLineManage.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "线路管理",
                  },
                },
                {
                  path: "/projectTravel/addMiddleSites",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/villagePassBusAdmin/addMiddleSites.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "添加停靠点",
                  },
                },
                {
                  path: "/projectTravel/middleSiteManagement",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/villagePassBusAdmin/middleSiteManagement.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "停靠点设置",
                  },
                },
              ],
            },
            {
              path: "/projectTravel/invoiceAdmin",
              component: (resolve) =>
                require(["@/components/basicDataMange/index.vue"], resolve),
              meta: {
                title: "发票管理",
              },
              children: [
                {
                  path: "/projectTravel/projectAdmin",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/invoiceAdmin/projectAdmin.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "开票项目",
                  },
                },
                {
                  path: "/projectTravel/electronicInvoiceAdmin",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/invoiceAdmin/electronicInvoiceAdmin.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "开票记录",
                  },
                },
                {
                  path: "/projectTravel/invoiceRecord",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/invoiceAdmin/invoiceRecord.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "申请记录",
                  },
                },
              ],
            },
            {
              path: "/projectTravel/basicDataAdmin",
              component: (resolve) =>
                require(["@/components/basicDataMange/index.vue"], resolve),
              meta: {
                title: "基础数据管理",
              },
              children: [
                {
                  path: "/projectTravel/vehicleTypeManage",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/basicDataAdmin/vehicleTypeManage.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "车型分类管理",
                  },
                },

                {
                  path: "/projectTravel/carDealerManage",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/basicDataAdmin/carDealerManage.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "车商管理",
                  },
                },
              ],
            },
            {
              path: "/projectTravel/securityManage",
              component: (resolve) =>
                require(["@/components/basicDataMange/index.vue"], resolve),
              meta: {
                title: "安全管理",
              },
              children: [
                {
                  path: "/projectTravel/addMonitorAreaSetting",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/securityManage/addMonitorAreaSetting.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "监测区域",
                  },
                },
              ],
            },
            {
              path: "/projectTravel/busTrip",
              component: (resolve) =>
                require(["@/components/basicDataMange/index.vue"], resolve),
              meta: {
                title: "公交出行",
              },
              children: [
                {
                  path: "/projectTravel/busTravelConfig",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/configManage/busTravelConfig.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "公交出行配置",
                  },
                },
              ],
            },
            {
              path: "/projectTravel/basicInfo",
              component: (resolve) =>
                require(["@/components/basicDataMange/index.vue"], resolve),
              meta: {
                title: "基础信息",
              },
              children: [
                {
                  path: "/projectTravel/vehicleManagement",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/vehicleManage/vehicleManagement.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "车辆管理",
                  },
                },
                {
                  path: "/projectTravel/driverRelation",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/vehicleManage/driverRelation.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "人车关系",
                  },
                },
                {
                  path: "/projectTravel/driverUser",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/userManage/driverUser.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "司机管理",
                  },
                },
              ],
            },
            {
              path: "/projectTravel/systemConfigurationManage",
              component: (resolve) =>
                require(["@/components/basicDataMange/index.vue"], resolve),
              meta: {
                title: "系统配置",
              },
              children: [
                {
                  path: "/projectTravel/rulesOptions",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/configManage/rulesOptions.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "业务规则配置",
                  },
                  children: [
                    {
                      path: "",
                      redirect: "/projectTravel/ruleConfig",
                    },
                    {
                      path: "/projectTravel/ruleConfig",
                      component: (resolve) =>
                        require([
                          "@/views/projectTravel/configManage/ruleConfig.vue",
                        ], resolve),
                      meta: {
                        keepAlive: true,
                        title: "定制客运规则",
                      },
                    },
                    {
                      path: "/projectTravel/carterConfig",
                      component: (resolve) =>
                        require([
                          "@/views/projectTravel/configManage/carterConfig.vue",
                        ], resolve),
                      meta: {
                        keepAlive: true,
                        title: "包车规则",
                      },
                    },
                    {
                      path: "/projectTravel/CarRentalConfig",
                      component: (resolve) =>
                        require([
                          "@/views/projectTravel/configManage/CarRentalConfig.vue",
                        ], resolve),
                      meta: {
                        keepAlive: true,
                        title: "租车规则",
                      },
                    },
                    {
                      path: "/projectTravel/customizedBusConfig",
                      component: (resolve) =>
                        require([
                          "@/views/projectTravel/configManage/customizedBusConfig.vue",
                        ], resolve),
                      meta: {
                        keepAlive: true,
                        title: "定制班线规则",
                      },
                    },
                    {
                      path: "/projectTravel/villagePassConfig",
                      component: (resolve) =>
                        require([
                          "@/views/projectTravel/configManage/villagePassConfig.vue",
                        ], resolve),
                      meta: {
                        keepAlive: true,
                        title: "村通客运规则",
                      },
                    },
                  ],
                },
                {
                  path: "/projectTravel/appletMessageConfiguration",
                  name: "appletMessageConfiguration",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/systemConfigurationManage/appletMessageConfiguration/appletMessageConfiguration.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "小程序订阅通知",
                  },
                },
                {
                  path: "/projectTravel/appletMessageConfiguration/appletMessageDetail",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/systemConfigurationManage/appletMessageConfiguration/appletMessageDetail.vue",
                    ], resolve),
                  meta: {
                    keepAlive: false,
                    title: "小程序渠道属性配置",
                  },
                },
                {
                  path: "/projectTravel/SMSTemplateConfiguration",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/systemConfigurationManage/SMSConfiguration/SMSTemplateConfiguration.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "短信模板配置",
                  },
                },
                {
                  path: "/projectTravel/SMSTemplateConfiguration/SMSServiceCode",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/systemConfigurationManage/SMSConfiguration/SMSServiceCode.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "短信业务代码配置",
                  },
                },
                {
                  path: "/projectTravel/SMSTemplateConfiguration/SMSTemplateDetail",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/systemConfigurationManage/SMSConfiguration/SMSTemplateDetail.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "短信模板详情",
                  },
                },
                {
                  path: "/projectTravel/virtualNumberConfiguration",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/systemConfigurationManage/virtualNumberConfiguration/virtualNumberConfiguration.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "虚拟号码配置",
                  },
                },
                {
                  path: "/projectTravel/officialAccountMessageTemplate",
                  name: "officialAccountMessageTemplate",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/systemConfigurationManage/officialAccountMessageTemplate/officialAccountMessageTemplate.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "服务号消息模板",
                  },
                },
                {
                  path: "/projectTravel/officialAccountMessageTemplate/officialAccountMessageDetail",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/systemConfigurationManage/officialAccountMessageTemplate/officialAccountMessageDetail.vue",
                    ], resolve),
                  meta: {
                    keepAlive: false,
                    title: "服务号渠道属性配置",
                  },
                },
                {
                  path: "/projectTravel/networkLog",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/configManage/networkLog.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "网络日志",
                  },
                },
              ],
            },
            {
              path: "/projectTravel/configManage",
              component: (resolve) =>
                require(["@/components/basicDataMange/index.vue"], resolve),
              meta: {
                title: "小程序配置",
              },
              children: [
                {
                  path: "/projectTravel/channelConfig",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/configManage/channelConfig.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "渠道配置",
                  },
                },
                {
                  path: "/projectTravel/advertisementManage",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/configManage/advertisementManage.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "广告发布",
                  },
                },
                {
                  path: "/projectTravel/agreementConfig",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/configManage/agreementConfig.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "协议配置",
                  },
                },
                {
                  path: "/projectTravel/systemTheme",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/configManage/themeManage/index.vue",
                    ], resolve),
                  meta: {
                    title: "主题配置",
                  },
                  children: [
                    {
                      path: "/projectTravel/systemTheme",
                      component: (resolve) =>
                        require([
                          "@/views/projectTravel/configManage/themeManage/themeConfiguration.vue",
                        ], resolve),
                      meta: {
                        keepAlive: true,
                      },
                    },
                    {
                      path: "/projectTravel/moduleConfiguration",
                      component: (resolve) =>
                        require([
                          "@/views/projectTravel/configManage/themeManage/moduleConfiguration.vue",
                        ], resolve),
                      meta: {
                        title: "模块配置",
                      },
                    },
                    {
                      path: "/projectTravel/operationTheme",
                      component: (resolve) =>
                        require([
                          "@/views/projectTravel/configManage/themeManage/operationTheme.vue",
                        ], resolve),
                      meta: {
                        title: "主题配置",
                      },
                    },
                  ],
                },
                {
                  path: "/projectTravel/passengerBusinessModelConfig",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/configManage/passengerBusinessModelConfig.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "用户端业务模块配置",
                  },
                },
                {
                  path: "/projectTravel/appVersion",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/appVersion/index.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "APP版本管理",
                  },
                },
              ],
            },
            {
              path: "/projectTravel/operateManage",
              component: (resolve) =>
                require(["@/components/basicDataMange/index.vue"], resolve),
              meta: {
                title: "运营",
              },
              children: [
                {
                  path: "/projectTravel/lineCollect",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/operateManage/lineCollect.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "线路征集",
                  },
                },
                {
                  path: "/projectTravel/lineRelation",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/operateManage/lineRelation.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "线路关联",
                  },
                },
                {
                  path: "/projectTravel/complaintFeedback",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/operateManage/complaintFeedback.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "投诉反馈",
                  },
                },
                {
                  path: "/projectTravel/driverPointDetail",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/operateManage/driverPointDetail.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "司机积分明细",
                  },
                },
                {
                  path: "/projectTravel/visitManage",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/operateManage/visitManage/visitManage.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "回访管理",
                  },
                },
                {
                  path: "/projectTravel/visitManage/detail",
                  name: "visitManage_detail",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/operateManage/visitManage/visitDetail.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "回访管理",
                  },
                },
                {
                  path: "/projectTravel/feedbackType",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/operateManage/feedbackType.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "反馈类型",
                  },
                },
                {
                  path: "/projectTravel/evaluationIndex",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/operateManage/evaluationIndex.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "用户评价指标",
                  },
                },
                {
                  path: "/projectTravel/evaluationManage",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/operateManage/evaluationManage.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "评价管理",
                  },
                },
                {
                  path: "/projectTravel/driverIntegralStat",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/operateManage/driverIntegralStat.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "司机积分统计",
                  },
                },
                {
                  path: "/projectTravel/feedbackDetail",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/operateManage/feedbackDetail.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "反馈详情",
                  },
                },
                {
                  path: "/projectTravel/introduceLines",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/operateManage/introduceLines.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "线路推荐",
                  },
                },
                {
                  path: "/projectTravel/helpCenter",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/operateManage/helpCenter.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "帮助中心",
                  },
                },
                {
                  path: "/projectTravel/driverConfiguration",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/systemConfigurationManage/driverConfiguration/driverConfiguration.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "司机考核配置",
                  },
                },
                {
                  path: "/projectTravel/securityConfiguration",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/systemConfigurationManage/securityConfiguration/securityConfiguration.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "安全管理配置",
                  },
                },
                {
                  path: "/projectTravel/videoCaptureSetting",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/securityManage/passengerMonitorSetting.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "乘客人数监测设置",
                  },
                },
                {
                  path: "/projectTravel/vedioCaptureRecord",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/securityManage/passengerMonitorRecord.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "乘客人数监测记录",
                  },
                },
                {
                  path: "/projectTravel/passengerMonitorInfo",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/securityManage/passengerMonitorInfo.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "乘客人数监测详情",
                  },
                },
                {
                  path: "/projectTravel/vehicleCheckConfig",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/securityManage/vehicleCheckConfig.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "车辆例检配置",
                  },
                },
                {
                  path: "/projectTravel/examinationTimeoutRecord",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/securityManage/examinationTimeoutRecord/examinationTimeoutRecord.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "例检超时记录",
                  },
                },
                {
                  path: "/projectTravel/trainInspectionRecord",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/securityManage/trainInspectionRecord/trainInspectionRecord.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "车辆例检记录",
                  },
                },
              ],
            },
            {
              path: "/projectTravel/userManage",
              component: (resolve) =>
                require(["@/components/basicDataMange/index.vue"], resolve),
              meta: {
                title: "用户",
              },
              children: [
                {
                  path: "/projectTravel/platformUser",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/userManage/platformUser.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "平台用户管理",
                  },
                },
              ],
            },
            //9.0新增菜单排班调度
            {
              path: "/projectTravel/shiftSchedulingManagement",
              component: (resolve) =>
                require(["@/components/basicDataMange/index.vue"], resolve),
              meta: {
                title: "排班调度",
              },
              children: [
                {
                  path: "/projectTravel/dispatchManagement",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/scheduling/dispatchManagement.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "调度",
                  },
                },
                {
                  path: "/projectTravel/dispatchManagement/shiftLogs",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/scheduling/shiftLogs.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "调度 / 班次日志",
                  },
                },
                {
                  path: "/projectTravel/historyQuery",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/scheduling/historyQuery.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "售票派车查询",
                  },
                },
                {
                  path: "/projectTravel/historyQuery/telebooking",
                  name: "telebookingNew",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/scheduling/telebookingChangeTicket.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "改签",
                  },
                },
                {
                  path: "/projectTravel/schedulingManagement",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/vehicleManage/workforceManagement.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "排班管理",
                  },
                },
                {
                  path: "/projectTravel/charterDispatch",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/scheduling/charterDispatch.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "包车调度",
                  },
                },
                {
                  path: "/projectTravel/CarRentalDispatch",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/scheduling/CarRentalDispatch.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "租车调度",
                  },
                },
              ],
            },
            {
              path: "/projectTravel/financialManagement",
              component: (resolve) =>
                require(["@/components/basicDataMange/index.vue"], resolve),
              meta: {
                title: "财务",
              },
              children: [
                {
                  path: "/projectTravel/passengerVoucher",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/financialManagement/passengerVoucher.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "客运凭单",
                  },
                },
                {
                  path: "/projectTravel/divisionBillManage",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/financialManagement/divisionBillManage.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "分账账单",
                  },
                },
                {
                  path: "/projectTravel/merchantConfiguration",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/systemConfigurationManage/merchantConfiguration/merchantConfiguration.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "商户配置",
                  },
                },
                {
                  path: "/projectTravel/platformConfiguration",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/systemConfigurationManage/platformConfiguration/platformConfiguration.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "分账配置",
                  },
                },
                {
                  path: "/projectTravel/payFlow",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/settlementCenter/payFlow.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "支付流水",
                  },
                },
                {
                  path: "/projectTravel/afterSaleRefund",
                  component: (resolve) =>
                    require([
                      "@/views/projectTravel/settlementCenter/afterSaleRefund.vue",
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: "售后退款",
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
});

const addStorageEvent = function (k, val) {
  const newStorageEvent = document.createEvent("StorageEvent");
  localStorage.setItem(k, val);
  // 初始化创建的事件
  newStorageEvent.initStorageEvent(
    "setItem",
    false,
    false,
    k,
    null,
    val,
    null,
    null
  );
  // 派发对象
  window.dispatchEvent(newStorageEvent);
};

// 全局路由守卫
NProgress.configure({
  showSpinner: false,
});
router.beforeEach((to, from, next) => {
  NProgress.start();
  if (to.path == "/login") {
    next();
  } else {
    var token = sessionStorage.getItem("token");
    //如果没登录,都导向登录页
    if (!token) {
      if (to.path !== "/login") {
        next({
          path: "/login",
        });
      } else {
        next();
      }
    } else {
      next();
    }
  }
});
router.afterEach((to) => {
  if (sessionStorage.getItem("menuList")) {
    let menuLists = [JSON.parse(sessionStorage.getItem("menuList"))];
    Vue.prototype.arrayRecursion(menuLists, to.path);
  }
  if (to.path.indexOf("projectTravel") > -1) {
    addStorageEvent("YH_BF_THEME", "theme-project-gps");
    sessionStorage.setItem("system", 0);
  }
  if (to.path) {
    window.sessionStorage.setItem("routeName", to.path.substr(1));
  }
  NProgress.done();
});
export default router;
