<template>
  <el-select
    ref="select_element"
    :value="valueTitle"
    :clearable="clearable"
    :filterable="searchable"
    :filter-method="filterCompany"
    placeholder="请选择"
    class="companySelectForSearch"
    @focus="filterReset"
    @clear="resetForm"
  >
    <el-option :value="valueTitle" :label="valueTitle" class="options">
      <el-tree
        id="tree-option"
        ref="selectTree"
        :show-checkbox="multiple"
        :data="companyList"
        :props="props"
        :node-key="props.value"
        :default-expanded-keys="defaultExpandedKey"
        :filter-node-method="filterCompanyNode"
        @check="getKeys"
        @node-click="handleNodeClick"
      ></el-tree>
    </el-option>
  </el-select>
</template>

<script>
/**
 * 企业选择组件，
 * 可多选可单选可模糊搜索，多选时父子联动
 * 父子联动 传选中的最上层id    一般用于搜索不做回显
 */
import { getCompanyTree } from "@/api/lib/api.js";
export default {
  props: {
    /* 配置项 */
    props: {
      type: Object,
      default: () => {
        return {
          value: "id", // ID字段名
          label: "name", // 显示名称
          children: "children", // 子级字段名
        };
      },
    },
    companyDisabled: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    /* 可清空选项 */
    clearable: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    /* 多选 */
    multiple: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    searchable: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  created() {
    this.getCompanyTreeNode();
  },

  data() {
    return {
      valueId: null, // 初始值
      valueTitle: "",
      defaultExpandedKey: [],
      companyList: [],
      checkedNodes: [],
    };
  },

  methods: {
    /** 获取公司树形结构 */
    getCompanyTreeNode() {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
            /** 默认展开所有 */
            this.setDefaultExpandedKey(res.data); // 设置默认选中
            this.initScroll();
          }
        }
      );
    },
    /** 默认展开所有 */
    setDefaultExpandedKey(res) {
      for (let i = 0; i < res.length; i++) {
        const element = res[i];
        this.defaultExpandedKey.push(element[this.props.value]); // 设置默认展开
        if (element.children) {
          element.children.map((item) => {
            this.defaultExpandedKey.push(item[this.props.value]); // 设置默认展开
          });
        }
      }
    },

    /** 多选时checked点击事件 */
    getKeys(data, checked) {
      const checkedKeys = [];
      if (checked.checkedNodes.length !== 0) {
        self.checkedNodes = [...checked.checkedNodes];
        if (checked.checkedNodes.length === 1) {
          this.valueTitle = checked.checkedNodes[0].name;
          checkedKeys.push(checked.checkedNodes[0].id);
        } else {
          let str = "";
          checked.checkedNodes.map((item) => {
            str += `${item.name},`;
            const index = self.checkedNodes.findIndex(
              (element) => item.pid === element.id || item.pId === element.id
            );
            if (index < 0) {
              checkedKeys.push(item.id);
            }
          });
          this.valueTitle = str;
        }
      } else {
        this.valueTitle = "";
      }
      this.$emit("getValue", checkedKeys);
    },

    /** 初始化滚动条 */
    initScroll() {
      this.$nextTick(() => {
        const scrollWrap = document.querySelectorAll(
          ".companySelectForSearch .el-scrollbar .el-select-dropdown__wrap"
        )[0];
        const scrollBar = document.querySelectorAll(
          ".companySelectForSearch .el-scrollbar .el-scrollbar__bar"
        );
        scrollWrap.style.cssText =
          "margin: 0px; max-height: 600px; overflow: hidden;";
        scrollBar.forEach((ele) => (ele.style.width = 0));
      });
    },

    /** 切换选项 */
    handleNodeClick(node) {
      /** 非多选情况下才执行nodeclick事件 */
      if (!this.multiple) {
        this.valueTitle = node[this.props.label];
        this.valueId = node[this.props.value];
        this.$emit("getValue", this.valueId);
        this.$emit("getName", this.valueTitle);
        this.defaultExpandedKey = [];
        this.$refs.select_element.blur();
      }
    },

    /** 公司可多选与可单选切换时，清空input */
    resetForm() {
      this.valueTitle = "";
      this.valueId = null;
      this.defaultExpandedKey = [];
      this.clearSelected();
      this.$emit("getValue", null);
      this.$emit("getName", null);
    },
    /* 清空选中样式 */
    clearSelected() {
      this.$refs.selectTree.setCheckedNodes([]); // 设置默认选中
      const allNode = document.querySelectorAll("#tree-option .el-tree-node");
      allNode.forEach((element) => element.classList.remove("is-current"));
    },
    /** 车队搜索方法 */
    filterCompany(value) {
      this.$refs.selectTree.filter(value);
    },
    /** 车队的筛选 */
    filterCompanyNode(value, data, node) {
      const _array = []; // 这里使用数组存储 只是为了存储值。返回其子元素
      if (!value) return true;
      this.getReturnNode(node, _array, value);
      let result = false;
      _array.forEach((item) => {
        result = result || item;
      });
      return result;
    },
    /** 筛选子元素 */
    getReturnNode(node, _array, value, type) {
      let isPass = "";
      isPass = node.data.name.indexOf(value) !== -1;
      _array.push(isPass || "");
      if (!isPass && node.level !== 1 && node.parent) {
        this.getReturnNode(node.parent, _array, value);
      }
    },
    /** 车队搜索框失焦事件 */
    filterReset() {
      if (!this.valueTitle) {
        this.$refs.selectTree.filter("");
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 0;
  overflow: hidden;
  overflow-y: auto;
}
.el-select-dropdown__item.selected {
  font-weight: normal;
}
ul li >>> .el-tree .el-tree-node__content {
  height: auto;
  padding: 0 20px;
}
.el-tree-node__label {
  font-weight: normal;
}
.soloTree >>> .is-current > .el-tree-node__content > .el-tree-node__label {
  /* color: #059370; */
  font-weight: 700;
}
.soloTree >>> .is-current .el-tree-node__children .el-tree-node__label {
  /* color: #059370; */
  font-weight: normal;
}
</style>
