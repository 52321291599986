<template>
  <!-- 公用的操作弹窗组件 -->
  <div class="operationPopup">
    <!-- content容器 -->
    <div class="manual-content" :style="'height:' + boxHeight + 'px'" :class="[popupNum == 3 ? 'specific-width' :'']">
      <!-- header -->
      <div class="header">
        <div class="text">{{ headerText }}</div>
        <div class="close" @click="closeFun()"></div>
      </div>
      <!-- content -->
      <!-- 城际专车调度---批量配车确认弹框显示 -->
      <div v-if="popupNum == 1" class="content content-1">
        <div class="box">
          <div class="row">
            <div class="row-left">线路：</div>
            <div class="row-right">
              {{ operAtionData.startStation }} - {{ operAtionData.endStation }}
            </div>
          </div>
          <div class="row">
            <div class="row-left">发车时间：</div>
            <div class="row-right">
              {{
                operAtionData.departureDate + " " + operAtionData.departureTime
              }}
            </div>
          </div>
          <div class="row">
            <div class="row-left">订单数量：</div>
            <div class="row-right">{{ operAtionData["ORDERNUM"] }}</div>
          </div>
          <div class="row">
            <div class="row-left">乘客人数：</div>
            <div class="row-right">{{ operAtionData["PEOPRENUM"] }}人</div>
          </div>
          <div class="row">
            <div class="row-left">指派车辆：</div>
            <div class="row-right">
              <el-select v-model="carValue" placeholder="请选择">
                <el-option
                  v-for="items in carList"
                  :key="items.id"
                  :label="items.licensePlateNumber"
                  :value="items.id"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <!-- 人车关系 --- 新增 -->
      <div v-if="popupNum == 2" class="content content-2">
        <div class="row">
          <div class="row-left"><span>*</span>司机姓名</div>
          <div class="row-right">
            <el-select
              v-model="driverForm.driverIds"
              :disabled="driverDisabled"
              placeholder="请选择"
              filterable
              @change="driverChange"
            >
              <el-option
                v-for="items in driverNameList"
                :key="items.id"
                :label="items.userName"
                :value="{ value: items.id, companyId: items.companyId }"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="row">
          <div class="row-left"><span>*</span>机动司机</div>
          <div class="row-right">
            <el-select
              v-model="driverForm.isFlexible"
              placeholder="请选择"
              @change="MotorDriverChange"
            >
              <el-option
                v-for="items in driverList"
                :key="items.value"
                :label="items.label"
                :value="items.value"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="row">
          <div class="row-left">绑定车辆</div>
          <div class="row-right">
            <el-select
              v-model="driverForm.vehicleIds"
              :disabled="bindingCarDisabled"
              multiple
              collapse-tags
              filterable
              placeholder="请选择"
              @change="bindCarChange"
            >
              <el-option
                v-for="item in bindinCarList"
                :key="item.id"
                :label="item.licensePlateNumber"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <!-- 配置管理 --- 规则配置--购票规则-编辑 -->
      <div v-if="popupNum == 3" class="content content-3">
        <div class="row">
          <div class="row-left">
            <span>*</span>
            {{
              dataObj.unitType == 8
                ? dataObj.regulationName + "（百分比值）"
                : dataObj.regulationName
            }}
          </div>
          <div class="row-right">
            <el-input
              v-if="dataObj.id === 137 || dataObj.id === 99 || dataObj.id === 3"
              v-model="dataObj.regulationValue"
              onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
              maxlength="11"
            ></el-input>
            <el-input
              v-else-if="['intercityUpdateUpDownPoint','sendDispatchOrderSms'].includes(dataObj.regulationCode)"
              v-model="dataObj.regulationValue"
              onkeyup="this.value = this.value.replace(/^0|[^\d]+/g,'');"
              maxlength="11"
            ></el-input>
            <el-input
              v-else
              v-model="dataObj.regulationValue"
              onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
              maxlength="11"
            ></el-input>
          </div>
        </div>
      </div>
      <!-- 配置管理 --- 规则配置--调度规则-编辑 -->
      <div v-if="popupNum == 5" class="content content-5">
        <div class="row">
          <div class="row-left"><span>*</span>发车前(分钟)</div>
          <div class="row-right">
            <el-input
              v-model="dataObj.departureTimeStart"
              onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
            ></el-input>
          </div>
        </div>
      </div>
      <!-- 配置管理 --- 规则配置--退票规则-添加 -->
      <div v-if="popupNum == 6" class="content content-6">
        <div class="row">
          <div class="row-left"><span>*</span>发车前时间区间起(含)</div>
          <div class="row-right">
            <el-input
              v-model="dataObj.departureTimeStart"
              disabled="disabled"
              type="number"
            ></el-input>
          </div>
        </div>
        <div class="row">
          <div class="row-left"><span>*</span>发车前时间区间止(不含)</div>
          <div class="row-right">
            <el-input
              v-model="dataObj.departureTimeEnd"
              onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
              maxlength="5"
            ></el-input>
          </div>
        </div>
        <div class="row">
          <div class="row-left"><span>*</span>收取手续费比率(百分比)</div>
          <div class="row-right">
            <el-input
              v-model="dataObj.chargeRate"
              onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
              maxlength="3"
            ></el-input>
          </div>
        </div>
      </div>
      <!-- footer -->
      <div class="footer">
        <div>
          <el-button @click="confirmFun">确认</el-button>
          <el-button @click="closeFun">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { vehicleAPI, vehicleListAPI } from "@/api/lib/api.js";
export default {
  props: {
    popupNum: {
      //  用于显示哪一个弹窗的接收值
      type: Number,
      default: undefined,
    },
    headerText: {
      //  弹窗标题文字
      type: String,
      default: "",
    },
    carList: {
      //  车辆列表
      type: Array,
      default: () => {
        return [];
      },
    },
    operAtionData: {
      // 公用的传过来可能用到的数据集
      type: [Array, Object],
      default: () => {},
    },
  },
  data() {
    return {
      driverDisabled: false,
      bindingCarDisabled: false,
      boxHeight: 0,
      carValue: "",
      driverList: [
        { value: 0, label: "否" },
        { value: 1, label: "是" },
      ],
      driverNameList: [],
      driverForm: {
        vehicleName: "",
        driverIds: "",
        isFlexible: "",
      },
      bindinCarList: [],
      dataObj: {},
    };
  },
  mounted() {
    this.dataObj = { ...this.operAtionData };
    new Promise((resolve, reject) => {
      vehicleAPI().then((res) => {
        if (res.code == "SUCCESS") {
          this.driverNameList = res.data;
          resolve();
        }
      });
    }).then(() => {
      // 获取所有司机信息
      switch (this.popupNum) {
        case 1:
          this.boxHeight = 360;
          break;
        // 2--->人车关系调度
        case 2:
          this.boxHeight = 280;
          if (this.headerText == "编辑") {
            this.driverDisabled = true;
            this.driverNameList = [
              {
                companyId: this.operAtionData.companyId,
                id: this.operAtionData.driverId,
                driveType: this.operAtionData.driveType,
                vehicleName: this.operAtionData.vehicleName,
                userName: this.operAtionData.driverName,
              },
            ];
            this.driverForm.driverIds = {
              value: this.operAtionData.driverId,
              companyId: this.operAtionData.companyId,
            };
            if (this.dataObj.vehicleIds) {
              let idArray;
              idArray = this.dataObj.vehicleIds.split(",");
              for (let i = 0; i < idArray.length; i++) {
                idArray[i] = parseInt(idArray[i]);
              }
              this.driverForm.vehicleIds = idArray;
            } else {
              this.driverForm.vehicleIds = "";
            }
            this.MotorDriverChange(this.operAtionData.isFlexible);
            this.driverChange(this.driverForm.driverIds).then(() => {
              if (this.operAtionData.vehicleName) {
                this.driverForm.vehicleName = [];
                this.operAtionData.vehicleName.split(",").map((item) => {
                  this.driverForm.vehicleName.push(item);
                });
              }
            });
            this.driverForm.isFlexible = this.operAtionData.isFlexible;
          }
          break;
        case 5:
          this.boxHeight = 200;
          break;
        case 6:
          this.boxHeight = 280;
          break;
      }
    });
  },
  methods: {
    // 机动司机下拉框change事件
    MotorDriverChange(val) {
      // 如果是否
      if (val == 0) {
        this.bindingCarDisabled = false;
      } else {
        this.bindingCarDisabled = true;
        this.driverForm.vehicleIds = [];
      }
    },
    // 司机姓名change事件
    driverChange(val) {
      return new Promise((reslove, reject) => {
        let arr = this.driverNameList.find((item) => {
          return item.id == val.value;
        });
        let obj = {};
        let item = arr.driveType || [];
        if (item.includes(3)) {
          obj = {
            companyId: val.companyId,
            childSearch: false,
            businessType: 7,
          };
        } else {
          obj = {
            companyId: val.companyId,
            childSearch: false,
          };
        }
        vehicleListAPI(obj).then((res) => {
          console.log(res);
          if (res.code == "SUCCESS") {
            this.bindinCarList = res.data;
            reslove();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },

    bindCarChange(val) {
      this.driverForm.vehicleIds = val;
    },

    // 确认按钮回调
    confirmFun() {
      let confir = {
        carValue: this.carValue,
        ...this.driverForm,
        ...this.dataObj,
      };
      // 如果为3表示是配置管理---购票规则
      if (this.popupNum == 3) {
        const Obj = {
          presellDay: {
            min: {
              val: 1,
              msg: "预售天数值不得小于1天！",
            },
            max: {
              val: 30,
              msg: "预售天数值不得大于30天！",
            },
          },
          stopTicketPresellTimeMin: {
            min: {
              val: 1,
              msg: "停售提前时间不得小于1分钟！",
            },
            max: {
              val: 300,
              msg: "停售提前时间不得大于300分钟！",
            },
          },
          ticketServicePricesKey: {
            min: {
              val: 0,
              msg: "购票服务费不得小于0元/人！",
            },
            max: {
              val: 100,
              msg: "购票服务费不得大于100元/人！",
            },
          },
          orderLockTimeMin: {
            min: {
              val: 5,
              msg: "订单锁定时间不得小于5分钟！",
            },
            max: {
              val: 30,
              msg: "订单锁定时间不得大于30分钟！",
            },
          },
          orderBuyTicketAmount: {
            min: {
              val: 1,
              msg: "人数不得小于1！",
            },
            max: {
              val: 8,
              msg: "人数不得大于8！",
            },
          },
          area: {
            min: {
              val: 10,
              msg: "区域面积不得小于10平方米！",
            },
            max: {
              val: null,
            },
          },
          isCarryingChildren: {
            min: {
              val: 0,
              msg: "携童票百分比值不得小于0！",
            },
            max: {
              val: 100,
              msg: "携童票百分比值不得大于100！",
            },
          },
          intercityUpdateUpDownPoint: {
            min: {
              val: 1,
              msg: "发车前X分钟可修改上下车点、乘车人电话不得小于1！",
            },
            max: {
              val: null,
            },
          },
          sendDispatchOrderSms: {
            min: {
              val: 1,
              msg: "发车前X分钟发送派单短信不得小于1！",
            },
            max: {
              val: null,
            },
          },
        };
        let { regulationCode: key, regulationValue: v } = this.dataObj;
        let { min, max } = Obj[key];
        if (v < min.val) {
          this.$message.error(min.msg);
        } else if (max.val !== null && v > max.val) {
          this.$message.error(max.msg);
        } else {
          this.$emit(
            "confirmFun",
            confir,
            this.bindingCarDisabled,
            this.headerText
          );
        }
      }
      // 5---调度规则--编辑
      else if (this.popupNum == 5) {
        const { departureTimeStart: start } = this.dataObj;
        start < 0
          ? this.$message.error("发车前设置不得小于5分钟！")
          : start > 4880
          ? this.$message.error("发车前设置不得大于4880分钟！")
          : this.$emit(
              "confirmFun",
              confir,
              this.bindingCarDisabled,
              this.headerText
            );
      }
      // 退票规则--编辑
      else if (this.popupNum == 6) {
        const {
          departureTimeEnd: end,
          departureTimeStart: start,
          chargeRate: rate,
        } = this.dataObj;
        end <= start
          ? this.$message.error(
              "'发车前时间区间止'需大于'发车前时间区间起'，请重新输入！"
            )
          : rate > 100
          ? this.$message.error("手续费比率不得大于100！")
          : rate < 0
          ? this.$message.error("手续费比率不得小于0！")
          : this.$emit(
              "confirmFun",
              confir,
              this.bindingCarDisabled,
              this.headerText
            );
      } else {
        // 否则就组装数据进行返回
        this.$emit(
          "confirmFun",
          {
            carValue: this.carValue,
            ...this.dataObj,
            ...this.driverForm,
          },
          this.bindingCarDisabled,
          this.headerText
        );
      }
    },
    //   关闭窗口
    closeFun() {
      this.$emit("closePopupFun", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-input {
  width: 200px;
}
.content {
  position: absolute;
  top: 60px;
  left: 0;
  width: calc(100% - 28px);
  padding: 10px 14px;
  .row {
    width: 100%;
    height: 32px;
    margin: 10px 0;
    display: flex;
    align-items: center;
    .row-left {
      width: 160px;
      height: 32px;
      text-align: right;
      line-height: 32px;
      padding-right: 14px;
      span {
        color: red;
      }
    }
    .row-right {
      width: calc(100% - 180px);
      height: 32px;
      line-height: 32px;
      span {
        display: inline-block;
        margin-right: 10px;
      }
      .content-2-input {
        margin-right: 10px;
        width: 80px;
      }
    }
  }
}
.content-1 {
  .box {
    width: 100%;
  }
}
.operationPopup {
  z-index: 777;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  .manual-content {
    width: 500px;
    background: #ffffff;
    border-radius: 6px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-height: 200px;
  }
  .specific-width{
    width: 650px;
    .row-left{
      width: 430px !important;
    }
    
  }
}
.header {
  padding: 18px 24px;
  height: 24px;
  position: absolute;
  left: 0;
  width: calc(100% - 48px);
  top: 0;
  border-bottom: 1px solid #d7d7d7;
  .text {
    margin-top: 8px;
  }
  .close {
    cursor: pointer;
    width: 12px;
    height: 12px;
    background: url("../../assets/images/home/close.png") no-repeat;
    background-size: 100%;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.footer {
  padding: 10px 24px 18px 24px;
  height: 32px;
  position: absolute;
  left: 0;
  width: calc(100% - 48px);
  border-bottom: none !important;
  border-top: 1px solid #d7d7d7;
  bottom: 0 !important;
  .el-button {
    float: right;
    margin-left: 14px;
  }
  .el-button:first-child {
    background: #336fee;
    color: #ffffff;
  }
}
</style>
