<template>
  <div :style="'min-width:' + minWidth" class="Table">
    <div :class="maskShow ? 'disabledMask' : ''"></div>
    <el-table
      ref="Table"
      :data="tableData2"
      border
      :stripe="stripe"
      :header-cell-style="tableHeaderColor"
      :header-cell-class-name="headerCellClass"
      :row-style="rowClass"
      :max-height="tableHeight"
      :show-summary="showSummary"
      :sum-text="sumText"
      :highlight-current-row="highlightCurrentRow"
      :empty-text="emptyText"
      :tree-props="treeProps"
      :summary-method="summaryMethod"
      :row-class-name="tableRowClassName"
      row-key="id"
      :span-method="spanMethod"
      @row-click="rowClick"
      @selection-change="handleSelectionChange"
      @select-all="handSelectAllChange"
      @select="handSelectAllChange"
      :class="isExpandChange ? 'expand-style' : ''"
      @expand-change="handleExpandChange"
      :expand-row-keys="expands"
      :show-expand="false"
    >
      <el-table-column type="expand" v-if="isExpand">
        <template slot-scope="scope">
          <slot name="expandScope" :scopeRow="scope.row"></slot>
        </template>
      </el-table-column>
      <el-table-column
        v-if="typeCheckbox == true"
        width="50"
        type="selection"
        align="center"
        :selectable="selectable"
      ></el-table-column>
      <el-table-column
        v-if="dragOnoff"
        align="center"
        :resizable="false"
        width="50"
        class="dds"
      >
        <i class="iconfont its_sm_tuozhuai" />
      </el-table-column>
      <el-table-column
        v-if="colClassType == true"
        :type="tableColType"
        align="center"
        :resizable="false"
        :width="colTypeWidth"
        label="序号"
        class="dds"
      ></el-table-column>
      <el-table-column
        v-if="checkedOnoff == true"
        align="center"
        width="110px"
        label="启用费用项目"
      >
        <template slot-scope="scope">
          <el-checkbox
            v-model="scope.row.checked"
            @change="
              (val) => {
                checkedBoxChange(val, scope);
              }
            "
          ></el-checkbox>
        </template>
      </el-table-column>

      <el-table-column
        v-for="(item, index) in titleName"
        :key="index"
        :align="isCenter"
        :resizable="resizable"
        :show-overflow-tooltip="showOverflowTooltip"
        :label="item.title"
        :fixed="!!item.fixed"
        :width="item.width ? item.width : undefined"
      >
        <template v-if="item.children">
          <el-table-column
            v-for="nodes in item.children"
            :key="nodes.title"
            :label="nodes.title"
            :align="isCenter"
            :show-overflow-tooltip="true"
            :resizable="resizable"
            :fixed="!!nodes.fixed"
            :width="nodes.width ? nodes.width : undefined"
          >
            <template slot-scope="scope">
              <div v-if="nodes.render">
                <renderDom
                  :row-data="scope.row"
                  :render="nodes.render"
                ></renderDom>
              </div>
              <span v-else-if="typeof nodes.SpecialJudgment === 'function'">
                {{
                  nodes.props == undefined
                    ? nodes.SpecialJudgment(scope.row)
                    : nodes.SpecialJudgment(scope.row[nodes.props])
                }}
              </span>
              <span v-else>{{ scope.row[nodes.props] }}</span>
            </template>
          </el-table-column>
        </template>
        <template v-if="item.slotType === 'header'" slot="header">
          <span>{{ item.title }}</span>
          <el-tooltip
            effect="dark"
            :open-delay="400"
            :content="item.tooltip"
            placement="top-start"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <template v-if="item.render">
            <renderDom
              :row-data="scope.row"
              :render="item.render"
              :indexs="scope.$index"
            ></renderDom>
          </template>
          <span v-else-if="typeof item.SpecialJudgment === 'function'">
            {{
              item.props == undefined
                ? item.SpecialJudgment(scope.row, index)
                : item.SpecialJudgment(scope.row[item.props])
            }}
          </span>
          <span v-else>{{ scope.row[item.props] }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="switchOnoff == true"
        align="center"
        width="110px"
        label="套餐是否包含"
      >
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.contain"
            :disabled="scope.row.showSwitch == true ? false : true"
            active-color="#13ce66"
            inactive-color="#E3E3E3"
            @click.stop.native
          ></el-switch>
        </template>
      </el-table-column>
      <!-- 展开属性和表格的fixed 不能共存 -->
      <el-table-column
        v-if="operation == true && !isExpand"
        :resizable="false"
        :width="operationWidth"
        align="center"
        :label="operationLabel"
        fixed="right"
      >
        <template slot-scope="scope">
          <slot
            :scopeRow="scopeRowFun(scope.row, tableData2.length, scope.$index)"
          ></slot>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="operation == true"
        :resizable="false"
        :width="operationWidth"
        align="center"
        :label="operationLabel"
      >
        <template slot-scope="scope">
          <slot
            :scopeRow="scopeRowFun(scope.row, tableData2.length, scope.$index)"
          ></slot>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import Sortable from "sortablejs";
export default {
  components: {
    Sortable,
    renderDom: {
      functional: true,
      props: {
        rowData: Object,
        render: {
          type: Function,
        },
        indexs: Number,
      },
      render: (h, data) => {
        return data.props.render(h, data.props.rowData, data.props.indexs);
      },
    },
  },
  props: {
    isExpand: {
      type: Boolean,
      default: false,
    },
    //合并单元格方法
    spanMethod: {
      type: Function,
      default: () => {
        return { rowspan: 1, colspan: 1 };
      },
    },
    //展开列的合计行样式修改
    isExpandChange: {
      type: Boolean,
      default: false,
    },
    //表格是否样式居中
    isCenter: {
      type: String,
      default: "center",
    },
    //是否超出宽度显示省略号
    showOverflowTooltip: {
      type: Boolean,
      default: true,
    },
    // 固定操作列
    fixed: {
      type: [Boolean, String],
      default: false,
    },
    maskShow: {
      type: Boolean,
      default: false,
    },
    checkedOnoff: {
      // 自定义复选框
      type: Boolean,
      default: false,
    },
    switchOnoff: {
      // 接收是否要启用开关
      type: Boolean,
      default: false,
    },
    operationLabel: {
      // 接收的操作栏label
      type: String,
      default: "操作",
    },
    tableData: {
      //接收的表格数据
      type: Array,
      default: [],
    },
    label: {
      //接收的表格每一列的表头文字
      type: String,
      default: "",
    },
    colClassType: {
      //用于接收是否显示类型列的布尔值
      type: Boolean,
      default: false,
    },
    dragOnoff: {
      //用于是否显示拖拽列的布尔值
      type: Boolean,
      default: false,
    },
    tableColType: {
      //用于接收的确定这一列显示的类型
      type: String,
      default: "index",
    },
    operation: {
      //用于接收是否需要打开操作栏的布尔值
      type: Boolean,
      default: false,
    },
    titleName: {
      //用于接收需要展示哪些字段的数组
      type: [Array, Object],
      default: () => {},
    },
    operationWidth: {
      //用于接收定义操作栏宽度的变量
      type: [String, Number],
      default: "",
    },
    tableHeight: {
      //用于接收定义表格高度的变量,固定表头
      type: [Number, String],
      default: undefined,
    },
    headerBackgroundColor: {
      //用于接收设定表头背景颜色变量
      type: String,
      default: "#F7F7F7",
    },
    rowBackgroundColor: {
      //用于接收设定斑马纹的背景颜色
      type: String,
      default: "#FFFFFF",
    },
    showSummary: {
      //用于接收是否在表尾显示合计行
      type: Boolean,
      default: false,
    },
    sumText: {
      //接收修改的合计行的文字
      type: String,
      default: "合计",
    },
    stripe: {
      //用于接受设置是否为斑马纹
      type: Boolean,
      default: false,
    },
    colTypeWidth: {
      //用于接收设置Type列的宽度，默认60
      type: String,
      default: "60",
    },
    typeCheckbox: {
      //是否展示checkbox的多选框，一般设置tableColType即可，此接收值为index和checkbox同时需求的情况可设置
      type: Boolean,
      default: false,
    },
    minWidth: {
      //用于表格的最小宽度设置
      type: String,
      default: "",
    },
    resizable: {
      type: Boolean,
      default: true,
    },
    highlightCurrentRow: {
      // 是否点击行高亮
      type: Boolean,
      default: false,
    },
    onOff: {
      type: Boolean,
      default: true,
    },
    isSingleChecked: {
      //复选框是否单选
      type: Boolean,
      default: false,
    },
    canSelect: {
      //复选框-判断金额>0才能选择
      type: Boolean,
      default: false,
    },
    //待派单乘客
    isPassengerChoose: {
      type: Boolean,
      default: false,
    },
    // 用于包车下的计价管理表格-选择套餐-勾选框的判断
    //是否选择框可以全选
    valuationManage: {
      type: Boolean,
      default: false,
    },
    //空数据时显示的文本内容
    emptyText: {
      type: String,
      default: "暂无数据",
    },
    //树形数据
    treeProps: {
      type: Object,
      default: () => {},
    },
    //合计方法
    summaryMethod: {
      type: Function,
      default: null,
    },
    tableRowClassName: {
      type: Function,
      default: null,
    },

    //默认展开项的数据
    expands: {
      type: Array,
      default: () => [],
    },
    //是否需要隐藏展开图标
    isNeedHiddenExpand: {
      type: Boolean,
      default: true,
    },
    //y业务需求-根据业务状态禁掉选中状态
    sendCar: {
      type: Boolean,
      default: false,
    },
    validPassengersNum: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      rowCheckArray: [],
      tableData2: [],
    };
  },
  watch: {
    tableData: {
      handler(val) {
        this.tableData2 = [...val];
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    checkedBoxChange(val, scope) {
      this.$emit("checked-change", val, scope.row);
      this.$refs["Table"].toggleRowSelection(scope.row);
    },
    scopeRowFun(val, len, e) {
      let obj = val;
      obj.DataLength = len;
      obj.$index = e;
      return obj;
    },
    tableHeaderColor() {
      return "background-color:" + this.headerBackgroundColor + ";";
    },
    rowClass: function (row, index) {
      return { "background-color": this.rowBackgroundColor };
    },
    rowClick(row, event, column) {
      if (row.orderStatus != 20 && !this.isSingleChecked) {
        this.$refs["Table"].toggleRowSelection(row);
      }
      if (event != undefined) {
        if (event.label != "操作" && !this.isSingleChecked) {
          this.$refs["Table"].toggleRowSelection(row);
          if (this.colClassType == true && this.tableColType == "selection") {
            this.$emit("row-click", row, event, column, this.rowCheckArray);
          } else {
            this.$emit("row-click", row, event, column, null);
          }
        }
      }
    },
    //展开行事件
    handleExpandChange(row, expandedRows) {
      if (this.isNeedHiddenExpand) {
        if (!row.driverIntercityOrders.length) {
          this.$refs["Table"].toggleRowExpansion(row, false);
        }
      }
    },
    handleSelectionChange(selection) {
      return
      if (!this.isSingleChecked) {
        this.$emit("select-all", selection);
        this.rowCheckArray = selection;
      }
    },
    handSelectAllChange(selection, row) {
      if (this.isSingleChecked) {
        this.$refs.Table.clearSelection(); // 清除 所有勾选项
        if (selection.length) {
          this.$refs.Table.toggleRowSelection(row, true);
          this.$emit("select-all", [row]);
        } else {
          this.$emit("select-all", []);
        }
      } else {
        this.$emit("select-all", selection);
      }
    },
    //行拖拽
    openDialog() {
      const tbody = document.querySelector(".dragContent .el-table__body-wrapper tbody");
      const _this = this;
      // Sortable的其他属性或方法使用可参考官方文档
      Sortable.create(tbody, {
        animation: 300,
        easing: "cubic-bezier(1, 0, 0, 1)",
        handle: ".its_sm_tuozhuai", // handle控制拖拽的区域,CSS样式控制
        onEnd({ newIndex, oldIndex }) {
          const currRow = _this.tableData2.splice(oldIndex, 1)[0];
          _this.tableData2.splice(newIndex, 0, currRow);
          _this.$emit("update:tableData", _this.tableData2);
          _this.$emit("dragFinsh", _this.tableData2, newIndex);
        },
      });
    },
    //复选框部分列禁用掉-false表示不可选为禁用状态
    selectable(row, index) {
      if (this.valuationManage && !this.isPassengerChoose) {
        return true;
      } else {
        if (this.canSelect) {
          let price = 0;
          if (row.orderStatus === 20) {
            price = row.payablePrices;
          } else if (row.orderStatus === 90 || row.orderStatus === 100) {
            price = row.refundTicketServicePrices;
          }
          return price > 0;
        } else if (this.isPassengerChoose) {
          return ![60, 70, 80].includes(row.orderStatus);
        } else if (this.sendCar) {
          return this.validPassengersNum <= row.surplusCarryPassengerNumber;
        } else {
          if (this.onOff) {
            return row.orderStatus === 20;
          } else {
            return true;
          }
        }
      }
    },
    headerCellClass(row) {
      if (
        row.columnIndex === 0 &&
        row.column.type == "selection" &&
        !this.valuationManage
      ) {
        return "disabledCheck";
      }
    },
    expandOrCloseAll(item, val) {
      this.$refs.Table.toggleRowExpansion(item, val);
    },
    disabledRow() {
      this.$refs.Table.setCurrentRow();
    },
  },
  mounted() {
    if(this.dragOnoff) this.openDialog();
  },
};
</script>

<style lang="scss" scoped>
.Table {
  position: relative;
}
.disabledMask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.3);
}
/deep/ .el-table {
  width: 100%;
  height: 100%;
  /deep/.el-table__body td,
  .el-table__body tr,
  .el-table__header th,
  .el-table__header tr {
    padding: 0;
    height: 40px !important;
    line-height: 40px !important;
  }
}
/* 隐藏箭头图标 */
/deep/.el-table__expand-icon {
  display: none;
}
/deep/.el-table__expand-column .el-table__cell {
  display: none;
}

/deep/.expand-style {
  /**表格展开行样式修改 */
  .el-table__body .el-table__row--level-1 {
    background: #f5f7fa !important;
  }
}
/deep/ .el-table th > .cell {
  color: #333333;
  font-weight: 400;
}
/*去除表头全选框*/
/deep/ .disabledCheck .cell .el-checkbox__inner {
  display: none;
}
/*全选框替代文本*/
/deep/.disabledCheck .cell:before {
  content: "选择";
}

.its_sm_tuozhuai:hover {
  color: #409eff;
}
.its_sm_tuozhuai:before {
  content: "\e7f4";
  font-size: 18px;
}
.demo-table-expand {
  border: 1px solid #000;
}
</style>
