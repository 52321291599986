var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "charterPopup" }, [
    _c(
      "div",
      { staticClass: "manual-content", style: "width:" + _vm.width + "px" },
      [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.headerText))]),
          _c("div", {
            staticClass: "close",
            on: {
              click: function ($event) {
                return _vm.closeFun()
              },
            },
          }),
        ]),
        _c(
          "div",
          {
            staticClass: "content content-1",
            style: { "max-height": _vm.maxHeight },
          },
          [_c("div", { staticClass: "box" }, [_vm._t("default")], 2)]
        ),
        _c("div", { staticClass: "footer" }, [
          _c(
            "div",
            [
              !_vm.disabled
                ? _c(
                    "el-button",
                    { staticClass: "enter", on: { click: _vm.confirmFun } },
                    [_vm._v(" " + _vm._s(_vm.confirmText) + " ")]
                  )
                : _vm._e(),
              _c("el-button", { on: { click: _vm.closeFun } }, [
                _vm._v(_vm._s(_vm.cancelText)),
              ]),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }