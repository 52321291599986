import Vue from "vue";
import router from "./router";
import store from "./store";
import App from "./App";
import ElementUI from "element-ui";
import echarts from "echarts";
import BUS from "./common/service/bus";

// import VueDND from 'awe-dnd';

import "element-ui/lib/theme-chalk/index.css";
import "nprogress/nprogress.css";
import "normalize.css/normalize.css";
import "./assets/style/layout.scss";
import companySelect from "@/components/treeSelect/companySelect.vue";
import companySelectForSearch from "@/components/treeSelect/companySelectForSearch.vue";
import Table from "@/components/Table/index.vue";
import ytable from "@/components/Ytable/index";
import OperAtion from "@/components/operationPopup/index.vue";
import charterPopup from "@/components/charterPopup/charterPopup.vue";
import { providerListAPI } from "@/api/lib/api.js";

import Socket from "@/common/utils/websocket.js";
const wsKet = new Socket();

if (sessionStorage.getItem("token")) {
  wsKet.init(sessionStorage.getItem("token"));
}
Vue.prototype.$socket = wsKet;

import configs from "@/api/static/host";
Vue.prototype.$configs = configs;

// 获取所有车商列表数据
Vue.prototype.providerListFun = (openBusiness = 4) => {
  let array = [];
  return new Promise((resolve) => {
    providerListAPI({ openBusiness: openBusiness }).then((res) => {
      if (res.code == "SUCCESS") {
        array = res.data;
        resolve(array);
      }
    });
  });
};

Vue.directive("enterNumber", {
  inserted: function (el) {
    el.addEventListener("keypress", function (e) {
      e = e || window.event;
      let charcode = typeof e.charCode === "number" ? e.charCode : e.keyCode;
      let re = /\d/;
      if (
        !re.test(String.fromCharCode(charcode)) &&
        charcode > 9 &&
        !e.ctrlKey
      ) {
        if (e.preventDefault) {
          e.preventDefault();
        } else {
          e.returnValue = false;
        }
      }
    });
  },
});

// 【城际专车调度、人车关系、配置管理下的弹窗组件】
Vue.component("OperAtion", OperAtion);
Vue.component("Table", Table); // 公用表格组件
Vue.component("y-table", ytable); // 公用表格组件
Vue.component("charterPopup", charterPopup);
Vue.component("companySelect", companySelect); //所属企业
Vue.component("companySelectForSearch", companySelectForSearch); //所属企业
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(BUS);
// Vue.use(VueDND)
Vue.prototype.$echarts = echarts;
Vue.prototype.listFind = function (name) {
  if (sessionStorage.getItem("Jurisdiction").indexOf(name) >= 0) {
    return true;
  } else {
    return false;
  }
};
Vue.prototype.arrayRecursion = function (ary, path) {
  ary.map((item, index) => {
    if (path.substr(15) === item["url"]) {
      sessionStorage.setItem("Jurisdiction", JSON.stringify(item.subMenuTrees));
    } else {
      if (
        Array.isArray(item["subMenuTrees"]) &&
        item["subMenuTrees"].length > 0
      ) {
        this.arrayRecursion(item["subMenuTrees"], path);
      }
    }
  });
};

// 深拷贝
Vue.prototype.deepClone = (target) => {
  // 定义一个变量
  let result;
  // 如果当前需要深拷贝的是一个对象的话
  if (typeof target === "object") {
    // 如果是一个数组的话
    if (Array.isArray(target)) {
      result = []; // 将result赋值为一个数组，并且执行遍历
      for (let i in target) {
        // 递归克隆数组中的每一项
        result.push(Vue.prototype.deepClone(target[i]));
      }
      // 判断如果当前的值是null的话；直接赋值为null
    } else if (target === null) {
      result = null;
      // 判断如果当前的值是一个RegExp对象的话，直接赋值
    } else if (target.constructor === RegExp) {
      result = target;
    } else {
      // 否则是普通对象，直接for in循环，递归赋值对象的所有值
      result = {};
      for (let i in target) {
        result[i] = Vue.prototype.deepClone(target[i]);
      }
    }
    // 如果不是对象的话，就是基本数据类型，那么直接赋值
  } else {
    result = target;
  }
  // 返回最终结果
  return result;
};

// 产生随机数方法，参数传几就生成几位
Vue.prototype.RndNum = (n) => {
  var rnd = "";
  for (var i = 0; i < n; i++) rnd += Math.floor(Math.random() * 10);
  return rnd;
};
// 普通版转换时间方法【年月日】
Vue.prototype.GMTToStr = (time) => {
  if (time == "" || time == undefined || time == null) {
    return null;
  } else {
    var date = new Date(time);
    var Str =
      date.getFullYear() +
      "-" +
      Vue.prototype.zero(date.getMonth() + 1) +
      "-" +
      Vue.prototype.zero(date.getDate());
    return Str;
  }
};
// 精细版转换时间方法【年月日-时分秒】
Vue.prototype.GMTToStrSenior = (time) => {
  if (time == "" || time == undefined || time == null) {
    return null;
  } else {
    var date = new Date(time);
    var Str =
      date.getFullYear() +
      "-" +
      Vue.prototype.zero(date.getMonth() + 1) +
      "-" +
      Vue.prototype.zero(date.getDate()) +
      " " +
      Vue.prototype.zero(date.getHours()) +
      ":" +
      Vue.prototype.zero(date.getMinutes()) +
      ":" +
      Vue.prototype.zero(date.getSeconds());
    return Str;
  }
};

// v-for生成唯一key
Vue.prototype.createdUUid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 8) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

// 补0方法
Vue.prototype.zero = (num) => {
  if (num < 10) {
    return "0" + num;
  } else {
    return num;
  }
};

// 监听localstorage的方法
Vue.prototype.$addStorageEvent = function (type, key, data) {
  if (type === 1) {
    // 创建一个StorageEvent事件
    const newStorageEvent = document.createEvent("StorageEvent");
    const storage = {
      setItem: function (k, val) {
        localStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent(
          "setItem",
          false,
          false,
          k,
          null,
          val,
          null,
          null
        );
        // 派发对象
        window.dispatchEvent(newStorageEvent);
      },
    };
    return storage.setItem(key, data);
  } else {
    // 创建一个StorageEvent事件
    const newStorageEvent = document.createEvent("StorageEvent");
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent(
          "setItem",
          false,
          false,
          k,
          null,
          val,
          null,
          null
        );
        // 派发对象
        window.dispatchEvent(newStorageEvent);
      },
    };
    return storage.setItem(key, data);
  }
};

new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
  components: { App },
  data() {
    return {
      THEME_NAME: "dark",
    };
  },
  mounted() {
    if (window.localStorage.getItem("YH_BF_THEME")) {
      this.THEME_NAME = window.localStorage
        .getItem("YH_BF_THEME")
        .replace("theme-", "");
    }
    window.addEventListener("setItem", (e) => {
      this.THEME_NAME = e.newValue.replace("theme-", "");
    });
  },
}).$mount("#app");
