/**
 * 缓存菜单信息
 *
 */
const addUserInfo = {
  state: {
    ip: '',
    port: '',
    pwd: '',
    userName: '',
    userId: '',
    lnglat: [],
    hdVideo: null,
    token: null,
    messageNumber: 0,
    orderStatus: '',
    socketStatus: true,
  },
  mutations: {
    socketFlash(state, param) {
      console.log(param);
      state.socketStatus = param
    },
    // websocket消息接受
    socketInfoData(state, param) {
      state.messageNumber = param.messageNumber
    },

    getOrderStatus(state, param) {
      console.log(param);
      state.orderStatus = param
    },
    addUserInfo(state, param) {
      if (param) {
        state.userId = param.id
        state.hdVideo = param.hdVideo
        state.userName = param.loginName;
        state.token = param.token;
      }
    },
    addUserLngLat(state, param) {
      state.lnglat =
        param && param.longitude && param.latitude
          ? [param.longitude, param.latitude]
          : [105.829757, 32.433668]
    },
    clear_user(state, param) {
      state.ip = ''
      state.port = ''
      state.pwd = ''
      state.userName = ''
      state.userId = ''
      state.lnglat = []
      state.hdVideo = null
    }
  },
  actions: {
    addUserInfo({ commit }, param) {
      commit('addUserInfo', param)
    },
    addUserLngLat({ commit }, param) {
      commit('addUserLngLat', param)
    },
    clear_user({ commit }, param) {
      commit('clear_user', param)
    }
  }
}
export default addUserInfo
