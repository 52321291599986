/**
 * 缓存车辆信息
 * author:young
 */

const addVehicle = {
  state: {
    yhsdp: null,
    interactiveArr: [], // 下发指令返回
    statusList: [],
    terminalType: {
      0: "808",
      2: "808",
      1: "905",
    },
    globalAlarmArr: [], // websocket推送的报警数据
    screenCoefficient: 1, // table宽度系数，根据屏幕分辨率适应
    terminalObj: null, //去查询终端属性的终端号obj
    terminalPropertiesList: [], //查询终端属性的结果
    terminalFilters: [], //查询终端属性的终端型号
    terminalHardFilters: [], //查询终端属性的硬件版本号
    terminalSoftFilters: [], //查询终端属性的固件版本号
    downLoadList: [], //历史视频下载列表
    downLoadLength: 0, //未下载列表数值（等待生成中，2.生成中）
    refreshDownLoad: false, //下载刷新状态,
    refreshDailog: false, //车辆面板刷新状态,
    paidOrderId: "",
  },

  mutations: {

    setRefreshDailogStatus(state, param) {
      state.refreshDailog = param
    },
    setRefreshStatus(state, param) {
      state.refreshDownLoad = param
    },
    add_yhsdp(state, param) {
      if (param) {
        state.yhsdp = param
      }
    },
    add_interactiveArr(state, param) {
      if (param) {
        /** 最多显示200条 */
        state.interactiveArr.unshift(param)
        if (state.interactiveArr > 200) {
          state.interactiveArr.splice(state.interactiveArr.length - 1, 1)
        }
      }
    },
    change_interactiveArr(state, param) {
      if (param) {
        /** 确认指令信息位置 */
        const {
          orderId,
          terminalNo,
          sTime,
          sNote
        } = param
        const index = state.interactiveArr.findIndex(
          item =>
            item.status === 0 &&
            `${terminalNo}${orderId}` === `${item.terminalNo}${item.orderId}`
        )
        if (index !== -1) {
          state.interactiveArr[index].receiveTime = sTime
          state.interactiveArr[index].sNote = sNote
          state.interactiveArr[index].status = 1
        }
      }
    },
    add_globalAlarm(state, param) {
      if (param) {
        state.globalAlarmArr.unshift(param)
      }
    },
    add_statusList(state, param) {
      state.statusList = param
    },
    clear_interactiveArr(state, param) {
      state.interactiveArr = []
    },
    clear_all(state, param) {
      state.yhsdp = null
      state.interactiveArr = []

      state.downLoadList = []
      state.downLoadLength = 0
      state.refreshDownLoad = false
      state.refreshDailog = false

      state.globalAlarmArr = []

      state.statusList = []

      state.screenCoefficient = 0
      state.terminalObj = null;
      state.terminalPropertiesList = [];
      state.terminalFilters = [];
      state.terminalHardFilters = [];
      state.terminalSoftFilters = [];

    },
    cutGlobalAlarm(state, param) {
      state.globalAlarmArr.splice(0, 1)
    },
    clearGlobalAlarm(state, param) {
      state.globalAlarmArr = []
    },

    set_screenCoefficient(state, param) {
      state.screenCoefficient = param
    },
    set_terminalObj(state, param) {

      state.terminalObj = param ? {
        ...param
      } : param;
    },
    add_terminalPropertiesList(state, param) {
      state.terminalPropertiesList.push(param);
      if (state.terminalFilters.indexOf(param.terminalModel) === -1) {
        state.terminalFilters.push(param.terminalModel)
      }
      if (state.terminalHardFilters.indexOf(param.hardwareVersion) === -1) {
        state.terminalHardFilters.push(param.hardwareVersion)
      }
      if (state.terminalSoftFilters.indexOf(param.softwareVersion) === -1) {
        state.terminalSoftFilters.push(param.softwareVersion)
      }
    },
    clear_terminalPropertiesList(state, param) {
      state.terminalPropertiesList = [];
      state.terminalFilters = [];
      state.terminalHardFilters = [];
      state.terminalSoftFilters = [];

    },
    set_downLoadList(state, param) {
      state.downLoadList = param;
    },
    set_downLoadListLength(state, param) {
      state.downLoadLength = param;
    },
    PAIDORDERID(state, paidOrderId) {
      state.paidOrderId = paidOrderId;
    }
  },
  actions: {

    setRefreshDailogStatus({
      commit
    }, param) {
      commit('setRefreshDailogStatus', param)
    },
    setRefreshStatus({
      commit
    }, param) {
      commit('setRefreshStatus', param)
    },

    add_yhsdp({
      commit
    }, param) {
      commit('add_yhsdp', param)
    },
    add_interactiveArr({
      commit
    }, param) {
      commit('add_interactiveArr', param)
    },
    change_interactiveArr({
      commit
    }, param) {
      commit('change_interactiveArr', param)
    },

    clear_interactiveArr({
      commit
    }, param) {
      commit('clear_interactiveArr', param)
    },

    clear_all({
      commit
    }, param) {
      commit('clear_all', param)
    },
    add_globalAlarm({
      commit
    }, param) {
      commit('add_globalAlarm', param)
    },
    cutGlobalAlarm({
      commit
    }, param) {
      commit('cutGlobalAlarm', param)
    },

    clearGlobalAlarm({
      commit
    }, param) {
      commit('clearGlobalAlarm', param)
    },
    add_statusList({
      commit
    }, param) {
      commit('add_statusList', param)
    },
    set_screenCoefficient({
      commit
    }, param) {
      commit('set_screenCoefficient', param)
    },
    set_terminalObj({
      commit
    }, param) {
      commit('set_terminalObj', param)
    },
    add_terminalPropertiesList({
      commit
    }, param) {
      commit('add_terminalPropertiesList', param)
    },
    clear_terminalPropertiesList({
      commit
    }, param) {
      commit('clear_terminalPropertiesList', param)
    },
    set_downLoadList({
      commit
    }, param) {
      commit('set_downLoadList', param)
    },
    set_downLoadListLength({
      commit
    }, param) {
      commit('set_downLoadListLength', param)
    },

  }
}
export default addVehicle
