<template>
  <el-select
    ref="select_element"
    :value="valueTitle"
    :clearable="clearable"
    :disabled="disabled"
    @clear="clearHandle"
  >
    <el-option :value="valueTitle" :label="valueTitle" class="options">
      <el-tree
        id="tree-option"
        ref="selectTree"
        :check-strictly="multiple"
        :show-checkbox="multiple"
        :check-on-click-node="multiple"
        :default-expand-all="multiple"
        :default-checked-keys="valueMultiple"
        :accordion="accordion"
        :data="companyTree"
        :props="props"
        :node-key="props.value"
        :default-expanded-keys="defaultExpandedKey"
        :class="multiple ? 'multipleTree' : 'soloTree'"
        :render-content="renderContent"
        @node-click="handleNodeClick"
        @check="getKeys"
      ></el-tree>
    </el-option>
  </el-select>
</template>

<script>
/**
 * 一般用于 新增__回显
 * 企业选择组件，可多选可单选，多选时父子不关联，不联动
 *
 */
export default {
  name: "el-tree-select",

  props: {
    /* 配置项 */
    props: {
      type: Object,
      default: () => {
        return {
          value: "id", // ID字段名
          label: "name", // 显示名称
          children: "children", // 子级字段名
        };
      },
    },
    /* 选项列表数据(树形结构的对象数组) */
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    /* 初始值 */
    value: {
      type: Number,
      default: () => {
        return null;
      },
    },
    companyTree: {
      type: Array,
      default: () => {
        return [];
      },
    },
    /* 初始显示 */
    valuename: {
      type: String,
      default: () => {
        return null;
      },
    },
    /* 可清空选项 */
    clearable: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    /* 自动收起 */
    accordion: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    /* 多选 */
    multiple: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    valuenameMultiple: {
      type: String,
      default: () => {
        return "";
      },
    },
    valueMultiple: {
      type: Array,
      default: () => {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledArray: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      valueId: this.value, // 初始值
      valueTitle: "",
      defaultExpandedKey: [],
    };
  },

  methods: {
    renderContent(h, { node, data }) {
      if (this.disabledArray.includes(data.id)) {
        return <div style="color:#ccc">{data.name}</div>;
      } else {
        return <div>{data.name}</div>;
      }
    },
    // 多选时checked点击事件
    getKeys(data, checked) {
      if (checked.checkedNodes.length !== 0) {
        if (checked.checkedNodes.length === 1) {
          this.valueTitle = checked.checkedNodes[0][this.props.label];
        } else {
          let str = [];
          checked.checkedNodes.map((item) => {
            str.push(`${item[this.props.label]}`);
          });
          this.valueTitle = str?.join();
        }
      } else {
        this.valueTitle = "";
      }
      if (this.multiple) {
        this.$emit("getName", this.valueTitle);
      }
      this.$emit("getValue", checked.checkedKeys);
    },
    // 初始化值
    initHandle() {
      this.valueTitle = this.valuename; // 初始化显示
      if (this.valueId) {
        this.defaultExpandedKey = [this.valueId]; // 设置默认展开
      }
      this.$refs.selectTree.setCurrentKey(this.value); // 设置默认选中
      this.$emit("getValue", this.valueId);
      this.$emit("getName", this.valueTitle);
      this.initScroll();
    },
    //多选初始化-兼容
    initHandleMultiple(val, valueMultiple) {
      this.valueTitle = val;
      this.$refs.selectTree.setCurrentKey(valueMultiple);
      this.clearSelected();
    },
    // 初始化滚动条
    initScroll() {
      this.$nextTick(() => {
        const scrollWrap = document.querySelectorAll(
          ".el-scrollbar .el-select-dropdown__wrap"
        )[0];
        const scrollBar = document.querySelectorAll(
          ".el-scrollbar .el-scrollbar__bar"
        );
        scrollWrap.style.cssText =
          "margin: 0px; max-height: 600px; overflow: hidden;";
        scrollBar.forEach((ele) => (ele.style.width = 0));
      });
    },
    // 切换选项
    handleNodeClick(node) {
      if (!this.multiple) {
        if (!this.disabledArray.includes(node.id)) {
          this.valueTitle = node[this.props.label];
          this.valueId = node[this.props.value];
          this.$emit("getValue", this.valueId, node.orgCa);
          this.$emit("getName", this.valueTitle);
          this.defaultExpandedKey = [];
          this.$refs.select_element.blur();
        }
      }
    },
    // 清除选中
    clearHandle() {
      this.valueTitle = "";
      this.valueId = null;
      this.defaultExpandedKey = [];
      this.clearSelected();
      this.$emit("getValue", this.valueId);
      this.$emit("getName", this.valueTitle);
    },
    /* 清空选中样式 */
    clearSelected() {
      this.$refs.selectTree.setCheckedNodes([]); // 设置默认选中
      const allNode = document.querySelectorAll("#tree-option .el-tree-node");
      allNode.forEach((element) => element.classList.remove("is-current"));
    },
  },

  watch: {
    valuename(val) {
      this.valueId = this.value;
      this.initHandle();
    },
    valuenameMultiple(val) {
      this.valueTitle = val;
      this.$refs.selectTree.setCurrentKey(this.valueMultiple);
      this.clearSelected();
      this.$emit("getValue", this.valueMultiple);
      this.$emit("getName", this.valueTitle);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 249px;
  padding: 0;
  overflow: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7px;
    background: transparent;
  }
  /* 自定义滚动条轨道 */
  &::-webkit-scrollbar-track {
    background: transparent !important; /* 轨道颜色 */
  }

  /* 自定义滚动条的滑块（thumb） */
  &::-webkit-scrollbar-thumb {
    background: #a4a4a4 !important; /* 滑块颜色 */
    border-radius: 4px;
  }

  /* 当hover或active状态时，自定义滑块的颜色 */
  &::-webkit-scrollbar-thumb:hover {
    background: #a4a4a4 !important;
  }
}
.el-select-dropdown__item.selected {
  font-weight: normal;
}
ul li >>> .el-tree .el-tree-node__content {
  height: auto;
  padding: 0 20px;
}
.el-tree-node__label {
  font-weight: normal;
}
.soloTree >>> .is-current > .el-tree-node__content > .el-tree-node__label {
  /* color: #059370; */
  font-weight: 700;
}
.soloTree >>> .is-current .el-tree-node__children .el-tree-node__label {
  /* color: #059370; */
  font-weight: normal;
}

/* 开发禁用 */
/* .el-tree-node:focus>.el-tree-node__content{
    background-color:transparent;
    background-color: #f5f7fa;
    color: #c0c4cc;
    cursor: not-allowed;
  }
  .el-tree-node__content:hover{
    background-color: #f5f7fa;
  } */
</style>
