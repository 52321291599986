import { Table as T } from 'element-ui'
export default {
  props: {
    ...T.props,
    border: {
      type: [Boolean, String],
      default: true
    },
    headerCellStyle: {
      type: [Object, Function],
      default: () => ({ backgroundColor: '#F7F7F7' })
    },
    columns: {
      type: Array,
      default: () => []
    },
    tooltip: {
      type: [Boolean, String],
      default: true
    }
  },
  mounted() {
    const {
      clearSelection,
      toggleRowSelection,
      toggleAllSelection,
      toggleRowExpansion,
      setCurrentRow,
      clearSort,
      clearFilter,
      doLayout,
      sort
    } = this.$refs.$inner
    this.clearSelection = clearSelection;
    this.toggleRowSelection = toggleRowSelection;
    this.toggleAllSelection = toggleAllSelection;
    this.toggleRowExpansion = toggleRowExpansion;
    this.setCurrentRow = setCurrentRow;
    this.clearSort = clearSort;
    this.clearFilter = clearFilter;
    this.doLayout = doLayout;
    this.sort = sort;
  },
  render(h) {
    let props = this._props
    let columns = [...this.columns]
    const $soped = this.$scopedSlots;
    let tooltip = this.tooltip
    const table = (
      <el-table ref="$inner" {...{ props }} {...{ on: { ...this.$listeners } }} >
        {
          columns.map(temp => {
            let item = { showOverflowTooltip: tooltip, ...temp }
            let { customRender, scopedSlots, prop, customHeader } = item;
            const slotScope = {};
            if (customRender && (typeof customRender === 'function')) {
              slotScope.scopedSlots = {
                default({ row, $index }) {
                  return h('span', customRender(row[prop], row, $index))
                },
              }
            }
            if (!!customHeader) {
              slotScope.scopedSlots = {
                header(scope) {
                  return $soped[customHeader](scope)
                },
              }
            }
            if (!!scopedSlots) {
              slotScope.scopedSlots = {
                default(scope) {
                  return $soped[scopedSlots](scope)
                },
              }
            }
            return (<el-table-column  {...{ props: item }} {...slotScope} align='center' />)
          })
        }
      </el-table>
    )
    return table
  }
}