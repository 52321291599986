import service from "./request";
//接口前缀

/* ----------------- 登录模块 ---------------------- */
// 用户登录
const login = (data) => {
  return service({
    url: `/user/admin/login`,
    method: "post",
    data,
  });
};
const logins = (data) => {
  return service({
    url: `/user/admin`,
    method: "get",
    data,
  });
};

// 用户登出
const loginOut = (data) => {
  return service({
    url: `/user/admin/logout`,
    method: "get",
  });
};

// 查询所有新闻
const queryNews = (data) => {
  return service({
    url: `/news/queryNews`,
    method: "get",
    data,
  });
};

// 获取用户设置经纬度
const getUserLnlat = (data) => {
  return service({
    url: `/company/queryCompanyByLoginCompanys`,
    method: "post",
    data,
  });
};

export { login, logins, loginOut, queryNews, getUserLnlat };

/* ----------------- 登录模块 ---------------------- */

/* ----------------- 用户模块 ---------------------- */

// 获取用户列表
const getUserList = (data) => {
  return service({
    url: "/user/admin/page",
    method: "post",
    data,
  });
};

// 禁用用户
const disableUser = (data) => {
  return service({
    url: "/user/admin/disable/" + data.id,
    method: "put",
    data,
  });
};

// 启用用户
const enableUser = (data) => {
  return service({
    url: "/user/admin/enable/" + data.id,
    method: "put",
    data,
  });
};

// 新增用户
const insertUser = (data) => {
  return service({
    url: "/user/admin",
    method: "post",
    data,
  });
};

// 修改用户信息
const updateUser = (data) => {
  return service({
    url: "/user/admin",
    method: "put",
    data,
  });
};

// 重置密码
const resetPwd = (data) => {
  return service({
    url: "/user/admin/resetPassword/" + data.id,
    method: "put",
    data,
  });
};

// 修改密码
const updatePassword = (data) => {
  return service({
    url: "/user/admin/password",
    method: "put",
    data,
  });
};

export {
  getUserList,
  disableUser,
  enableUser,
  insertUser,
  updateUser,
  resetPwd,
  updatePassword,
};

/* ----------------- 用户模块 ---------------------- */

/* ----------------- 菜单模块 ---------------------- */

// 新增菜单
const insertMenu = (data) => {
  return service({
    url: "/base/menu/add",
    method: "post",
    data,
  });
};

// 查询用户菜单树
const getMenus = (data) => {
  return service({
    url: "/base/menu/getMenus",
    method: "get",
    data,
  });
};

// 根据父级id查询菜单
const queryMenu = (data) => {
  return service({
    url: "base/menu/getByParentId",
    method: "get",
    data,
  });
};

// 删除菜单
const deleteMenu = (data) => {
  return service({
    url: `/base/menu/delete/${data}`,
    method: "delete",
  });
};

// 修改菜单
const updateMenu = (data) => {
  return service({
    url: "/base/menu/update",
    method: "put",
    data,
  });
};

export { insertMenu, getMenus, queryMenu, deleteMenu, updateMenu };

/* ----------------- 菜单模块 ---------------------- */

/* ----------------- 用户组模块 ---------------------- */

// 用户组查询
const queryUserGroup = (data) => {
  return service({
    url: "/base/userGroup/queryUserGroup",
    method: "post",
    data,
  });
};

// 用户组新增
const addUserGroup = (data) => {
  return service({
    url: "/base/userGroup/add",
    method: "post",
    data,
  });
};

// 修改用户组
const updateUserGroup = (data) => {
  return service({
    url: "/base/userGroup/update",
    method: "put",
    data,
  });
};

// 启用禁用 用户组
const updateUserGroupStatus = (data) => {
  return service({
    url: `/base/userGroup/updateStatus`,
    method: "get",
    data,
  });
};

// 获取所有用户组
const queryAllUserGroup = (data) => {
  return service({
    url: `/base/userGroup/queryAllUserGroup`,
    method: "get",
    data,
  });
};

export {
  queryUserGroup,
  addUserGroup,
  updateUserGroup,
  updateUserGroupStatus,
  queryAllUserGroup,
};

/* ----------------- 用户组模块 ---------------------- */

/* ----------------- 线路模块 ---------------------- */

// 新增线路
const insertLine = (data) => {
  return service({
    url: "/manage/line",
    method: "post",
    data,
  });
};

// 查询账号下绑定的线路
const getAllLine = (data) => {
  return service({
    url: "/manage/line/list",
    method: "get",
    data,
  });
};

// 修改线路
const updateLine = (data) => {
  return service({
    url: "/manage/line/" + data.id,
    method: "put",
    data,
  });
};

// 线路删除
const deleteLine = (data) => {
  return service({
    url: "/manage/line/" + data.id,
    method: "delete",
    data,
  });
};

// 线路禁用启用
const disableLine = (data) => {
  return service({
    url: "/manage/line/disable/" + data.id,
    method: "put",
    data,
  });
};

// 线路详情
const queryLineList = (data) => {
  return service({
    url: "/manage/line/" + data.id,
    method: "get",
    data,
  });
};

// 新增车次
const insertShift = (data) => {
  return service({
    url: "/manage/vehicle/shift",
    method: "post",
    data,
  });
};

// 修改车次
const updateShift = (data) => {
  return service({
    url: "/manage/vehicle/shift/" + data.id,
    method: "put",
    data,
  });
};

// 车次列表
const queryShiftList = (data) => {
  return service({
    url: "/manage/vehicle/shift/list",
    method: "get",
    data,
  });
};

// 车次删除
const deleteShift = (data) => {
  return service({
    url: "/manage/vehicle/shift/" + data.id,
    method: "delete",
    data,
  });
};

// 车次禁用启用
const disableShift = (data) => {
  return service({
    url: "/manage/vehicle/shift/disable/" + data.id,
    method: "put",
    data,
  });
};

// 新增区域
const insertArea = (data) => {
  return service({
    url: "/manage/line/area",
    method: "post",
    data,
  });
};

// 修改区域
const updateArea = (data) => {
  return service({
    url: "/manage/line/area/" + data.id,
    method: "put",
    data,
  });
};

// 区域列表
const queryAreaList = (data) => {
  return service({
    url: "/manage/line/area/list",
    method: "get",
    data,
  });
};
//途经点列表
const queryIntercityLineStationRelationPage = (data) => {
  return service({
    url: "/manage/intercityLineStationRelation/queryIntercityLineStationRelationPage",
    method: "post",
    data,
  });
};

// 区域删除
const deleteArea = (data) => {
  return service({
    url: "/manage/line/area/" + data.id,
    method: "delete",
    data,
  });
};

// 区域禁用启用
const disableArea = (data) => {
  return service({
    url: "/manage/line/area/disable/" + data.id,
    method: "put",
    data,
  });
};
//列表查询车站/站点/区域修改
const queryIntercityStationList = (data) => {
  return service({
    url: "/manage/intercitystation/queryIntercityStationList",
    method: "post",
    data,
  });
};
//新增途经点、
const saveIntercityLineStationRelation = (data) => {
  return service({
    url: "/manage/intercityLineStationRelation/saveIntercityLineStationRelation",
    method: "post",
    data,
  });
};
//编辑途经点
const updateIntercityLineStationRelation = (data) => {
  return service({
    url: "/manage/intercityLineStationRelation/updateIntercityLineStationRelation",
    method: "put",
    data,
  });
};
//删除途经点
const deleteMidwayStationById = (data) => {
  return service({
    url: "/manage/intercityLineStationRelation/deleteMidwayStationById",
    method: "delete",
    data,
  });
};
//下车点排序
const areaAdjustmentSerial = (data) => {
  return service({
    url: "/manage/line/area/adjustmentSerial",
    method: "post",
    data,
  });
};

export {
  insertLine,
  getAllLine,
  updateLine,
  deleteLine,
  queryLineList,
  disableLine,
  insertShift,
  updateShift,
  queryShiftList,
  deleteShift,
  disableShift,
  insertArea,
  updateArea,
  queryAreaList,
  deleteArea,
  disableArea,
  queryIntercityLineStationRelationPage,
  queryIntercityStationList,
  saveIntercityLineStationRelation,
  updateIntercityLineStationRelation,
  deleteMidwayStationById,
  areaAdjustmentSerial,
};

/* ----------------- 角色模块 ---------------------- */

/* ----------------- 企业模块 ---------------------- */

// 查询企业树（企业树）
const getCompanyTree = (data) => {
  return service({
    url: `/manage/company/tree`,
    method: "get",
    data,
  });
};

// 查询企业树（权限树--线路）
const getLineCompanyTree = (data) => {
  return service({
    url: `/manage/company/shift/tree`,
    method: "get",
    data,
  });
};

// 查询调度树（权限树--线路时间）
const getTimeCompanyTree = (data) => {
  return service({
    url: `/manage/schedule/tree`,
    method: "get",
    data,
  });
};

// 获取公司名列表
const getCompanyLabels = (data) => {
  return service({
    url: `/company/getCompanyLabelByUserId`,
    method: "get",
    data,
  });
};

// 新增企业
const insertCompany = (data) => {
  return service({
    url: `/company/add`,
    method: "post",
    data,
  });
};

// 获取企业详情
const getCompanyDetailLabels = (data) => {
  return service({
    url: `/company/getCompanyById`,
    method: "get",
    data,
  });
};

// 删除企业
const deleteCompany = (data) => {
  return service({
    url: `/company/delete/${data}`,
    method: "delete",
  });
};

// 修改企业
const updateCompany = (data) => {
  return service({
    url: `/company/update`,
    method: "put",
    data,
  });
};

//获取车队列表
const queryImportCompanyGroups = (data) => {
  return service({
    url: `/company/queryImportCompanyGroups`,
    method: "get",
    data,
  });
};
//查询行政区与站点树
const queryIntercityStationTree = (data) => {
  return service({
    url: `/manage/intercitystation/queryIntercityStationTree`,
    method: "post",
    data,
  });
};
//添加行政区划
const saveIntercityAdministrativeDivisions = (data) => {
  return service({
    url: `/manage/intercityadministrativedivisions/saveIntercityAdministrativeDivisions`,
    method: "post",
    data,
  });
};
//添加车站/站点/区域
const saveIntercityStation = (data) => {
  return service({
    url: `/manage/intercitystation/saveIntercityStation`,
    method: "post",
    data,
  });
};
//更新车站/站点/区域
const updateIntercityStation = (data) => {
  return service({
    url: `/manage/intercitystation/updateIntercityStation`,
    method: "put",
    data,
  });
};
//删除车站/站点/区域
const deleteIntercityStationById = (data) => {
  return service({
    url: `/manage/intercitystation/deleteIntercityStationById`,
    method: "delete",
    data,
  });
};
export {
  getCompanyTree,
  getCompanyLabels,
  insertCompany,
  getCompanyDetailLabels,
  deleteCompany,
  getLineCompanyTree,
  getTimeCompanyTree,
  updateCompany,
  queryImportCompanyGroups,
  queryIntercityStationTree,
  saveIntercityAdministrativeDivisions,
  saveIntercityStation,
  deleteIntercityStationById,
  updateIntercityStation,
};

/* ----------------- 企业模块 ---------------------- */

/* ----------------- 车辆模块 ---------------------- */

// 查询车辆列表
const getVehicleList = (data) => {
  return service({
    url: `/manage/vehicle/page`,
    method: "post",
    data,
  });
};

// 查询所有车辆列表
const getAllVehicleList = (data) => {
  return service({
    url: `/manage/vehicle/list`,
    method: "get",
    data,
  });
};

// 通过id查询车辆列表
const getVehicleById = (data) => {
  return service({
    url: `/manage/vehicle/${data}`,
    method: "get",
  });
};

// 新增车辆
const insertVehicle = (data) => {
  return service({
    url: `/manage/vehicle`,
    method: "post",
    data,
  });
};

// 修改车辆
const updateVehicle = (data) => {
  return service({
    url: `/manage/vehicle/${data.id}`,
    method: "put",
    data,
  });
};

// 车牌号模糊查询
const queryVehicleByCph = (data) => {
  return service({
    url: `/manage/vehicle/queryVehicleByCph`,
    method: "get",
    data,
  });
};

// 车辆附加信息查询
const getVehicleInfo = (data) => {
  return service({
    url: `/manage/vehicle/getVehicleInfo`,
    method: "get",
    data,
  });
};

// 删除车辆
const deleteVehicle = (data) => {
  return service({
    url: `/manage/vehicle/${data.id}`,
    method: "delete",
    data,
  });
};

// 根据车牌号查询its数据
const queryItsListAPI = (data) => {
  return service({
    url: `/manage/vehicle/queryItsList/${data.cph}`,
    method: "post",
    data,
  });
};

// 获取车辆状态下拉框数据API
const getItsVehicleStatusListAPI = (data) => {
  return service({
    url: `/manage/vehicle/getItsVehicleStatusList`,
    method: "get",
    data,
  });
};

// 获取车辆等级下拉框数据API
const getItsVehicleGradeListAPI = (data) => {
  return service({
    url: `/manage/vehicle/getItsVehicleGradeList`,
    method: "get",
    data,
  });
};

// 获取车辆颜色下拉框数据API
const getItsVehicleColorListAPI = (data) => {
  return service({
    url: `/manage/vehicle/getItsVehicleColorList`,
    method: "get",
    data,
  });
};

// 获取车牌颜色下拉框数据API
const getItsVehicleSignageColorListAPI = (data) => {
  return service({
    url: `/manage/vehicle/getItsVehicleSignageColorList`,
    method: "get",
    data,
  });
};

// 获取所有车辆信息
const getVehicleListAllAPI = (data) => {
  return service({
    url: `/manage/vehicle/listAll`,
    method: "post",
    data,
  });
};

// 获取所有司机信息
const getDriverListAllAPI = (data) => {
  return service({
    url: `/user/driver/list/all`,
    method: "post",
    data,
  });
};

export {
  getVehicleList,
  getAllVehicleList,
  getVehicleById,
  insertVehicle,
  updateVehicle,
  queryVehicleByCph,
  getVehicleInfo,
  deleteVehicle,
  queryItsListAPI,
  getItsVehicleStatusListAPI,
  getItsVehicleGradeListAPI,
  getItsVehicleColorListAPI,
  getItsVehicleSignageColorListAPI,
  getVehicleListAllAPI,
  getDriverListAllAPI,
};

/* ----------------- 车辆模块 ---------------------- */

/* ----------------- 调度模块 ---------------------- */

// 待配车列表
const queryUnscheduleList = (data) => {
  return service({
    url: `/manage/schedule/vehicle/list`,
    method: "get",
    data,
  });
};

// 待派单列表
const querySchedulingList = (data) => {
  return service({
    url: `/manage/schedule/driver/list`,
    method: "get",
    data,
  });
};

// 待派单列表
const querySchedulingDetail = (data) => {
  return service({
    url: `/manage/schedule/driver/detail`,
    method: "get",
    data,
  });
};

// 已派单列表
const queryScheduledList = (data) => {
  return service({
    url: `/manage/schedule/scheduled`,
    method: "get",
    data,
  });
};

// 历史订单分页
const queryScheduleHistoryList = (data) => {
  return service({
    url: `/manage/schedule/history`,
    method: "post",
    data,
  });
};

// 售后退款明细
const queryAfterSalesRefundDetailPage = (data) => {
  return service({
    url: `/manage/aftersalesrefunddetail/queryAfterSalesRefundDetailPage`,
    method: "post",
    data,
  });
};

// 售后退款明细导出
const exportAfterSalesRefundDetail = (data) => {
  return service({
    url: `/manage/aftersalesrefunddetail/exportAfterSalesRefundDetail`,
    method: "post",
    data,
    responseType: "blob",
  });
};

// 历史订单详情
const queryScheduleHistoryDetail = (data) => {
  return service({
    url: `/manage/schedule/history/detail`,
    method: "get",
    data,
  });
};

// 取消配车
const cancelVehicle = (data) => {
  return service({
    url: `/manage/schedule/vehicle/cancel`,
    method: "put",
    data,
  });
};

// 取消订单
const cancelOrder = (data) => {
  return service({
    url: `/order/intercity/scheduleCancel`,
    method: "put",
    data,
  });
};

// 退票
const cancelTicket = (data) => {
  return service({
    url: `/order/intercity/server/refund`,
    method: "put",
    data,
  });
};

// 配车
const scheduleVehicle = (data) => {
  return service({
    url: `/manage/schedule/vehicle`,
    method: "put",
    data,
  });
};

// 派单
const scheduleDriver = (data) => {
  return service({
    url: `/manage/schedule/driver`,
    method: "put",
    data,
  });
};

// 查询司机和乘客位置
const getSchedulePositions = (data) => {
  return service({
    url: `/manage/schedule/positions`,
    method: "get",
    data,
  });
};

// 删除司机订单
const deleteDriverOrder = (data) => {
  return service({
    url: `/manage/schedule/driver/order/` + data.id,
    method: "delete",
  });
};

// 电召下单线路查询
const searchLineAPI = (data) => {
  return service({
    url: "/manage/line/chooseByPermissions",
    method: "get",
    data,
  });
};

// 电召下单班次时间查询
const searchShiftTimeAPI = (data) => {
  return service({
    url: "/manage/shift/intercity/v2/timetable",
    method: "get",
    data,
  });
};

// 电召下单提交订单
const placeOrderAPI = (data) => {
  return service({
    url: "/order/intercity/order",
    method: "post",
    data,
  });
};

// 电召订单支付
const payOrderAPI = (data) => {
  return service({
    url: "/order/wxPay/serverPay",
    method: "post",
    data,
  });
};

// 电召下单分页查询
const getPhoneOrderListAPI = (data) => {
  return service({
    url: "/manage/schedule/callIntercityOrderPage",
    method: "post",
    data,
  });
};

// 电召下单导出
const phoneOrderListExpAPI = (data) => {
  return service({
    url: "/manage/schedule/callIntercityOrderExport",
    method: "post",
    data,
    responseType: "blob",
  });
};

// 电召下单取消订单
const cancelOrderAPI = (data) => {
  return service({
    url: "/order/intercity/user/order/" + data.orderId,
    method: "put",
    data,
  });
};

// 电召下单地名模糊搜索
const searchPointAPI = (data) => {
  return service({
    url: "/manage/map/search/polygon/key",
    method: "post",
    data,
  });
};

export {
  queryUnscheduleList,
  querySchedulingList,
  querySchedulingDetail,
  queryScheduledList,
  queryScheduleHistoryList,
  queryAfterSalesRefundDetailPage,
  exportAfterSalesRefundDetail,
  queryScheduleHistoryDetail,
  cancelVehicle,
  cancelTicket,
  cancelOrder,
  scheduleVehicle,
  scheduleDriver,
  getSchedulePositions,
  deleteDriverOrder,
  searchLineAPI,
  searchShiftTimeAPI,
  placeOrderAPI,
  payOrderAPI,
  getPhoneOrderListAPI,
  phoneOrderListExpAPI,
  cancelOrderAPI,
  searchPointAPI,
};

/* ----------------- 车辆模块 ---------------------- */

/* ----------------- 驾驶员模块 ---------------------- */

// 查询驾驶员列表
const getDriverList = (data) => {
  return service({
    url: "/user/driver/page",
    method: "post",
    data,
  });
};

// 查询所有驾驶员列表
const getAllDriverList = (data) => {
  return service({
    url: `/user/driver/list`,
    method: "get",
    data,
  });
};

// 新增驾驶员
const insertDriver = (data) => {
  return service({
    url: "/user/driver",
    method: "post",
    data,
  });
};

// 修改驾驶员
const updateDriver = (data) => {
  return service({
    url: "/user/driver/" + data.id,
    method: "put",
    data,
  });
};
// 查询驾驶证类型数据字典
const queryDictsByCodes = () => {
  return service({
    url: "/manage/vehicle/getItsDriverlicenseList",
    method: "get",
  });
};
// 查询详情列表数据
const getDriverDetail = (data) => {
  return service({
    url: "/user/driver/" + data.id,
    method: "get",
    data,
  });
};

// 驾驶员重置密码
const resetDriverPwd = (data) => {
  return service({
    url: "/user/driver/resetPassword/" + data.id,
    method: "get",
    data,
  });
};

// 启用禁用驾驶员
const disableDriver = (data) => {
  return service({
    url: "/user/driver/disable/" + data.id,
    method: "put",
    data,
  });
};

export {
  getDriverList,
  getAllDriverList,
  insertDriver,
  updateDriver,
  getDriverDetail,
  resetDriverPwd,
  disableDriver,
  queryDictsByCodes,
};

/* ----------------- 驾驶员模块 ---------------------- */

/* ----------------- 售票结算+排班管理 ---------------------- */
// 售票结算表格
const statisticsAPI = (data) => {
  return service({
    url: "/manage/statistics/page",
    method: "post",
    data,
  });
};

// 确认
const statisticsConfirmAPI = (data) => {
  return service({
    url: "/manage/statistics/confirm/" + data.id,
    method: "put",
    data,
  });
};

// 结算
const statisticsSettlementAPI = (data) => {
  return service({
    url: "/manage/statistics/settlement/" + data.id,
    method: "put",
    data,
  });
};

//城际专车结算明细
const intercityCarListAPI = (data) => {
  return service({
    url: "/manage/statistics/intercity/order/detail",
    method: "post",
    data,
  });
};

////城际专车结算明细导出
const intercityCarListExpAPI = (data) => {
  return service({
    url: "/manage/statistics/intercity/order/detail/export",
    method: "post",
    data,
    responseType: "blob",
  });
};

// 数据导出
const statisticsExportAPI = (data) => {
  return service({
    url: "/manage/statistics/export",
    method: "post",
    responseType: "blob",
    data,
  });
};

//电子客运凭单查询列表
const ElectronicVoucherListAPI = (data) => {
  return service({
    url: "/order/custom/passenger/transport/credential/page",
    method: "post",
    data,
  });
};

//电子客运凭单导出
const ElectronicVoucherExpAPI = (data) => {
  return service({
    url: "/order/custom/passenger/transport/credential/export",
    method: "post",
    data,
    responseType: "blob",
  });
};

// 排班管理表格
const intercityPageAPI = (data) => {
  return service({
    url: "/manage/shift/intercity/page",
    method: "post",
    data,
  });
};

// 起点站集合
const startStationAPI = (data) => {
  return service({
    url: "/manage/line/startStation",
    method: "get",
    data,
  });
};

// 终点站集合
const endStationAPI = (data) => {
  return service({
    url: "/manage/line/endStation",
    method: "get",
    data,
  });
};

// 班线管理--线路分页查询
const linePageAPI = (data) => {
  return service({
    url: "/manage/line/page",
    method: "post",
    data,
  });
};

// 线路启禁用
const lineDisableAPI = (data) => {
  return service({
    url: "/manage/line/disable/" + data.id,
    method: "put",
    data,
  });
};

// 改班&暂停&撤班&恢复
const shiftIntercityAPI = (data) => {
  return service({
    url: "/manage/shift/intercity/" + data.id,
    method: "put",
    data,
  });
};

// 生成排班
const manualScheduleAPI = (data) => {
  return service({
    url: "/manage/shift/intercity/manualSchedule",
    method: "post",
    data,
  });
};

// 参数查询
const configureParamsAPI = (data) => {
  return service({
    url: "/manage/configure/paramsV2",
    method: "get",
    data,
  });
};

// 排班计划表格数据
const scheduledRuleListAPI = (data) => {
  return service({
    url: "/manage/scheduledRule/list",
    method: "get",
    data,
  });
};

// 获取排班计划的线路API
const scheduledlineListAPI = (data) => {
  return service({
    url: "/manage/scheduledRule/lineList",
    method: "get",
    data,
  });
};

// 新增排班计划
const AddscheduledRuleAPI = (data) => {
  return service({
    url: "/manage/scheduledRule",
    method: "post",
    data,
  });
};

// 删除排班计划
const deleteScheduledRuleAPI = (data) => {
  return service({
    url: "/manage/scheduledRule/" + data.id,
    method: "delete",
    data,
  });
};

// 修改排班计划
const updateScheduledRuleAPI = (data) => {
  return service({
    url: "/manage/scheduledRule",
    method: "put",
    data,
  });
};

// 添加固定点
const linePointAPI = (data) => {
  return service({
    url: "/manage/line/point",
    method: "post",
    data,
  });
};

// 修改固定点
const updateLinePointAPI = (data) => {
  return service({
    url: "/manage/line/point/" + data.id,
    method: "put",
    data,
  });
};

// 消息中心接口数据获取
const orderPhonesAPI = (data) => {
  return service({
    url: "/manage/message/admin/orderPhones",
    method: "post",
    data,
  });
};

// 消息中心获取未读消息条数
const notReadCountAPI = (data) => {
  return service({
    url: "/manage/message/admin/notReadCount",
    method: "get",
  });
};

// 消息中心批量已读API
const adminReadStatusAPI = (data) => {
  return service({
    url: "/manage/message/update/adminReadStatus/list",
    method: "post",
    data,
  });
};

// 人车关系查询接口
const driverIntercityPageAPI = (data) => {
  return service({
    url: "/manage/driverIntercity/page",
    method: "post",
    data,
  });
};

// 后台修改单条消息已读
const adminReadStatusUpdateAPI = (data) => {
  return service({
    url: "/manage/message/update/adminReadStatus/" + data,
    method: "get",
  });
};

// 根据车辆id获取驾驶员信息
const driverIntercityAPI = (data) => {
  return service({
    url: "/manage/driverIntercity/" + data,
    method: "get",
  });
};

// 根据公司id获取车辆信息
const vehicleListAPI = (data) => {
  return service({
    url: `/manage/vehicle/list/${data.companyId}`,
    method: "get",
    data,
  });
};

// 查询未绑定车辆驾驶员信息
const vehicleAPI = (data) => {
  return service({
    url: "/user/driver/unbounded/vehicle",
    method: "get",
  });
};

// 新增人车关系
const driverIntercityAddAPI = (data) => {
  return service({
    url: "/manage/driverIntercity",
    method: "post",
    data,
  });
};

// 删除人车关系
const driverIntercityDeleteAPI = (data) => {
  return service({
    url: "/manage/driverIntercity/" + data.id,
    method: "delete",
  });
};

// 修改人车关系
const driverIntercityUpdateAPI = (data) => {
  return service({
    url: "/manage/driverIntercity/" + data.id,
    method: "put",
    data,
  });
};

// 司机派单统计分页查询
const driverStatisticsAPI = (data) => {
  return service({
    url: "/manage/driver/assignOrder/statistics/listPage",
    method: "post",
    data,
  });
};

// 司机派单统计导出按钮
const driverExportAPI = (data) => {
  return service({
    url: "/manage/driver/assignOrder/statistics/export",
    method: "post",
    responseType: "blob",
    data,
  });
};

// 汽车售票订单---获取票源列表
const companyCodeAPI = (data) => {
  return service({
    url: "/order/automobileIntercity/ticket/companyCode",
    method: "get",
    data,
  });
};

// 班线管理--修改商品编码
const updateCommodityCodeAPI = (data) => {
  return service({
    url: "/manage/line/update/commodityCode",
    method: "put",
    data,
  });
};

export {
  updateCommodityCodeAPI,
  statisticsAPI,
  statisticsConfirmAPI,
  statisticsSettlementAPI,
  statisticsExportAPI,
  intercityPageAPI,
  startStationAPI,
  endStationAPI,
  linePageAPI,
  lineDisableAPI,
  shiftIntercityAPI,
  manualScheduleAPI,
  configureParamsAPI,
  scheduledRuleListAPI,
  scheduledlineListAPI,
  AddscheduledRuleAPI,
  deleteScheduledRuleAPI,
  updateScheduledRuleAPI,
  linePointAPI,
  updateLinePointAPI,
  orderPhonesAPI,
  notReadCountAPI,
  adminReadStatusAPI,
  driverIntercityPageAPI,
  adminReadStatusUpdateAPI,
  driverIntercityAPI,
  updateDispatchOfDriverOrCarAPI,
  vehicleAPI,
  driverIntercityAddAPI,
  driverIntercityDeleteAPI,
  driverIntercityUpdateAPI,
  vehicleListAPI,
  driverStatisticsAPI,
  driverExportAPI,
  companyCodeAPI,
  intercityCarListAPI,
  intercityCarListExpAPI,
  ElectronicVoucherExpAPI,
  ElectronicVoucherListAPI,
};

// 配置管理模块----*-----
// 购票规则表格数据获取
const ticketRegulationAPI = (data) => {
  return service({
    url: "/manage/ticketRegulation/get",
    method: "get",
    data,
  });
};
// 购票规则---数据修改
const ticketRegulationUpdateAPI = (data) => {
  return service({
    url: "/manage/ticketRegulation/put",
    method: "put",
    data,
  });
};

// 退票规则数据获取
const refundTicketRegulationAPI = (data) => {
  return service({
    url: "/manage/refundTicketRegulation/get",
    method: "get",
    data,
  });
};
// 退票规则数据删除
const refundTicketRegulationDeleteAPI = (data) => {
  return service({
    url: "/manage/refundTicketRegulation/delete/" + data.id,
    method: "delete",
    data,
  });
};
// 退票规则数据添加
const refundTicketRegulationAddAPI = (data) => {
  return service({
    url: "/manage/refundTicketRegulation/post",
    method: "post",
    data,
  });
};
// 退票规则数据修改
const refundTicketRegulationUpdateAPI = (data) => {
  return service({
    url: "/manage/refundTicketRegulation/put",
    method: "put",
    data,
  });
};

//定制班线和村通客运规则查询
const searchRulesAPI = (data) => {
  return service({
    url: "/manage/regulation/list",
    method: "post",
    data,
  });
};

//定制班线和村通客运规则修改
const updateRulesAPI = (data) => {
  return service({
    url: "/manage/regulation/update",
    method: "put",
    data,
  });
};

//定制班线和村通客运新增规则
const addRulesAPI = (data) => {
  return service({
    url: "/manage/regulation/add",
    method: "post",
    data,
  });
};

// 调度规则数据获取
const scheduleRegulationAPI = (data) => {
  return service({
    url: "/manage/scheduleRegulation/get",
    method: "get",
    data,
  });
};
// 调度规则数据修改
const scheduleRegulationUpdateAPI = (data) => {
  return service({
    url: "/manage/scheduleRegulation/put",
    method: "put",
    data,
  });
};

// 获取协议配置数据接口
const agreementConfigAPI = (data) => {
  return service({
    url: "/manage/pactConfig/list",
    method: "post",
    data,
  });
};
// 修改协议配置数据接口
const agreementConfigUpdateAPI = (data) => {
  return service({
    url: "/manage/pactConfig/update",
    method: "put",
    data,
  });
};

//获取用户端业务模块配置列表
const userBussinessAPI = (data) => {
  return service({
    url: "/manage/businessConfig/list",
    method: "get",
    data,
  });
};

// 获取用户端业务模块配置详情
const selectOneAPI = (data) => {
  return service({
    url: "/manage/businessConfig/selectOne/" + data.code,
    method: "post",
  });
};

//修改用户端业务模块配置
const updateUserBussinessAPI = (data) => {
  return service({
    url: "/manage/businessConfig/update",
    method: "put",
    data,
  });
};

//-----------------------公交出行配置-------------------
//排序
const busTravelConfigurationSortingAPI = (data) => {
  return service({
    url: "/bus/busTravelConfiguration/sorting",
    method: "put",
    format: true,
    data,
  });
};
//添加公交出行配置
const addBusTravelConfigurationAPI = (data) => {
  return service({
    url: "/bus/busTravelConfiguration/addBusTravelConfiguration",
    method: "post",
    data,
  });
};
//修改公交出行配置
const busTravelConfigurationUpdateAPI = (data) => {
  return service({
    url: "/bus/busTravelConfiguration/update",
    method: "put",
    data,
  });
};
//公交出行配置列表
const busTravelConfigurationListAPI = (data) => {
  return service({
    url: "/bus/busTravelConfiguration/list",
    method: "get",
    data,
  });
};
//公交出行配置详情
const busTravelConfigurationSelectOneAPI = (data) => {
  return service({
    url: "/bus/busTravelConfiguration/selectOne/" + data.id,
    method: "post",
  });
};
//公交公司tree
const busTravelConfigurationCompanyTreeAPI = (data) => {
  return service({
    url: "/manage/company/tree",
    method: "get",
    data,
  });
};
//获取城市代码
const getCityCodeAPI = (data) => {
  return service({
    url: "/manage/map/getCityCode",
    method: "get",
    data,
  });
};
//公交出行订单
const selectBusOrderByKeyWordAPI = (data) => {
  return service({
    url: "/bus/order/selectBusOrderByKeyWord",
    method: "post",
    data,
  });
};
//公交出行订单-导出表格
const getBusOrderExportAPI = (data) => {
  return service({
    url: "/bus/order/getBusOrderExport",
    method: "post",
    data,
    responseType: "blob",
  });
};
//公交订单详情
const selectOneBusOrderAPI = (data) => {
  return service({
    url: "/bus/order/selectOneBusOrder/" + data.id,
    method: "post",
  });
};
//查询公司绑定线路
const selectLineBindCarInfoAPI = (data) => {
  return service({
    url: "/bus/busService/selectLineBindCarInfo",
    method: "post",
    data,
  });
};
//获取线路下所绑定车辆信息
const getLineBindCarInfoAPI = (data) => {
  return service({
    url: "/bus/busTravelConfiguration/getLineBindCarInfo",
    method: "post",
    data,
  });
};
//绑定的车辆
const selectBusCarByCphAPI = (data) => {
  return service({
    url: "/bus/busTravelConfiguration/selectBusCarByCph",
    method: "post",
    data,
  });
};
//添加绑定车辆信息
const addLineBindCarInfoAPI = (data) => {
  return service({
    url: "/bus/busTravelConfiguration/addLineBindCarInfo",
    method: "post",
    data,
  });
};
//删除线路车辆绑定信息
const deleteLineBindCarInfoAPI = (data) => {
  return service({
    url: "/bus/busTravelConfiguration/deleteLineBindCarInfo",
    method: "post",
    data,
  });
};

//拖拽行
const dragRowAPI = (data) => {
  return service({
    url: "/manage/businessConfig/sorting",
    method: "put",
    format: true,
    data,
  });
};

// 汽车售票订单接口
const automobileIntercityAPI = (data) => {
  return service({
    url: "/order/automobileIntercity/orderList",
    method: "post",
    data,
  });
};

// 获取所有站点数据接口
const siteListAPI = (data) => {
  return service({
    url: "/manage/company/mapping/coach/station/user",
    method: "get",
    data,
  });
};

// 售票明细接口
const ticketDetailsAPI = (data) => {
  return service({
    url: "/order/automobileIntercity/page/detail",
    method: "post",
    data,
  });
};

// 售票明细--导出接口
const ticketDetailExportAPI = (data) => {
  return service({
    url: "/order/automobileIntercity/export",
    method: "post",
    responseType: "blob",
    data,
  });
};

// 查询反馈类型列表接口
const feedbackTypeListAPI = (data) => {
  return service({
    url: "/manage/feedback/type/list",
    method: "post",
    data,
  });
};
// 反馈类型列表树结构
const feedbackTypeTreeAPI = (data) => {
  return service({
    url: "/manage/feedback/type/tree",
    method: "get",
    data,
  });
};

// 新建、修改反馈类型接口
const feedbackTypeEditAPI = (data) => {
  return service({
    url: "/manage/feedback/type/operation",
    method: "post",
    data,
  });
};
// 删除反馈类型
const feedbackTypeDeleteAPI = (data) => {
  return service({
    url: "/manage/feedback/type/delete/" + data.id,
    method: "post",
  });
};
// 投诉反馈数据获取接口
const feedbackAPI = (data) => {
  return service({
    url: "/manage/complaint/feedback/page",
    method: "post",
    data,
  });
};
// 查询反馈详情接口
const feedbackDetailInfoAPI = (data) => {
  return service({
    url: "/manage/complaint/feedback/backDetail/" + data.id,
    method: "post",
  });
};
// 处理投诉反馈
const feedbackHandleAPI = (data) => {
  return service({
    url: "/manage/complaint/feedback/handle",
    method: "post",
    data,
  });
};
// 用户反馈回复
const feedbackReplyAPI = (data) => {
  return service({
    url: "manage/complaint/feedback/reply",
    method: "post",
    data,
  });
};
// 支付流水数据获取接口
const payFlowAPI = (data) => {
  return service({
    url: "/order/payment/flow/page",
    method: "post",
    data,
  });
};

// 支付流水--导出获取接口
const payFlowExportAPI = (data) => {
  return service({
    url: "/order/payment/flow/export",
    method: "post",
    responseType: "blob",
    data,
  });
};

// 单车结算分页数据
const singleVehiclePage = (data) => {
  return service({
    url: "/manage/statistics/singleVehicle/page",
    method: "post",
    data,
  });
};
// 单车结算详情
const singleVehicleDetailInfo = (data) => {
  return service({
    url: "/manage/statistics/singleVehicleDetailInfo",
    method: "post",
    data,
  });
};
// 单车结算统计导出
const singleVehicleDetailExport = (data) => {
  return service({
    url: "/manage/statistics/singleVehicleDetailExport",
    method: "post",
    data,
    responseType: "blob",
  });
};

// 单车结算导出
const singleVehicleExport = (data) => {
  return service({
    url: "/manage/statistics/singleVehicle/export",
    method: "post",
    responseType: "blob",
    data,
  });
};

// 汽车订票---订单详情查询
const orderDetailAPI = (data) => {
  return service({
    url: "/order/automobileIntercity/orderDetail/" + data.id,
    method: "get",
    data,
  });
};

// 汽车订票---订单导出
const orderListExportAPI = (data) => {
  return service({
    url: "/order/automobileIntercity/orderListExport",
    method: "post",
    responseType: "blob",
    data,
  });
};

// 待配车树接口
const carTreeAPI = (data) => {
  return service({
    url: "/manage/schedule/vehicle/tree",
    method: "get",
    data,
  });
};

// 晚点群发短信接口
const lateNoticeAPI = (data) => {
  return service({
    url: "/manage/schedule/vehicle/lateNotice",
    method: "put",
    data,
  });
};

//城际专车-查看乘车码
const checkCodeAPI = (data) => {
  return service({
    url: "/order/intercity/order/passenger/certificate",
    method: "get",
    data,
  });
};
// 城际专车导出接口
const historyExportAPI = (data) => {
  return service({
    url: "/manage/schedule/historyExport",
    method: "post",
    responseType: "blob",
    data,
  });
};

// 城际专车查看订单详情接口
const referOrderDetialAPI = (data) => {
  return service({
    url: "/manage/schedule/selectIntercityChildListByOrderId/" + data,
    method: "get",
  });
};
//获取主订单优惠流水详情
const referOrderCouponsDetailAPI = (data) => {
  return service({
    url: "/order/discountFlow/detailInfo",
    method: "get",
    data,
  });
};

export {
  ticketRegulationAPI,
  refundTicketRegulationAPI,
  scheduleRegulationAPI,
  ticketRegulationUpdateAPI,
  scheduleRegulationUpdateAPI,
  refundTicketRegulationDeleteAPI,
  refundTicketRegulationAddAPI,
  refundTicketRegulationUpdateAPI,
  agreementConfigAPI,
  agreementConfigUpdateAPI,
  automobileIntercityAPI,
  siteListAPI,
  ticketDetailsAPI,
  feedbackAPI,
  feedbackDetailInfoAPI,
  feedbackTypeListAPI,
  feedbackTypeTreeAPI,
  feedbackTypeEditAPI,
  feedbackTypeDeleteAPI,
  payFlowAPI,
  ticketDetailExportAPI,
  payFlowExportAPI,
  feedbackHandleAPI,
  feedbackReplyAPI,
  orderDetailAPI,
  orderListExportAPI,
  carTreeAPI,
  lateNoticeAPI,
  historyExportAPI,
  checkCodeAPI,
  userBussinessAPI,
  updateUserBussinessAPI,
  selectOneAPI,
  searchRulesAPI,
  updateRulesAPI,
  addRulesAPI,
  dragRowAPI,
  referOrderDetialAPI,
  busTravelConfigurationSortingAPI,
  busTravelConfigurationListAPI,
  busTravelConfigurationUpdateAPI,
  busTravelConfigurationCompanyTreeAPI,
  busTravelConfigurationSelectOneAPI,
  addBusTravelConfigurationAPI,
  getCityCodeAPI,
  selectBusOrderByKeyWordAPI,
  getBusOrderExportAPI,
  selectOneBusOrderAPI,
  selectLineBindCarInfoAPI,
  getLineBindCarInfoAPI,
  selectBusCarByCphAPI,
  addLineBindCarInfoAPI,
  deleteLineBindCarInfoAPI,
  singleVehiclePage,
  singleVehicleDetailInfo,
  singleVehicleDetailExport,
  singleVehicleExport,
  referOrderCouponsDetailAPI,
};

// -------------------------------包车业务相关接口-----------------------------------
// 车型数据字典接口
const carTypeListAPI = (data) => {
  return service({
    url: "/manage/vehicleModelType/getVehicleModelTypeDistList",
    method: "get",
    data,
  });
};

// 新增车型模板接口
const addCarTypeListAPI = (data) => {
  return service({
    url: "/manage/vehicleModelType/postVehicleModelType",
    method: "post",
    data,
  });
};

// 新增车型上传图片的接口
const uploadFileAPI = (data) => {
  return service({
    url: "/manage/uploadImage/file/upload",
    method: "post",
    header: "multipart/form-data",
    data,
  });
};

// 获取表格所有车型模板数据接口
const renderCarTypeListAPI = (data) => {
  return service({
    url: "/manage/vehicleModelType/getVehicleModelTypeList",
    method: "get",
    data,
  });
};

// 获取所有已开启的包车车型模板
const renderCarTypeListAPI2 = (data) => {
  return service({
    url: "/manage/vehicleModelType/getEnableCharteredVehicleModelTypeList",
    method: "get",
  });
};

// 删除车型模板数据接口
const carTypeDeleteAPI = (data) => {
  return service({
    url: "/manage/vehicleModelType/delVehicleModelType/" + data.id,
    method: "delete",
    data,
  });
};

// 修改车型模板数据接口
const carTypeUpdateAPI = (data) => {
  return service({
    url: "/manage/vehicleModelType/putVehicleModelType",
    method: "put",
    data,
  });
};

// 新增包车套餐接口
const carPackageAddAPI = (data) => {
  return service({
    url: "/manage/chartered/combo/template/add",
    method: "post",
    data,
  });
};

// 获取包车套餐接口
const carPackageRenderAPI = (data) => {
  return service({
    url: "/manage/chartered/combo/template/list",
    method: "post",
    data,
  });
};

// 删除包车套餐接口
const carPackageDeleteAPI = (data) => {
  return service({
    url: "/manage/chartered/combo/template/delete",
    method: "delete",
    data,
  });
};

// 删除包车套餐接口
const carPackageUpdateAPI = (data) => {
  return service({
    url: "/manage/chartered/combo/template/update",
    method: "put",
    data,
  });
};

// 添加车商接口
const carDealerAddAPI = (data) => {
  return service({
    url: "/manage/car/service/provider/add",
    method: "post",
    data,
  });
};

// 车商数据查询接口
const carDealerRenderAPI = (data) => {
  return service({
    url: "/manage/car/service/provider/page",
    method: "post",
    data,
  });
};

// 删除车商接口
const carDealerDeleteAPI = (data) => {
  return service({
    url: "/manage/car/service/provider/delete",
    method: "delete",
    data,
  });
};

// 编辑车商接口
const carDealerUpdateAPI = (data) => {
  return service({
    url: "/manage/car/service/provider/update",
    method: "put",
    data,
  });
};

// 选择车型分类后的上传接口
const modelTypesAddAPI = (data) => {
  return service({
    url: "/manage/car/service/provider/vehicle/model/types",
    method: "post",
    data,
  });
};

// 车型列表获取接口
const byProviderListRenderAPI = (data) => {
  return service({
    url: "/manage/car/service/provider/vehicle/model/list/byProvider",
    method: "get",
    data,
  });
};

// 车型列表启用/禁用接口
const enableAndDisableAPI = (data) => {
  return service({
    url:
      "/manage/car/service/provider/vehicle/model/enableAndDisable?status=" +
      data.status +
      "&id=" +
      data.id,
    method: "put",
    data,
  });
};

// 获取全程往返核定里程计算方式枚举值
const roundTripPricingValue = (data) => {
  return service({
    url: "/manage/ticketRegulation/roundTripPricingValue",
    method: "get",
  });
};

// 获取包车预订规则接口
const getCharteredAPI = (data) => {
  return service({
    url: "/manage/ticketRegulation/getChartered",
    method: "get",
    data,
  });
};

// 获取包车行车规则接口
const drivingRegulationAPI = (data) => {
  return service({
    url: "/manage/drivingRegulation/getChartered",
    method: "get",
    data,
  });
};

// 新增包车退订规则接口
const postCharteredAPI = (data) => {
  return service({
    url: "/manage/refundTicketRegulation/postChartered",
    method: "post",
    data,
  });
};

// 获取包车退订规则接口
const getRrefundTicketRegulationAPI = (data) => {
  return service({
    url: "/manage/refundTicketRegulation/getChartered",
    method: "get",
    data,
  });
};

// 修改包车退订规则接口
const putCharteredAPI = (data) => {
  return service({
    url: "/manage/refundTicketRegulation/putChartered",
    method: "put",
    data,
  });
};

// 删除包车退订规则接口
const delCharteredAPI = (data) => {
  return service({
    url: "/manage/refundTicketRegulation/delChartered/" + data.id,
    method: "delete",
    data,
  });
};

// 修改包车预订规则接口
const putCharteredTicketRegulationAPI = (data) => {
  return service({
    url: "/manage/ticketRegulation/putChartered",
    method: "put",
    data,
  });
};

// 修改包车预订规则接口
const putCharteredDrivingRegulationAPI = (data) => {
  return service({
    url: "/manage/drivingRegulation/putChartered",
    method: "put",
    data,
  });
};

// 车商下拉列表获取接口
const providerListAPI = (data) => {
  return service({
    url: "/manage/car/service/provider/list",
    method: "get",
    data,
  });
};

// 包车费用项目管理-新增接口
const postCharteredCostProjectAPI = (data) => {
  return service({
    url: "/manage/costProject/postCharteredCostProject",
    method: "post",
    data,
  });
};

// 包车费用项目管理-表格数据接口
const getCharteredCostProjectListAPI = (data) => {
  return service({
    url: "/manage/costProject/getCharteredCostProjectList",
    method: "get",
    data,
  });
};

// 包车费用项目管理-修改接口
const putCharteredCostProjectAPI = (data) => {
  return service({
    url: "/manage/costProject/putCharteredCostProject",
    method: "put",
    data,
  });
};

// 包车费用项目管理-删除接口
const delCharteredCostProjectAPI = (data) => {
  return service({
    url: "/manage/costProject/delCharteredCostProject/" + data.id,
    method: "delete",
  });
};

// 库存管理-数据接口
const charterInventoryRenderAPI = (data) => {
  return service({
    url: "/manage/car/service/provider/vehicle/model/inventory/page",
    method: "post",
    data,
  });
};

// 库存管理-子类下拉列表接口
const getVehicleTypeChildNameListAPI = (data) => {
  return service({
    url: "/manage/vehicleModelType/getVehicleTypeChildNameList",
    method: "get",
    data,
  });
};

// 库存管理-上下架接口
const charterInventoryonShelfAPI = (data) => {
  return service({
    url: "/manage/car/service/provider/vehicle/model/inventory/onShelf",
    method: "put",
    data,
  });
};

// 库存管理-修改库存上线接口
const charterInventoryAllCarCountAPI = (data) => {
  return service({
    url: "/manage/car/service/provider/vehicle/model/inventory/allCarCount",
    method: "post",
    data,
  });
};

// 计价管理-包车分页查询规则列表
const valuationManagePageAPI = (data) => {
  return service({
    url: "/manage/charteredValuationManagement/page",
    method: "post",
    data,
  });
};

// 计价管理-包车更新规则
const updateValuationManagePageAPI = (data) => {
  return service({
    url: "/manage/charteredValuationManagement/update",
    method: "put",
    data,
  });
};

// 计价管理-获取指定计价信息
const getValuationManagementAPI = (data) => {
  return service({
    url: `/manage/charteredValuationManagement/getValuationManagement/${data.serviceVehicleModelId}`,
    method: "get",
  });
};

// 计价管理-新增包车套餐
const chooseCharteredComboAPI = (data) => {
  return service({
    url: `/manage/charteredValuationManagement/chooseCharteredCombo`,
    method: "post",
    data,
  });
};

// 计价管理-编辑保存包车套餐
const chooseValuationManagementCostProjectAPI = (data) => {
  return service({
    url: `/manage/charteredValuationManagement/chooseValuationManagementCostProject`,
    method: "post",
    data,
  });
};

// 计价管理-删除包车套餐
const delValuationManagementComboAPI = (data) => {
  return service({
    url: `/manage/charteredValuationManagement/delValuationManagementCombo/${data.id}`,
    method: "delete",
  });
};

// 计价管理-修改启用禁用状态
const charteredValuationManagementPutStatusAPI = (data) => {
  return service({
    url: `/manage/charteredValuationManagement/putStatus`,
    method: "put",
    data,
  });
};

// 计费规则配置详情
export const charteredValuationManagementAPI = (data) => {
  return service({
    url: `/manage/charteredValuationManagement/getById?id=${data.id}`,
    method: "get",
  });
};
// 计费规则配置保存
export const updateCharteredValuationManagementAPI = (data) => {
  return service({
    url: `/manage/charteredValuationManagement/updateByManagementId`,
    method: "post",
    data,
  });
};

// 包车调度-获取待调度数据
export const getWaitingSchedulingOrderListAPI = (data) => {
  return service({
    url: `/manage/chartered/scheduling/getWaitingSchedulingOrderList`,
    method: "get",
    data,
  });
};

// 包车调度-获取待配车订单数量
export const getCharteredSchedulingOrderCountVOAPI = (data) => {
  return service({
    url: `/manage/chartered/scheduling/getCharteredSchedulingOrderCountVO`,
    method: "get",
    data,
  });
};

// 包车调度-待配车退单
export const charterRefundOrderAPI = (data) => {
  return service({
    url: `/order/chartered/order/server/refund`,
    method: "put",
    data,
  });
};

// 包车调度-获取可调度的车辆
export const getCanSchedulingOrderVehicleListAPI = (data) => {
  return service({
    url: `/manage/chartered/scheduling/getCanSchedulingOrderVehicleList/${data.companyId}/${data.id}`,
    method: "get",
  });
};

// 包车调度-获取可调度的司机
export const getCanSchedulingOrderDriverListAPI = (data) => {
  return service({
    url: `/manage/chartered/scheduling/getCanSchedulingOrderDriverList/${data.companyId}`,
    method: "get",
  });
};

// 包车调度-调度订单
export const putWaitingSchedulingOrderAPI = (data) => {
  return service({
    url: `/manage/chartered/scheduling/putWaitingSchedulingOrder`,
    method: "put",
    data,
  });
};

// 包车调度-待接驾订单列表
export const getGreetingDriverOrderListAPI = (data) => {
  return service({
    url: `/manage/chartered/scheduling/getGreetingDriverOrderList`,
    method: "get",
    data,
  });
};

// 包车调度-待接驾订单列表
export const cancelWaitingSchedulingOrderAPI = (data) => {
  return service({
    url: `/manage/chartered/scheduling/cancelWaitingSchedulingOrder/${data.id}`,
    method: "put",
  });
};

//包车调度-查看乘车码
export const checkCodeAPIB = (data) => {
  return service({
    url: "/order/chartered/order/passenger/certificate",
    method: "get",
    data,
  });
};

// 包车调度-历史调度 和 包车订单的表格数据获取接口
export const getHistoryOrderPageListAPI = (data) => {
  return service({
    url: `/manage/chartered/scheduling/getHistoryOrderPageList`,
    method: "post",
    data,
  });
};

// 包车调度-历史调度-->查看订单详情接口
export const getCharteredOrderExtraCostTripRecordAPI = (data) => {
  return service({
    url: `/manage/chartered/scheduling/getCharteredOrderExtraCostTripRecord/${data.id}`,
    method: "get",
  });
};

// 包车调度报价页面
export const quotationPageAPI = (data) => {
  return service({
    url: `/order/chartered/order/quotationPage`,
    method: "get",
    data,
  });
};
// 包车调度报价
export const quotationAPI = (data) => {
  return service({
    url: `/order/chartered/order/quotation`,
    method: "post",
    data,
  });
};
// 取消包车调度订单
export const charterOrderCancelAPI = (data) => {
  return service({
    url: `/order/chartered/order/user/cancel/` + data.id,
    method: "put",
  });
};
// 包车订单后台退款
export const serverRefundAPI = (data) => {
  return service({
    url: `/order/chartered/order/serverRefund`,
    method: "post",
    data,
  });
};
// 订单详情
export const charteredOrderDetailAPI = (data) => {
  return service({
    url: `/order/chartered/order/user/detail/${data.orderId}`,
    method: "get",
  });
};

// 包车调度-历史调度导出接口
export const getHistoryOrderPageListExportAPI = (data) => {
  return service({
    url: `/manage/chartered/scheduling/getHistoryOrderPageListExport`,
    method: "post",
    responseType: "blob",
    data,
  });
};

// 包车调度-行程中修改包车订单额外费用
export const putOrderExtraCostAPI = (data) => {
  return service({
    url: `/manage/chartered/scheduling/putOrderExtraCost`,
    method: "put",
    data,
  });
};

// 包车调度-获取车辆位置
export const getVehiclePositionAPI = (data) => {
  return service({
    url: `/manage/chartered/scheduling/getVehiclePosition`,
    method: "post",
    data,
  });
};

// 包车调度-结束行程
export const completeItineraryAPI = (data) => {
  return service({
    url: `/order/chartered/driver/order/complete`,
    method: "post",
    data,
  });
};

//------------------------   租车   ---------------------------------
//待取车订单列表
export const waitingTakeVehicleAPI = (data) => {
  return service({
    url: `/manage/rental/scheduling/waitingTakeVehicle`,
    method: "post",
    data,
  });
};
//待还车订单列表
export const waitingStillVehicleAPI = (data) => {
  return service({
    url: `/manage/rental/scheduling/waitingStillVehicle`,
    method: "post",
    data,
  });
};
//待退押金订单列表
export const waitingRefundDepositAPI = (data) => {
  return service({
    url: `/manage/rental/scheduling/waitingRefundDeposit`,
    method: "post",
    data,
  });
};
//租车调度-后台退车辆押金
export const serverRefundVehicleDepositAPI = (data) => {
  return service({
    url: `/order/rental/serverRefundVehicleDeposit`,
    method: "put",
    data,
  });
};
//租车调度-后台退违章押金
export const serverRefundViolationDepositAPI = (data) => {
  return service({
    url: `/order/rental/serverRefundViolationDeposit`,
    method: "put",
    data,
  });
};
//待取车 退单
export const refundOrder = (data) => {
  return service({
    url: `order/rental/server/refund`,
    method: "put",
    data,
  });
};
//待取车 车辆列表
export const getVechicleList = (data) => {
  return service({
    url: `manage/rental/scheduling/getCanSchedulingOrderVehicleList`,
    method: "get",
    data,
  });
};
//待取车 确认取车
export const takeCarPickUp = (data) => {
  return service({
    url: `manage/rental/scheduling/pickUp`,
    method: "post",
    data,
  });
};
//待取车 确认还车
export const returnCarPickUp = (data) => {
  return service({
    url: `manage/rental/scheduling/returnCar`,
    method: "post",
    data,
  });
};
//租车调度-待审核
export const waitAuditingScheduleAPI = (data) => {
  return service({
    url: `/manage/rental/scheduling/waitAuditingSchedule`,
    method: "post",
    data,
  });
};
//租车调度-待审核-审核
export const licenceAuditingOrderAPI = (data) => {
  return service({
    url: `/manage/rental/scheduling/licenceAuditingOrder`,
    method: "put",
    data,
  });
};

export {
  carTypeListAPI,
  addCarTypeListAPI,
  uploadFileAPI,
  renderCarTypeListAPI,
  renderCarTypeListAPI2,
  carTypeDeleteAPI,
  carTypeUpdateAPI,
  carPackageAddAPI,
  carPackageRenderAPI,
  carPackageDeleteAPI,
  carPackageUpdateAPI,
  carDealerAddAPI,
  carDealerRenderAPI,
  carDealerDeleteAPI,
  carDealerUpdateAPI,
  modelTypesAddAPI,
  byProviderListRenderAPI,
  enableAndDisableAPI,
  getCharteredAPI,
  roundTripPricingValue,
  drivingRegulationAPI,
  postCharteredAPI,
  getRrefundTicketRegulationAPI,
  putCharteredAPI,
  delCharteredAPI,
  putCharteredTicketRegulationAPI,
  putCharteredDrivingRegulationAPI,
  providerListAPI,
  postCharteredCostProjectAPI,
  getCharteredCostProjectListAPI,
  putCharteredCostProjectAPI,
  delCharteredCostProjectAPI,
  charterInventoryRenderAPI,
  getVehicleTypeChildNameListAPI,
  charterInventoryonShelfAPI,
  charterInventoryAllCarCountAPI,
  valuationManagePageAPI,
  updateValuationManagePageAPI,
  getValuationManagementAPI,
  chooseCharteredComboAPI,
  chooseValuationManagementCostProjectAPI,
  delValuationManagementComboAPI,
  charteredValuationManagementPutStatusAPI,
};

// -------------------------------租车业务相关接口-----------------------------------

// 租车预订规则
const rentBookAPI = (data) => {
  return service({
    url: "/manage/ticketRegulation/getRental",
    method: "get",
    data,
  });
};
// 租车退票规则
const rentUnsubAPI = (data) => {
  return service({
    url: "/manage/refundTicketRegulation/getRental",
    method: "get",
    data,
  });
};
// 租车使用规则
const rentUseAPI = (data) => {
  return service({
    url: "/manage/car/use/regulation/getRental",
    method: "get",
    data,
  });
};
// 修改租车预订规则
const updateRentBookAPI = (data) => {
  return service({
    url: "/manage/ticketRegulation/putRental",
    method: "put",
    data,
  });
};
// 删除租车退票规则
const delRentUnsubAPI = (data) => {
  return service({
    url: "/manage/refundTicketRegulation/delRental/" + data.id,
    method: "delete",
    data,
  });
};
// 修改租车使用规则
const updateRentUseAPI = (data) => {
  return service({
    url: "/manage/car/use/regulation/putRental",
    method: "put",
    data,
  });
};
// 修改租车退票规则
const updateRentUnsubAPI = (data) => {
  return service({
    url: "/manage/refundTicketRegulation/putRental",
    method: "put",
    data,
  });
};
// 新增租车退票规则
const addRentUnsubAPI = (data) => {
  return service({
    url: "/manage/refundTicketRegulation/postRental",
    method: "post",
    data,
  });
};
//查询租车订单表格数据
const searchRentTableAPI = (data) => {
  return service({
    url: "order/rental/pageOrderList",
    method: "post",
    data,
  });
};

//导出租车订单
const exportRentOrderAPI = (data) => {
  return service({
    url: "/order/rental/pageOrderListExport",
    method: "post",
    data,
    responseType: "blob",
  });
};
// 租车-新增取车城市
const cityInsertAPI = (data) => {
  return service({
    url: "/manage/rental/city/insert",
    method: "post",
    data,
  });
};

// 租车-取车城市获取
const cityRenderAPI = (data) => {
  return service({
    url: "/manage/rental/city/district/page",
    method: "post",
    data,
  });
};

// 租车-取车城市更新
const rentalCityUpdateAPI = (data) => {
  return service({
    url: `/manage/rental/city/update/${data.id}`,
    method: "put",
    data,
  });
};

// 租车-取车城市删除
const rentalCityDeleteAPI = (data) => {
  return service({
    url: `/manage/rental/city/delete/${data.id}`,
    method: "delete",
    data,
  });
};

// 租车-获取取车城市列表
const rentalCityListAPI = (data) => {
  return service({
    url: `/manage/rental/city/list`,
    method: "post",
    data,
  });
};

// 包车-业务城市管理列表
const queryCharteredCityList = (data) => {
  return service({
    url: `/manage/charteredCity/queryCharteredCityList`,
    method: "post",
    data,
  });
};

// 包车-添加包车业务城市管理
const saveCharteredCity = (data) => {
  return service({
    url: `/manage/charteredCity/saveCharteredCity`,
    method: "post",
    data,
  });
};

// 包车-更新包车业务城市管理
const updateCharteredCity = (data) => {
  return service({
    url: `/manage/charteredCity/updateCharteredCity`,
    method: "put",
    data,
  });
};

// 包车-删除包车业务城市管理
const deleteCharteredCityById = (data) => {
  return service({
    url: `/manage/charteredCity/deleteCharteredCityById?id=${data}`,
    method: "delete",
  });
};

//租车库存管理表格数据
const rentManageTableAPI = (data) => {
  return service({
    url: "/manage/rental/car/service/provider/vehicle/model/inventory/page",
    method: "post",
    data,
  });
};

// 租车-新增取车点接口
const rentalPointAddAPI = (data) => {
  return service({
    url: `/manage/rental/address/add`,
    method: "post",
    data,
  });
};

//修改租车库存上限
const updataManageLimitAPI = (data) => {
  return service({
    url: "/manage/rental/car/service/provider/vehicle/model/inventory/allCarCount",
    method: "post",
    data,
  });
};

// 租车-获取所属区县列表
const rentalDistrictListAPI = (data) => {
  return service({
    url: `/manage/rental/district/list`,
    method: "post",
    data,
  });
};

//租车库存上架/下架
const rentIsUseAPI = (data) => {
  return service({
    url: "/manage/rental/car/service/provider/vehicle/model/inventory/onShelf",
    method: "put",
    data,
  });
};

//租车库存导出
const rentManageExpAPI = (data) => {
  return service({
    url: "/manage/rental/car/service/provider/vehicle/model/inventory/export",
    method: "POST",
    data,
    responseType: "blob",
  });
};

// 租车-取车点列表数据API
const rentalTableDataAPI = (data) => {
  return service({
    url: `/manage/rental/address/list`,
    method: "get",
    data,
  });
};

// 租车-修改取车点
const rentalTableDataUpdateAPI = (data) => {
  return service({
    url: `/manage/rental/address/update`,
    method: "post",
    data,
  });
};

// 获取所有已开启的租车车型模板
const getEnableRentalVehicleModelTypeListAPI = (data) => {
  return service({
    url: `/manage/vehicleModelType/getEnableRentalVehicleModelTypeList`,
    method: "get",
    data,
  });
};

// 获取所有租车--新增车型相关下拉框字典数据
const getRentalDistListAPI = (data) => {
  return service({
    url: `/manage/rental/car/service/provider/vehicle/model/distList`,
    method: "get",
    data,
  });
};

//获取租车表格数据
const rentChargeManageTableAPI = (data) => {
  return service({
    url: "/manage/rental/valuationManagement/pageRentalValuationManagementDTOList",
    method: "post",
    data,
  });
};
// 租车--车商管理下的新增租车车型
const rentalAddDataAPI = (data) => {
  return service({
    url: `/manage/rental/car/service/provider/vehicle/model/add`,
    method: "post",
    data,
  });
};

// 修改指定租车管理计价费用
const updateRentPriceAPI = (data) => {
  return service({
    url: "/manage/rental/valuationManagement/putRentalValuationManagement",
    method: "put",
    data,
  });
};

// 租车--车商管理下的租车车型表格数据获取
const rentalRenderData3API = (data) => {
  return service({
    url: `/manage/rental/car/service/provider/vehicle/model/list`,
    method: "post",
    data,
  });
};

// 租车--车商管理下的租车车型表格数据删除接口
const rentalDeleteDataAPI = (data) => {
  return service({
    url: `/manage/rental/car/service/provider/vehicle/model/delete`,
    method: "delete",
    data,
  });
};

// 租车--车商管理下的租车车型表格数据编辑
const rentalUpdateDataAPI = (data) => {
  return service({
    url: `/manage/rental/car/service/provider/vehicle/model/update`,
    method: "put",
    data,
  });
};

//租车计价管理导出
const rentPriceManageExpAPI = (data) => {
  return service({
    url: `/manage/rental/valuationManagement/pageRentalValuationManagementDTOExport`,
    method: "post",
    data,
    responseType: "blob",
  });
};

export {
  rentBookAPI,
  rentUnsubAPI,
  rentUseAPI,
  delRentUnsubAPI,
  addRentUnsubAPI,
  updateRentUnsubAPI,
  updateRentUseAPI,
  updateRentBookAPI,
  searchRentTableAPI,
  exportRentOrderAPI,
  rentManageTableAPI,
  updataManageLimitAPI,
  rentIsUseAPI,
  rentManageExpAPI,
  cityInsertAPI,
  cityRenderAPI,
  rentalCityUpdateAPI,
  rentalCityDeleteAPI,
  rentalCityListAPI,
  rentalPointAddAPI,
  rentalDistrictListAPI,
  rentalTableDataAPI,
  rentalTableDataUpdateAPI,
  getEnableRentalVehicleModelTypeListAPI,
  getRentalDistListAPI,
  rentChargeManageTableAPI,
  updateRentPriceAPI,
  rentalAddDataAPI,
  rentalRenderData3API,
  rentalDeleteDataAPI,
  rentalUpdateDataAPI,
  rentPriceManageExpAPI,
  queryCharteredCityList,
  saveCharteredCity,
  updateCharteredCity,
  deleteCharteredCityById,
};

// -------------------------------发票管理模块相关接口-----------------------------------

//开票项目列表
const openInvoceListAPI = (data) => {
  return service({
    url: "/manage/invoice/project/management/list",
    method: "get",
    data,
  });
};

//新增或修改开票项目
const updateOrAddInvoceAPI = (data) => {
  return service({
    url: "/manage/invoice/project/management/operation",
    method: "post",
    data,
  });
};

//开票项目删除
const deleteInvoceAPI = (data) => {
  return service({
    url: "/manage/invoice/project/management/delete",
    method: "post",
    data,
  });
};

//查询所有开票记录
const invoceListAPI = (data) => {
  return service({
    url: "/manage/invoice/list",
    method: "post",
    data,
  });
};

//导出开票记录
const invoceListExpAPI = (data) => {
  return service({
    url: "/manage/invoice/export",
    method: "post",
    data,
    responseType: "blob",
  });
};

export {
  openInvoceListAPI,
  updateOrAddInvoceAPI,
  deleteInvoceAPI,
  invoceListAPI,
  invoceListExpAPI,
};

// -------------------------------村通客运管理相关接口-----------------------------------

//分页查询城市区县
const villageCityCountyListAPI = (data) => {
  return service({
    url: "/manage/village/district/get",
    method: "get",
    data,
  });
};

//根据id删除区县
const deleteCountyAPI = (data) => {
  return service({
    url: "/manage/village/district/delete",
    method: "delete",
    data,
  });
};

//修改村通客运区县
const updateCountyAPI = (data) => {
  return service({
    url: "/manage/village/district/update",
    method: "put",
    data,
  });
};

//添加村通客运区县
const addCountyAPI = (data) => {
  return service({
    url: "/manage/village/district/add",
    method: "post",
    data,
  });
};

//城市区县状态启用/禁用
const cityIsUseAPI = (data) => {
  return service({
    url: `/manage/village/district/status/${data.id}/${data.status}`,
    method: "put",
    data,
  });
};

export {
  villageCityCountyListAPI,
  deleteCountyAPI,
  updateCountyAPI,
  addCountyAPI,
  cityIsUseAPI,
};

//---------------定制班线-------------------

//定制班线站点查询
export const getStationByKeyword = (data) => {
  return service({
    url: `/manage/custom/station/list`,
    method: "post",
    data,
  });
};
//定制班线订单分页查询
export const getCustomOrder = (data) => {
  return service({
    url: `/order/custom/order/page`,
    method: "post",
    data,
  });
};
//定制班线订单详情查询
export const getCustomOrderInfo = (data) => {
  return service({
    url: `/order/custom/order/info`,
    method: "get",
    data,
  });
};
//导出定制班线订单
export const exportCustomOrder = (data) => {
  return service({
    url: `/order/custom/order/export`,
    method: "post",
    responseType: "blob",
    data,
  });
};

//---------------app版本管理-------------
//app上传
export const uploadApp = (data, callback) => {
  return service({
    url: `/manage/uploadImage/file/uploadDriverApp`,
    method: "post",
    data,
    onUploadProgress: (e) => callback(e),
  });
};
//app分页查询
export const getAppListByPage = (data) => {
  return service({
    url: `/manage/app/pageApp`,
    method: "post",
    data,
  });
};
//app新增
export const appendApp = (data) => {
  return service({
    url: `/manage/app/addApp`,
    method: "post",
    data,
  });
};
//app删除
export const delApp = (data) => {
  return service({
    url: `/manage/app/delApp/${data.appId}`,
    method: "delete",
  });
};
//app更新
export const updateAppApi = (data) => {
  return service({
    url: `/manage/app/putApp`,
    method: "put",
    data,
  });
};

// ----------------------v4.2.0------------------------
//改签规则获取
export const rebookRegulationApi = (data) => {
  return service({
    url: `/manage/rebookRegulation/get`,
    method: "get",
    data,
  });
};
//改签规则修改
export const rebookRegulationUpdateApi = (data) => {
  return service({
    url: `/manage/rebookRegulation/put`,
    method: "put",
    data,
  });
};
//网络日志数据获取
export const pageGatewayLogApi = (data) => {
  return service({
    url: `/manage/gatewayLog/pageGatewayLog`,
    method: "post",
    data,
  });
};
//日志数据获取
export const getInterfaceNameListApi = (data) => {
  return service({
    url: `/manage/gatewayLog/getInterfaceNameList`,
    method: "get",
  });
};
//网络日志导出接口
export const getInterfaceNameListExportApi = (data) => {
  return service({
    url: `/manage/gatewayLog/export`,
    method: "post",
    data,
    responseType: "blob",
  });
};

//调度线路绑定---获取所有线路数据
export const scheduleLineAssociationListAPI = (data) => {
  return service({
    url: `/manage/scheduleLineAssociation/lineList`,
    method: "get",
    data,
  });
};
//调度线路绑定---获取表格数据
export const scheduleLineAssociationTableDataAPI = (data) => {
  return service({
    url: `/manage/scheduleLineAssociation/pageList`,
    method: "post",
    data,
  });
};
//调度线路绑定---编辑线路信息
export const updateLineInfoAPI = (data) => {
  return service({
    url: `/manage/scheduleLineAssociation/put`,
    method: "PUT",
    data,
  });
};
//调度线路绑定---删除线路
export const deleteLineInfoAPI = (data) => {
  return service({
    url: `/manage/scheduleLineAssociation/delete/${data}`,
    method: "DELETE",
    data,
  });
};
//调度线路绑定---添加新线路
export const addNewLineAPI = (data) => {
  return service({
    url: `/manage/scheduleLineAssociation/save`,
    method: "POST",
    data,
  });
};
//调度线路绑定---获取指定公司员工信息下拉列表
export const getUsersByCompanyIdAPI = (data) => {
  return service({
    url: `/manage/scheduleLineAssociation/getUsersByCompanyId`,
    method: "GET",
    data,
  });
};
// ----------------------v6.0------------------------
//系统主题分页
export const querySystemThemePageAPI = (data) => {
  return service({
    url: `/manage/systemTheme/querySystemThemePage`,
    method: "POST",
    data,
  });
};
//更新系统主题
export const updateSystemThemeAPI = (data) => {
  return service({
    url: `/manage/systemTheme/updateSystemTheme`,
    method: "PUT",
    data,
  });
};
//添加系统主题
export const saveSystemThemeAPI = (data) => {
  return service({
    url: `/manage/systemTheme/saveSystemTheme`,
    method: "POST",
    data,
  });
};
//获取系统主题详情
export const querySystemThemeInfoAPI = (data) => {
  return service({
    url: `/manage/systemTheme/querySystemThemeInfo`,
    method: "GET",
    data,
  });
};
//删除系统主题
export const deleteSystemThemeByIdAPI = (data) => {
  return service({
    url: `/manage/systemTheme/deleteSystemThemeById`,
    method: "DELETE",
    data,
  });
};
//获取系统主题-模块配置列表
export const querySystemThemeModelListAllAPI = (data) => {
  return service({
    url: `/manage/systemThemeModel/querySystemThemeModelListAll`,
    method: "GET",
    data,
  });
};
//获取系统主题-模块配置详情
export const querySystemThemeModelInfoAPI = (data) => {
  return service({
    url: `/manage/systemThemeModel/querySystemThemeModelInfo`,
    method: "GET",
    data,
  });
};
//更新系统主题-模块配置
export const updateSystemThemeModelAPI = (data) => {
  return service({
    url: `/manage/systemThemeModel/updateSystemThemeModel`,
    method: "PUT",
    data,
  });
};
//添加系统主题-模块配置
export const saveSystemThemeModelAPI = (data) => {
  return service({
    url: `/manage/systemThemeModel/saveSystemThemeModel`,
    method: "POST",
    data,
  });
};
//更新系统主题-TAB
export const updateSystemThemeTabAPI = (data) => {
  return service({
    url: `/manage/systemThemeTab/updateSystemThemeTab`,
    method: "PUT",
    data,
  });
};
//更新系统主题-首页
export const updateSystemThemeHomePageAPI = (data) => {
  return service({
    url: `/manage/systemThemeHomePage/updateSystemThemeHomePage`,
    method: "PUT",
    data,
  });
};
//更新系统主题-金刚区
export const updateSystemThemeJinGangDistrictAPI = (data) => {
  return service({
    url: `/manage/systemThemeJinGangDistrict/updateSystemThemeJinGangDistrict`,
    method: "PUT",
    data,
  });
};
//更新系统主题-副金刚区
export const updateSystemThemeAssistantJinGangDistrictAPI = (data) => {
  return service({
    url: `/manage/systemThemeAssistantJinGangDistrict/updateSystemThemeAssistantJinGangDistrict`,
    method: "PUT",
    data,
  });
};
//调整数据顺序-金刚区
export const jinGangAdjustmentSerialAPI = (data) => {
  return service({
    url: `/manage/systemThemeJinGangDistrict/adjustmentSerial`,
    method: "POST",
    data,
  });
};
//调整数据顺序-副金刚区
export const assistantJinGangAdjustmentSerialAPI = (data) => {
  return service({
    url: `/manage/systemThemeAssistantJinGangDistrict/adjustmentSerial`,
    method: "POST",
    data,
  });
};

// 班线管理-所属主线路下拉列表数据获取
export const queryIntercityMainLineListAPI = (data) => {
  return service({
    url: `/manage/intercityMainLine/queryIntercityMainLineList`,
    method: "POST",
    data,
  });
};

// 主线路管理-表格数据获取
export const queryIntercityMainLinePageAPI = (data) => {
  return service({
    url: `/manage/intercityMainLine/queryIntercityMainLinePage`,
    method: "POST",
    data,
  });
};

// 主线路管理-新增数据
export const saveIntercityMainLineAPI = (data) => {
  return service({
    url: `/manage/intercityMainLine/saveIntercityMainLine`,
    method: "POST",
    data,
  });
};

// 主线路管理-新增数据
export const updateIntercityMainLineAPI = (data) => {
  return service({
    url: `/manage/intercityMainLine/updateIntercityMainLine`,
    method: "PUT",
    data,
  });
};
// ----------------------v7.0------------------------
//渠道指定数据查询
export const queryChannelListAPI = (data) => {
  return service({
    url: `/manage/channel/queryChannelList`,
    method: "POST",
    data,
  });
};
//渠道全部数据查询
export const queryAllChannelListAPI = (data) => {
  return service({
    url: `/manage/channel/queryAllChannelList`,
    method: "POST",
    data,
  });
};
//渠道分页查询
export const queryChannelPageAPI = (data) => {
  return service({
    url: `/manage/channel/queryChannelPage`,
    method: "POST",
    data,
  });
};
//修改渠道
export const updateChannelAPI = (data) => {
  return service({
    url: `/manage/channel/updateChannel`,
    method: "PUT",
    data,
  });
};
//新增渠道
export const saveChannelAPI = (data) => {
  return service({
    url: `/manage/channel/saveChannel`,
    method: "POST",
    data,
  });
};
//座位规划模板分页查询
export const querySeatPlanPageAPI = (data) => {
  return service({
    url: `/manage/seatPlan/querySeatPlanPage`,
    method: "POST",
    data,
  });
};
//更新座位规划模板
export const updateSeatPlanAPI = (data) => {
  return service({
    url: `/manage/seatPlan/updateSeatPlan`,
    method: "PUT",
    data,
  });
};
//添加座位规划模板表
export const saveSeatPlanAPI = (data) => {
  return service({
    url: `/manage/seatPlan/saveSeatPlan`,
    method: "POST",
    data,
  });
};
//座位规划模板详情
export const querySeatPlanInfoAPI = (data) => {
  return service({
    url: `/manage/seatPlan/querySeatPlanInfo`,
    method: "GET",
    data,
  });
};
// 获取座位下拉框数据
export const queryBySeatNameAPI = (data) => {
  return service({
    url: `/manage/seatPlan/queryBySeatName`,
    method: "GET",
    data,
  });
};
// 删除座位规划模板
export const deleteSeatPlanByIdAPI = (data) => {
  return service({
    url: `/manage/seatPlan/deleteSeatPlanById?id=${data.id}`,
    method: "delete",
  });
};

// -----------------v7.1---------------------
// 调度任务管理--左侧树
export const queryShiftIntercityScheduleMainLineTreeAPI = (data) => {
  return service({
    url: `/manage/shiftintercityschedule/queryShiftIntercityScheduleMainLineTree`,
    method: "GET",
  });
};
// 调度改派v7.1
const updateDispatchOfDriverOrCarAPI = (data) => {
  return service({
    url: "/manage/schedule/updateDispatchOfDriverOrCar",
    method: "put",
    data,
  });
};
// 后台改签
export const reschedulingAPI = (data) => {
  return service({
    url: "/order/intercity/server/rescheduling",
    method: "put",
    data,
  });
};
// 获取订单详细信息
export const getOrderInfoAPI = (data) => {
  return service({
    url: "/order/intercity/server/getOrderInfo",
    method: "get",
    data,
  });
};
// 调度任务管理--调度任务列表查询
export const queryScheduleTasksAPI = (data) => {
  return service({
    url: `/manage/schedule/queryScheduleTasks`,
    method: "GET",
    data,
  });
};
// 调度任务管理--增派任务
export const saveShiftIntercityScheduleAPI = (data) => {
  return service({
    url: `/manage/shiftintercityschedule/saveShiftIntercitySchedule`,
    method: "POST",
    data,
  });
};
// 调度任务管理--取消任务
export const deleteShiftIntercityScheduleByIdAPI = (data) => {
  return service({
    url: `/manage/shiftintercityschedule/deleteShiftIntercityScheduleById`,
    method: "DELETE",
    data,
  });
};

// 调度任务管理--改派任务
export const updateShiftIntercityScheduleAPI = (data) => {
  return service({
    url: `/manage/shiftintercityschedule/updateShiftIntercitySchedule`,
    method: "PUT",
    data,
  });
};

// 调度任务管理--快速派单
export const quickScheduleTask = (data) => {
  return service({
    url: `/manage/shiftintercityschedule/quickScheduleTask`,
    method: "post",
    data,
  });
};

// 评价指标查询
export const queryEvaluationLabelListAPI = (data) => {
  return service({
    url: `/manage/passengerevaluationlabel/queryPassengerEvaluationLabelList`,
    method: "post",
    data,
  });
};

// ===================城际专车调度回访=============================

//--新增回访
export const addNewVisitRecord = (data) => {
  return service({
    url: "/manage/interViewInfo/saveInterviewInfo",
    method: "post",
    data,
  });
};
// 查询回访记录分页
export const queryVisitRecordsPage = (data) => {
  return service({
    url: "/manage/interViewInfo/queryInterviewInfoPage",
    method: "post",
    data,
  });
};

// 查询回访记录详情
export const queryVisitRecordDetail = (data) => {
  return service({
    url: "/manage/interViewInfo/queryInterviewInfoInfo",
    method: "get",
    data,
  });
};

// ========================班线 预留票==================================
// 新增预留票
export const addReserveTicket = (data) => {
  return service({
    url: "/manage/reservedticket/saveReservedTicket",
    method: "post",
    data,
  });
};
// 指标更新
export const updateEvaluationLabelListAPI = (data) => {
  return service({
    url: `/manage/passengerevaluationlabel/updatePassengerEvaluationLabel`,
    method: "put",
    data,
  });
};
// 预留票查询
export const queryReserveTicketPage = (data) => {
  return service({
    url: "/manage/reservedticket/queryReservedTicketPage",
    method: "post",
    data,
  });
};
// 预留记录导出
export const exportReserveTicketPage = (data) => {
  return service({
    url: "/manage/reservedticket/export",
    method: "post",
    data,
    responseType: "blob",
  });
};

// 解除预留票
export const unReservedTicket = (data) => {
  return service({
    url: "/manage/reservedticket/releaseReservation",
    method: "put",
    data,
  });
};
// 评价管理分页查询
export const queryEvaluationPageAPI = (data) => {
  return service({
    url: `/manage/passengerevaluation/queryPassengerEvaluationPage`,
    method: "post",
    data,
  });
};
// 评价管理新增评价
export const saveEvaluationPageAPI = (data) => {
  return service({
    url: `/manage/passengerevaluation/savePassengerEvaluation`,
    method: "post",
    data,
  });
};
// 根据班次id 查询司机订单数据
export const byShiftIntercityIdAPI = (data) => {
  return service({
    url: `/order/driver/intercity/list/byShiftIntercityId?shiftIntercityId=${data.shiftIntercityId}&orderStatus=40`,
    method: "get",
  });
};
// 评价管理导出
export const exportEvaluationAPI = (data) => {
  return service({
    url: `/manage/passengerevaluation/exportPassengerEvaluation`,
    method: "post",
    data,
    responseType: "blob",
  });
};
// 评价确认
export const updateEvaluationAPI = (data) => {
  return service({
    url: `/manage/passengerevaluation/updatePassengerEvaluation`,
    method: "put",
    data,
  });
};
// 编辑新增删除例检配置
export const saveOrUpdateOrDeleteConfigAPI = (data) => {
  return service({
    url: `/manage/routineInspection/saveOrUpdateRoutineInspection`,
    method: "post",
    data,
  });
};
// 获取例检配置
export const queryConfigAPI = (data) => {
  return service({
    url:
      `/manage/routineInspection/queryRoutineInspectionInfo?channelNo=` + data,
    method: "get",
  });
};
// 删除子配置
export const deleteContentByIdAPI = (data) => {
  return service({
    url:
      `/manage/routineInspection/deleteRoutineInspectionContentById?id=` + data,
    method: "delete",
  });
};
//例检超时记录
export const queryRoutineInspectionRecordListAPI = (data) => {
  return service({
    url: `/manage/routineInspectionRecord/queryRoutineInspectionRecordList`,
    method: "post",
    data,
  });
};
//导出例检超时记录
export const exportRoutineInspectionTimeOutRecordAPI = (data) => {
  return service({
    url: `/manage/routineInspectionRecord/exportRoutineInspectionTimeOutRecord`,
    method: "post",
    data,
    responseType: "blob",
  });
};
//获取例检记录分页
export const queryRoutineInspectionRecordPageAPI = (data) => {
  return service({
    url: `/manage/routineInspectionRecord/queryRoutineInspectionRecordPage`,
    method: "post",
    data,
  });
};
//导出例检记录分页
export const exportRoutineInspectionRecordAPI = (data) => {
  return service({
    url: `/manage/routineInspectionRecord/exportRoutineInspectionRecord`,
    method: "post",
    data,
    responseType: "blob",
  });
};
//获取例检记录详情
export const queryRoutineInspectionRecordInfoAPI = (data) => {
  return service({
    url: `/manage/routineInspectionRecord/queryRoutineInspectionRecordInfo`,
    method: "get",
    data,
  });
};

// 城际专车分账订单分页
export const queryIntercityOrderPageAPI = (data) => {
  return service({
    url: `/order/kkLedgerOrderPay/queryKkLedgerOrderPayPage`,
    method: "post",
    data,
  });
};
// 导出城际专车分账订单分页
export const exportIntercityOrderPageAPI = (data) => {
  return service({
    url: `/order/kkLedgerOrderPay/exportKkLedgerOrderPay`,
    method: "post",
    data,
    responseType: "blob",
  });
};

// 城际专车分账订单分页-回退
export const queryKkLedgerOrderRefundPageAPI = (data) => {
  return service({
    url: `/order/kkLedgerOrderRefund/queryKkLedgerOrderRefundPage`,
    method: "post",
    data,
  });
};
// 导出城际专车分账订单分页-回退
export const exportKkLedgerOrderRefundAPI = (data) => {
  return service({
    url: `/order/kkLedgerOrderRefund/exportKkLedgerOrderRefund`,
    method: "post",
    data,
    responseType: "blob",
  });
};
// 重新计算城际专车分账订单
export const updateIntercityLedgerOrderAPI = (data) => {
  return service({
    url: `/order/kkLedgerOrderPay/updateKkLedgerOrderPay`,
    method: "put",
    data,
  });
};

// 修改预留票数量
export const updateReservedTicket = (data) => {
  return service({
    url: "/manage/reservedticket/updateReservedTicket",
    method: "put",
    data,
  });
};
/**
 * @description 7.2
 */
//商户号分页查询
export const queryWechatCertificatePageAPI = (data) => {
  return service({
    url: `/manage/wechat/certificate/queryWechatCertificatePage`,
    method: "POST",
    data,
  });
};
//更新商户号
export const mchStatusEnableUpdateAPI = (data) => {
  return service({
    url: `/manage/wechat/certificate/mchStatusEnableUpdate`,
    method: "PUT",
    data,
  });
};
//添加商户号
export const saveWechatCertificateAPI = (data) => {
  return service({
    url: `/manage/wechat/certificate/saveWechatCertificate`,
    method: "POST",
    data,
  });
};
//微信支付secret文件上传
export const secretUploadImageAPI = (data) => {
  return service({
    url: `/manage/uploadImage/file/secret`,
    method: "POST",
    header: "multipart/form-data",
    data,
  });
};
//商户分账管理分页
export const queryWechatMchLedgerPageAPI = (data) => {
  return service({
    url: `/manage/kkWechatLedger/queryKkWechatLedgerPage`,
    method: "POST",
    data,
  });
};
//添加商户分账管理
export const saveWechatMchLedgerAPI = (data) => {
  return service({
    url: `/manage/kkWechatLedger/saveKkWechatLedger`,
    method: "POST",
    data,
  });
};
//更新指定配置商户的最大分账比例
export const updateWechatMaxLedgerRatioByIdAPI = (data) => {
  return service({
    url: `/manage/kkWechatLedger/updateKkWechatMaxLedgerRatioById/${data.id}`,
    method: "PUT",
  });
};
//更新所有最大的分账比例
export const updateAllWechatMaxLedgerRatioAPI = (data) => {
  return service({
    url: `/manage/kkWechatLedger/updateAllWechatMaxLedgerRatio`,
    method: "PUT",
    data,
  });
};
//获取指定配置商户的最大分账比例
export const queryWechatMaxLedgerRatioByIdAPI = (data) => {
  return service({
    url: `/manage/kkWechatLedger/queryWechatMaxLedgerRatioById/${data.id}`, //7.8.1迭代
    method: "GET",
  });
};
//删除商户分账管理
export const deleteWechatMchLedgerByIdAPI = (data) => {
  return service({
    url: `/manage/kkWechatLedger/deleteKkWechatLedgerById`,
    method: "delete",
    data,
  });
};
//添加商户分账接收方
export const saveWechatMchLedgerReceiveAPI = (data) => {
  return service({
    url: `/manage/kkWechatLedgerReceive/saveKkWechatLedgerReceive`,
    method: "POST",
    data,
  });
};
//获取商户分账管理详情
export const queryWechatMchLedgerInfoAPI = (data) => {
  return service({
    url: `/manage/kkWechatLedger/queryKkWechatLedgerInfo`,
    method: "GET",
    data,
  });
};
//删除商户分账接收方
export const deleteWechatMchLedgerReceiveByIdAPI = (data) => {
  return service({
    url: `/manage/kkWechatLedgerReceive/deleteKkWechatLedgerReceiveById`,
    method: "delete",
    data,
  });
};
//更新商户分账接收方
export const updateWechatMchLedgerReceiveAPI = (data) => {
  return service({
    url: `/manage/kkWechatLedgerReceive/updateKkWechatLedgerReceive`,
    method: "PUT",
    kkhQuery: true,
    data,
  });
};
//更新商户分账管理
export const updateWechatMchLedgerAPI = (data) => {
  return service({
    url: `/manage/wechatMchLedger/updateWechatMchLedger`,
    method: "PUT",
    data,
  });
};
// 更新开班属性接口
export const updateVehicleShiftRegulationAPI = (data) => {
  return service({
    url: `/manage/vehicleShiftRegulation/updateVehicleShiftRegulation`,
    method: "PUT",
    data,
  });
};
//司机服务满意度列表
export const queryDriverServiceSatisfactionListAPI = (data) => {
  return service({
    url: `/manage/driverServiceSatisfaction/queryDriverServiceSatisfactionList`,
    method: "POST",
    data,
  });
};
//更新司机服务满意度
export const updateDriverServiceSatisfactionAPI = (data) => {
  return service({
    url: `/manage/driverServiceSatisfaction/updateDriverServiceSatisfaction`,
    method: "PUT",
    data,
  });
};
//获取司机考服务满意度基础积分表详情
export const queryDriverServiceSatisfactionBaseIntegralInfoAPI = (data) => {
  return service({
    url: `/manage/driverServiceSatisfactionBaseIntegral/queryDriverServiceSatisfactionBaseIntegralInfo`,
    method: "GET",
    data,
  });
};
//更新司机考服务满意度基础积分表
export const updateDriverServiceSatisfactionBaseIntegralAPI = (data) => {
  return service({
    url: `/manage/driverServiceSatisfactionBaseIntegral/updateDriverServiceSatisfactionBaseIntegral`,
    method: "PUT",
    data,
  });
};
//司机实载率列表
export const queryDriverRealLoadRateListAPI = (data) => {
  return service({
    url: `/manage/driverRealLoadRate/queryDriverRealLoadRateList`,
    method: "POST",
    data,
  });
};
//添加司机实载率
export const saveDriverRealLoadRateAPI = (data) => {
  return service({
    url: `/manage/driverRealLoadRate/saveDriverRealLoadRate`,
    method: "POST",
    data,
  });
};
//获取司机考核实载率计算方式详情
export const queryDriverRealLoadRateBaseIntegralInfoAPI = (data) => {
  return service({
    url: `/manage/driverRealLoadRateBaseIntegral/queryDriverRealLoadRateBaseIntegralInfo`,
    method: "GET",
    data,
  });
};
//更新司机考核实载率计算方式
export const updateDriverRealLoadRateBaseIntegralAPI = (data) => {
  return service({
    url: `/manage/driverRealLoadRateBaseIntegral/updateDriverRealLoadRateBaseIntegral`,
    method: "PUT",
    data,
  });
};
//删除司机实载率
export const deleteDriverRealLoadRateByIdAPI = (data) => {
  return service({
    url: `/manage/driverRealLoadRate/deleteDriverRealLoadRateById`,
    method: "delete",
    data,
  });
};
//更新司机实载率
export const updateDriverRealLoadRateAPI = (data) => {
  return service({
    url: `/manage/driverRealLoadRate/updateDriverRealLoadRate`,
    method: "PUT",
    data,
  });
};
//获取司机考核-拉新积分兑换配置详情
export const queryDriverInviteNewUserIntegralConvertInfoAPI = (data) => {
  return service({
    url: `/manage/driverInviteNewUserIntegralConvert/queryDriverInviteNewUserIntegralConvertInfo`,
    method: "GET",
    data,
  });
};
//更新司机考核-拉新积分兑换配置
export const updateDriverInviteNewUserIntegralConvertAPI = (data) => {
  return service({
    url: `/manage/driverInviteNewUserIntegralConvert/updateDriverInviteNewUserIntegralConvert`,
    method: "PUT",
    data,
  });
};
//查询 拉新积分规则
export const queryInfoByChannelAPI = (data) => {
  return service({
    url: `/manage/pull-new-integral-rule/queryInfoByChannel`,
    method: "GET",
    data,
  });
};
//更新拉新积分规则
export const updatePullNewIntegralRuleAPI = (data) => {
  return service({
    url: `/manage/pull-new-integral-rule/update`,
    method: "PUT",
    data,
  });
};
//获取系统配置-安全管理配置详情
export const querySecurityManagementInfoAPI = (data) => {
  return service({
    url: `/manage/securityManagement/querySecurityManagementInfo`,
    method: "GET",
    data,
  });
};
//更新系统配置-安全管理配置
export const updateSecurityManagementAPI = (data) => {
  return service({
    url: `/manage/securityManagement/updateSecurityManagement`,
    method: "PUT",
    data,
  });
};

// 更新开班属性接口
export const cloneTrainAPI = (data) => {
  return service({
    url: `/manage/vehicle/shift/clone/${data.id}`,
    method: "PUT",
    data,
  });
};
// 查询开班属性数据接口
export const queryVehicleShiftRegulationInfoAPI = (data) => {
  return service({
    url: `/manage/vehicleShiftRegulation/queryVehicleShiftRegulationInfo`,
    method: "GET",
    data,
  });
};
// 行车视频抓拍设置详情
export const queryTakeInfoAPI = (data) => {
  return service({
    url: `/manage/channel/take/info?id=` + data,
    method: "GET",
  });
};
// 行车视频抓拍设置更新
export const updateTakeInfoAPI = (data) => {
  return service({
    url: `/manage/channel/take/update`,
    method: "put",
    data,
  });
};
// 行车视频抓拍记录分页
export const logPageAPI = (data) => {
  return service({
    url: `/manage/take/snap/log/page`,
    method: "post",
    data,
  });
};
// 行车视频抓拍记录详情
export const logGetInfoAPI = (data) => {
  return service({
    url: `/manage/take/snap/log/info?id=` + data,
    method: "get",
  });
};
// 行车视频抓拍记录导出
export const exportLogPageAPI = (data) => {
  return service({
    url: `/manage/take/snap/log/export`,
    method: "post",
    data,
    responseType: "blob",
  });
};
// 行车视频抓拍记录查验
export const checkLogPageAPI = (data) => {
  return service({
    url: `/manage/take/snap/log/check`,
    method: "post",
    data,
  });
};
// 司机积分明细
export const queryDriverIntegralPageAPI = (data) => {
  return service({
    url: `/manage/driver/integral/queryDriverIntegralPage`,
    method: "post",
    data,
  });
};
// 司机积分明细——导出
export const exportDriverIntegralAPI = (data) => {
  return service({
    url: `/manage/driver/integral/exportDriverIntegral`,
    method: "post",
    data,
    responseType: "blob",
  });
};
// 查询司机拉新积分
export const queryDriverIntegralInfoAPI = (data) => {
  return service({
    url: `/manage/driver/integral/queryMemberPointsRecord?phone=${data}`,
    method: "get",
  });
};
// 拉新积分兑换
export const InviteNewUsersAPI = (data) => {
  return service({
    url: `/manage/driver/integral/saveDriverIntegral/InviteNewUsers`,
    method: "post",
    data,
  });
};
// 司机积分统计
export const queryDriverIntegralStatPageAPI = (data) => {
  return service({
    url: `/manage/driver/integral/queryDriverIntegralPage/polymerization`,
    method: "post",
    data,
  });
};
// 司机积分统计——导出
export const exportDriverIntegralStatAPI = (data) => {
  return service({
    url: `/manage/driver/integral/exportDriverIntegral/polymerization`,
    method: "post",
    data,
    responseType: "blob",
  });
};

/**
 * @description 7.3
 */

//短信模板配置 - 分页查询
export const querySmsLicensePage = (data) => {
  return service({
    url: `/manage/smslicense/querySmsLicensePage`,
    method: "post",
    data,
  });
};
//短信模板配置-详情
export const querySmsLicenseInfo = (data) => {
  return service({
    url: `/manage/smslicense/querySmsLicenseInfo`,
    method: "get",
    data,
  });
};

//短信模板配置 - 更新
export const updateSmsLicense = (data) => {
  return service({
    url: `/manage/smslicense/updateSmsLicense`,
    method: "put",
    data,
  });
};
//短信模板配置 - 添加
export const saveSmsLicense = (data) => {
  return service({
    url: `/manage/smslicense/saveSmsLicense`,
    method: "post",
    data,
  });
};
//短信模式详情 - 分页查询
export const querySmsModelPage = (data) => {
  return service({
    url: `/manage/smsmodel/querySmsModelPage`,
    method: "post",
    data,
  });
};
//短信模式详情 - 详情
export const querySmsModelInfo = (data) => {
  return service({
    url: `/manage/smsmodel/querySmsModelInfo`,
    method: "get",
    data,
  });
};
//短信模式详情 - 删除
export const deleteSmsModelById = (data) => {
  return service({
    url: "/manage/smsmodel/deleteSmsModelById",
    method: "delete",
    data,
  });
};
//短信模式详情 - 添加
export const saveSmsModel = (data) => {
  return service({
    url: `/manage/smsmodel/saveSmsModel`,
    method: "post",
    data,
  });
};
//短信模式详情 - 更新
export const updateSmsModel = (data) => {
  return service({
    url: `/manage/smsmodel/updateSmsModel`,
    method: "put",
    data,
  });
};
//业务代码分页查询
export const querySmsBusinessCodePage = (data) => {
  return service({
    url: `/manage/smsbusinesscode/querySmsBusinessCodePage`,
    method: "post",
    data,
  });
};
//业务代码 - 添加
export const saveSmsBusinessCode = (data) => {
  return service({
    url: `/manage/smsbusinesscode/saveSmsBusinessCode`,
    method: "post",
    data,
  });
};

//虚拟号码配置分页
export const queryFictitiousPhonePage = (data) => {
  return service({
    url: `/manage/fictitiousPhone/queryFictitiousPhonePage`,
    method: "post",
    data,
  });
};

//虚拟号码配置 - 添加
export const saveFictitiousPhone = (data) => {
  return service({
    url: `/manage/fictitiousPhone/saveFictitiousPhone`,
    method: "post",
    data,
  });
};

//虚拟号码配置 - 更新
export const updateFictitiousPhone = (data) => {
  return service({
    url: `/manage/fictitiousPhone/updateFictitiousPhone`,
    method: "put",
    data,
  });
};

//虚拟号码配置 - 更新禁用启用
export const updateFictitiousStatus = (data) => {
  return service({
    url: `/manage/fictitiousPhone/updateFictitiousStatus`,
    method: "put",
    data,
  });
};

//虚拟号码配置 - 删除
export const deleteFictitiousPhoneById = (data) => {
  return service({
    url: "/manage/fictitiousPhone/deleteFictitiousPhoneById",
    method: "delete",
    data,
  });
};

//指定金额退款(包含退还退票手续费)
export const refundedChildOrder = (data) => {
  return service({
    url: "/order/intercity/server/refundedChildOrder",
    method: "put",
    data,
  });
};
/**
 * @description 7.4
 */
//分账订单详情查询
export const queryLedgerOrderInfoAPI = (data) => {
  return service({
    url: `/order/kkLedgerOrderPay/getByIdInfo/${data}`,
    method: "get",
  });
};
//查询用户权限内可用渠道信息
export const getCurrentUserChannelInfoAPI = (data) => {
  return service({
    url: `/manage/scheduleLineAssociation/getCurrentUserChannelInfo`,
    method: "get",
    data,
  });
};
//小程序业务代码配置分页
export const queryMiniProgramBusinessCodePageAPI = (data) => {
  return service({
    url: `/manage/miniprogrambusinesscode/queryMiniProgramBusinessCodePage`,
    method: "post",
    data,
  });
};
//新增业务代码
export const saveMiniProgramBusinessCodeAPI = (data) => {
  return service({
    url: `/manage/miniprogrambusinesscode/saveMiniProgramBusinessCode`,
    method: "post",
    data,
  });
};
//小程序渠道配置分页
export const queryMiniProgramLicensePageAPI = (data) => {
  return service({
    url: `/manage/miniprogramlicense/queryMiniProgramLicensePage`,
    method: "post",
    data,
  });
};
//添加小程序渠道配置
export const saveMiniProgramLicenseAPI = (data) => {
  return service({
    url: `/manage/miniprogramlicense/saveMiniProgramLicense`,
    method: "post",
    data,
  });
};
//更新小程序渠道配置
export const updateMiniProgramLicenseAPI = (data) => {
  return service({
    url: "/manage/miniprogramlicense/updateMiniProgramLicense",
    method: "put",
    data,
  });
};
//小程序模板配置分页
export const queryMiniProgramModelPageAPI = (data) => {
  return service({
    url: `/manage/miniprogrammodel/queryMiniProgramModelPage`,
    method: "post",
    data,
  });
};
//添加小程序模板配置
export const saveMiniProgramModelAPI = (data) => {
  return service({
    url: `/manage/miniprogrammodel/saveMiniProgramModel`,
    method: "post",
    data,
  });
};
//更新小程序模板配置
export const updateMiniProgramModelAPI = (data) => {
  return service({
    url: "/manage/miniprogrammodel/updateMiniProgramModel",
    method: "put",
    data,
  });
};
//删除短信模板配置
export const deleteMiniProgramModelByIdAPI = (data) => {
  return service({
    url: "/manage/miniprogrammodel/deleteMiniProgramModelById",
    method: "delete",
    data,
  });
};

//----------------------------- 报表中心 -------------------------------

//营收概括
export const revenueSummaryAPI = (data) => {
  return service({
    url: "/manage/reportCenter/revenueSummary",
    method: "post",
    data,
  });
};

//营收概括导出
export const revenueSummaryExportAPI = (data) => {
  return service({
    url: "/manage/reportCenter/revenueSummaryExport",
    method: "post",
    data,
    responseType: "blob",
  });
};

//线路统计
export const revenueLineSummaryAPI = (data) => {
  return service({
    url: "/manage/reportCenter/revenueLineSummary",
    method: "post",
    data,
  });
};

//线路统计导出
export const revenueLineSummaryExportAPI = (data) => {
  return service({
    url: "/manage/reportCenter/revenueLineSummaryExport",
    method: "post",
    data,
    responseType: "blob",
  });
};

//购票方式统计
export const waysBuyTicketAPI = (data) => {
  return service({
    url: "/manage/reportCenter/waysBuyTicket",
    method: "post",
    data,
  });
};

//购票方式导出
export const waysBuyTicketExportAPI = (data) => {
  return service({
    url: "/manage/reportCenter/waysBuyTicketExport",
    method: "post",
    data,
    responseType: "blob",
  });
};

//根据公司(以及子公司)获取线路名称
export const listByCompanyIdAPI = (data) => {
  return service({
    url: "/manage/line/listByCompanyId?companyId=" + data,
    method: "get",
  });
};

//根据班次ID查询班次信息
export const intercityAPI = (data) => {
  return service({
    url: "/manage/shift/intercity?id=" + data,
    method: "get",
  });
};

//发票冲红
export const issueRedInvoiceAPI = (data) => {
  return service({
    url: "/manage/invoice/issueRedInvoice/" + data,
    method: "post",
  });
};

//分账总金额
export const queryTotalLedgerIncomeAPI = (data) => {
  return service({
    url: "/order/kkLedgerOrderPay/queryTotalLedgerIncome",
    method: "post",
    data,
  });
};

//分账回退总金额
export const queryKkLedgerRefundLedgerFeeAPI = (data) => {
  return service({
    url: "/order/kkLedgerOrderRefund/queryKkLedgerRefundLedgerFee",
    method: "post",
    data,
  });
};

//检测是否已开发票
export const isAlreadyInvoicedAPI = (data) => {
  return service({
    url: "/manage/invoice/isAlreadyInvoiced/" + data,
    method: "get",
  });
};

//获取调度线路绑定详情
export const queryScheduleLineAssociationVOInfo = (data) => {
  return service({
    url:
      "/manage/scheduleLineAssociation/queryScheduleLineAssociationVOInfo/" +
      data,
    method: "get",
  });
};

//线路征集表分页
export const queryRouteCollectionPage = (data) => {
  return service({
    url: "/manage/routecollection/queryRouteCollectionPage",
    method: "post",
    data,
  });
};

//添加定制客运和汽车线路关联
export const saveCustomLineIntercityAssociation = (data) => {
  return service({
    url: "/manage/customlineintercityassociation/saveCustomLineIntercityAssociation",
    method: "post",
    data,
  });
};

//更新定制客运和汽车线路关联
export const updateCustomLineIntercityAssociation = (data) => {
  return service({
    url: "/manage/customlineintercityassociation/updateCustomLineIntercityAssociation",
    method: "put",
    data,
  });
};

//定制客运和汽车线路关联分页
export const queryCustomLineIntercityAssociationPage = (data) => {
  return service({
    url: "/manage/customlineintercityassociation/queryCustomLineIntercityAssociationPage",
    method: "post",
    data,
  });
};

//删除定制客运和汽车线路关联
export const deleteCustomLineIntercityAssociationById = (data) => {
  return service({
    url:
      "/manage/customlineintercityassociation/deleteCustomLineIntercityAssociationById?id=" +
      data,
    method: "delete",
  });
};

//分账概览表列表
export const queryKkLedgerStatisticsList = (data) => {
  return service({
    url: "/order/kkledgerstatistics/queryKkLedgerStatisticsList",
    method: "post",
    data,
  });
};

//导出分账概览表
export const exportKkLedgerStatistics = (data) => {
  return service({
    url: "/order/kkledgerstatistics/exportKkLedgerStatistics",
    method: "post",
    data,
    responseType: "blob",
  });
};

//乘客数量监测设置分页
export const queryPassengerDetectionSettingPage = (data) => {
  return service({
    url: "/manage/passengerDetectionSetting/queryPassengerDetectionSettingPage",
    method: "post",
    data,
  });
};

//添加乘客数量监测设置
export const savePassengerDetectionSetting = (data) => {
  return service({
    url: "/manage/passengerDetectionSetting/savePassengerDetectionSetting",
    method: "post",
    data,
    array: true,
  });
};

//更改开启监控状态
export const changeOpenMonitor = (data) => {
  return service({
    url: "/manage/passengerDetectionSetting/changeOpenMonitor",
    method: "post",
    data,
  });
};

//获取乘客数量监测设置详情
export const queryPassengerDetectionSettingInfo = (data) => {
  return service({
    url: "/manage/passengerDetectionSetting/queryPassengerDetectionSettingInfo",
    method: "get",
    data,
  });
};

//获取监控区域配置信息
export const findAreaSettingBySettingId = (data) => {
  return service({
    url: "/manage/passengerDetectionSetting/findAreaSettingBySettingId",
    method: "get",
    data,
  });
};

//添加区域设置
export const addAreaSetting = (data) => {
  return service({
    url: "/manage/passengerDetectionSetting/addAreaSetting",
    method: "post",
    data,
  });
};

//更新区域设置
export const updateAreaSetting = (data) => {
  return service({
    url: "/manage/passengerDetectionSetting/updateAreaSetting",
    method: "put",
    data,
  });
};

//删除区域设定
export const delAreaSetting = (data) => {
  return service({
    url: "/manage/passengerDetectionSetting/delAreaSetting",
    method: "delete",
    data,
  });
};

//修改车辆拍照通道
export const updateTakeVideoChanelNumber = ({ data, id }) => {
  return service({
    url: "/manage/vehicle/updateTakeVideoChanelNumber/" + id,
    method: "put",
    data,
  });
};

//获取乘客数量监测记录详情
export const queryPassengerDetectionRecordInfo = (data) => {
  return service({
    url: "/manage/passengerDetectionRecord/queryPassengerDetectionRecordInfo",
    method: "get",
    data,
  });
};

//获取乘客数量监测记录分页
export const queryPassengerDetectionRecordPage = (data) => {
  return service({
    url: "/manage/passengerDetectionRecord/queryPassengerDetectionRecordPage",
    method: "post",
    data,
  });
};

//获取异常车辆数据
export const getAbnormalVehicle = (data) => {
  return service({
    url: "/manage/passengerDetectionRecord/getAbnormalVehicle",
    method: "post",
    data,
  });
};

//线路推荐列表
export const queryLineRecommendList = (data) => {
  return service({
    url: "/manage/linerecommend/queryLineRecommendList",
    method: "post",
    data,
  });
};

//删除线路推荐
export const deleteLineRecommendById = (data) => {
  return service({
    url: "/manage/linerecommend/deleteLineRecommendById",
    method: "delete",
    data,
  });
};

//添加线路推荐
export const saveLineRecommend = (data) => {
  return service({
    url: "/manage/linerecommend/saveLineRecommend",
    method: "post",
    data,
  });
};

//帮助中心树
export const helpCenterTree = (data) => {
  return service({
    url: "/manage/helpcenter/tree",
    method: "post",
    data,
  });
};

//删除帮助中心
export const deleteHelpCenterById = (data) => {
  return service({
    url: "/manage/helpcenter/deleteHelpCenterById",
    method: "delete",
    data,
  });
};

//获取帮助中心详情(仅限当前节点)
export const queryHelpCenterInfo = (data) => {
  return service({
    url: "/manage/helpcenter/queryHelpCenterInfo",
    method: "get",
    data,
  });
};

//更新帮助中心
export const updateHelpCenter = (data) => {
  return service({
    url: "/manage/helpcenter/updateHelpCenter",
    method: "put",
    data,
  });
};

//添加帮助中心
export const saveHelpCenter = (data) => {
  return service({
    url: "/manage/helpcenter/saveHelpCenter",
    method: "post",
    data,
  });
};

//城际专车管理/班线管理 通过线路获取公司下面 未绑定的 去程线路
export const lineListTrip = (data) => {
  return service({
    url: "/manage/line/listTrip",
    method: "get",
    data,
  });
};

//9.0.5 线路配置 根据线路id查询授权配置
export const lineAuthorizeConfigByLineId = (data) => {
  return service({
    url: "/manage/lineAuthorizeConfig/listByLineId",
    method: "get",
    data,
  });
};
//9.0.5 获取授权系统枚举
export const getSystemNoList = (data) => {
  return service({
    url: "/manage/lineAuthorizeConfig/getSystemNoList",
    method: "get",
    data,
  });
};
//添加或修改授权配置
export const addOrUpdateLineAuthorizeConfig = (data) => {
  return service({
    url: "/manage/lineAuthorizeConfig/addOrUpdate",
    method: "post",
    data,
  });
};
//删除授权
export const deleteLineAuthorizeConfig = (data) => {
  return service({
    url: "/manage/lineAuthorizeConfig/delete",
    method: "get",
    data,
  });
};

//发票管理/申请开票记录分页
export const queryPassengerApplyInvoiceRecordPage = (data) => {
  return service({
    url: "/manage/passengerApplyInvoiceRecord/queryPassengerApplyInvoiceRecordPage",
    method: "post",
    data,
  });
};

//发票管理/申请开票记录分页导出
export const exportPassengerApplyInvoiceRecord = (data) => {
  return service({
    url: "/manage/passengerApplyInvoiceRecord/exportPassengerApplyInvoiceRecord",
    method: "post",
    data,
    responseType: "blob",
  });
};

//资金对账汇总
export const fundStatementSummaryPage = (data) => {
  return service({
    url: "/manage/ledgerstatement/fundStatementSummary",
    method: "get",
    data,
  });
};

//交易对账单分页
export const queryTransactionStatementPage = (data) => {
  return service({
    url: "/manage/transactionstatement/queryTransactionStatementPage",
    method: "post",
    data,
  });
};

//分账对账单分页
export const queryLedgerStatementPage = (data) => {
  return service({
    url: "/manage/ledgerstatement/queryLedgerStatementPage",
    method: "post",
    data,
  });
};

//服务费设置 - 分页查询
export const queryServiceFeeConfigurationPage = (data) => {
  return service({
    url: "/manage/servicefeeconfiguration/queryServiceFeeConfigurationPage",
    method: "post",
    data,
  });
};

//结算订单是否包含取消接驾订单
export const updateReception = (data) => {
  return service({
    url: "/manage/channel/updateReception",
    method: "put",
    data,
  });
};

//服务费设置 - 更新
export const updateServiceFeeConfiguration = (data) => {
  return service({
    url: "/manage/servicefeeconfiguration/updateServiceFeeConfiguration",
    method: "put",
    data,
  });
};

//汽车票查询车站列表
export const getStationList = (data) => {
  return service({
    url: "/manage/servicefeeconfiguration/getStationList",
    method: "get",
    data,
  });
};

//定制客运结算明细
export const queryFlowPage = (data) => {
  return service({
    url: "/manage/intercityDetailsFlow/queryFlowPage",
    method: "post",
    data,
  });
};

//汽车票结算明细  分页
export const queryBusFlowPage = (data) => {
  return service({
    url: "/manage/automobileDetailsFlow/queryFlowPage",
    method: "post",
    data,
  });
};

//经营明细  分页
export const queryBusinessDetailsPage = (data) => {
  return service({
    url: "/manage/businessstatistics/queryBusinessDetailsPage",
    method: "post",
    data,
  });
};

//经营结算  分页
export const queryBusinessStatisticsPage = (data) => {
  return service({
    url: "/manage/businessstatistics/queryBusinessStatisticsPage",
    method: "post",
    data,
  });
};

//订单结算  分页
export const queryOrderBusinessStatisticsPage = (data) => {
  return service({
    url: "/manage/businessstatistics/queryOrderBusinessStatisticsPage",
    method: "post",
    data,
  });
};

//确认 订单结算 经营结算
export const businessStatisticsConfirm = (data) => {
  return service({
    url: "/manage/businessstatistics/confirm",
    method: "put",
    data,
  });
};

//结算 订单结算 经营结算
export const businessStatisticsSettlement = (data) => {
  return service({
    url: "/manage/businessstatistics/settlement",
    method: "put",
    data,
  });
};

//订单结算 导出
export const exportOrderBusinessStatisticsPage = (data) => {
  return service({
    url: "/manage/businessstatistics/exportBusinessOrderStatistics",
    method: "post",
    data,
    responseType: "blob",
  });
};

//定制客运结算明细 导出
export const exportFlowDZKY = (data) => {
  return service({
    url: "/manage/intercityDetailsFlow/exportFlow",
    method: "post",
    data,
    responseType: "blob",
  });
};

//汽车票结算明细  导出
export const exportFlowQCP = (data) => {
  return service({
    url: "/manage/automobileDetailsFlow/exportFlow",
    method: "post",
    data,
    responseType: "blob",
  });
};

//经营结算 导出
export const exportBusinessStatistics = (data) => {
  return service({
    url: "/manage/businessstatistics/exportBusinessStatistics",
    method: "post",
    data,
    responseType: "blob",
  });
};

//经营明细 导出
export const exportBusinessDetails = (data) => {
  return service({
    url: "/manage/businessstatistics/exportBusinessDetails",
    method: "post",
    data,
    responseType: "blob",
  });
};

//查询电召订票规则
export const queryElectriCallRegulation = (data) => {
  return service({
    url: "/manage/electriccallregulation/get",
    method: "get",
    data,
  });
};

//修改电召订票规则
export const updateElectriCallRegulation = (data) => {
  return service({
    url: "/manage/electriccallregulation/put",
    method: "put",
    data,
  });
};

//查询乘车码规则
export const getAllRegulationByChannelNo = (data) => {
  return service({
    url: "/manage/intercityPassengerCertificateRegulation/getAllRegulationByChannelNo",
    method: "get",
    data,
  });
};
//修改乘车码规则信息
export const updateChannelNoRegulation = (data) => {
  return service({
    url: "/manage/intercityPassengerCertificateRegulation/updateChannelNoRegulation",
    method: "put",
    data,
  });
};
//查询司机端规则
export const getAllDriverRegulationByChannelNo = (data) => {
  return service({
    url: "/manage/intercityDriverRegulation/getAllRegulationByChannelNo",
    method: "get",
    data,
  });
};
//修改司机端规则
export const updateDriverChannelNoRegulation = (data) => {
  return service({
    url: "/manage/intercityDriverRegulation/updateChannelNoRegulation",
    method: "put",
    data,
  });
};
//司机补现明细
export const queryDriverCashRecordPage = (data) => {
  return service({
    url: "/manage/drivercashrecord/queryDriverCashRecordPage",
    method: "post",
    data,
  });
};

//导出司机补现明细
export const exportDriverCashRecord = (data) => {
  return service({
    url: "/manage/drivercashrecord/exportDriverCashRecord",
    method: "post",
    data,
    responseType: "blob",
  });
};

//电召收现分账
export const ledgerQueryDriverCashRecordPage = (data) => {
  return service({
    url: "/manage/drivercashrecord/Ledger/queryDriverCashRecordPage",
    method: "post",
    data,
  });
};

//导出电召收现分账
export const ledgerExportDriverCashRecord = (data) => {
  return service({
    url: "/manage/drivercashrecord/Ledger/exportDriverCashRecord",
    method: "post",
    data,
    responseType: "blob",
  });
};

//电召下单-行程信息 5个订单信息
export const queryElectricCallingPage = (data) => {
  return service({
    url: "/order/intercity/electricCallingPage",
    method: "post",
    data,
  });
};

//司机补现统计 导出
export const exportStatistics = (data) => {
  return service({
    url: "/manage/drivercashrecord/exportStatistics",
    method: "post",
    data,
    responseType: "blob",
  });
};

//司机补现统计
export const queryStatisticsPage = (data) => {
  return service({
    url: "/manage/drivercashrecord/queryStatisticsPage",
    method: "post",
    data,
  });
};

//缓存数据列表
export const queryKkPassengerCacheList = (data) => {
  return service({
    url: "/order/kkpassengercache/queryKkPassengerCacheList",
    method: "post",
    data,
  });
};

//添加缓存数据
export const saveOrUpdate = (data) => {
  return service({
    url: "/order/kkpassengercache/saveOrUpdate",
    method: "post",
    data,
  });
};

//查询商户分账接收方
export const listRedisCache = (data) => {
  return service({
    url: "/manage/kkWechatLedgerReceive/listRedisCache",
    method: "get",
    data,
  });
};

//服务号业务代码配置分页
export const queryMpBusinessCodePage = (data) => {
  return service({
    url: `/manage/mp-business-code/queryMpBusinessCodePage`,
    method: "post",
    data,
  });
};

//新增服务号业务代码
export const saveMpBusinessCode = (data) => {
  return service({
    url: `/manage/mp-business-code/saveMpBusinessCode`,
    method: "post",
    data,
  });
};

//服务号渠道配置分页
export const queryMpLicensePage = (data) => {
  return service({
    url: `/manage/mp-license/queryMpLicensePage`,
    method: "post",
    data,
  });
};

//添加服务号渠道配置
export const saveMpLicense = (data) => {
  return service({
    url: `/manage/mp-license/saveMpLicense`,
    method: "post",
    data,
  });
};

//更新服务号渠道配置
export const updateMpLicense = (data) => {
  return service({
    url: `/manage/mp-license/updateMpLicense`,
    method: "put",
    data,
  });
};

//服务号模板配置分页
export const queryMpModelPage = (data) => {
  return service({
    url: `/manage/mp-model/queryMpModelPage`,
    method: "post",
    data,
  });
};

//添加服务号模板配置
export const saveMpModel = (data) => {
  return service({
    url: `/manage/mp-model/saveMpModel`,
    method: "post",
    data,
  });
};

//更新服务号模板配置
export const updateMpModel = (data) => {
  return service({
    url: `/manage/mp-model/updateMpModel`,
    method: "put",
    data,
  });
};

//删除服务号模板配置
export const deleteMpModelById = (data) => {
  return service({
    url: `/manage/mp-model/deleteMpModelById`,
    method: "delete",
    data,
  });
};

//渠道独立微信支付证书管理分页
export const independentWechatPage = (data) => {
  return service({
    url: `/manage/wechat/certificate/independentWechatPage`,
    method: "post",
    data,
  });
};

//禁用指定的商户配置信息
export const mchStatusDisableUpdate = (data) => {
  return service({
    url: `/manage/wechat/certificate/mchStatusDisableUpdate/` + data,
    method: "put",
    data,
  });
};

//------------------------财务管理-----------------------------

//客运凭单
export const passengerVoucherPage = (data) => {
  return service({
    url: `/order/ticket_checked/page_company_summary`,
    method: "post",
    data,
  });
};

//凭单
export const voucherTableAPI = (data) => {
  return service({
    url: `/order/ticket_checked/get_ticketed_merchant_sum`,
    method: "get",
    data,
  });
};

//单车明细
export const vehicleDetialPage = (data) => {
  return service({
    url: `/order/ticket_checked/page_car_summary`,
    method: "post",
    data,
  });
};

//驾驶员明细
export const driverDetialPage = (data) => {
  return service({
    url: `/order/ticket_checked/page_driver_summary`,
    method: "post",
    data,
  });
};

//线路明细
export const lineDetialPage = (data) => {
  return service({
    url: `/order/ticket_checked/page_line_summary`,
    method: "post",
    data,
  });
};

//明细
export const queryDetialPage = (data) => {
  return service({
    url: `/order/ticket_checked/query_ticket_checked_detail_page`,
    method: "post",
    data,
  });
};

//其他收入统计
export const incomeStatisticsPage = (data) => {
  return service({
    url: `/order/income/sum_page`,
    method: "post",
    data,
  });
};

//其他收入明细
export const incomeDetialPage = (data) => {
  return service({
    url: `/order/income/page`,
    method: "post",
    data,
  });
};

//分账明细
export const ledgerDetialPage = (data) => {
  return service({
    url: `/order/ledger_detail/page`,
    method: "post",
    data,
  });
};

//分账账单
export const ledgerBillPage = (data) => {
  return service({
    url: `/order/ledger_detail/page_sum`,
    method: "post",
    data,
  });
};

//导出明细
export const ledgerDetialPageExport = (data) => {
  return service({
    url: `/order/ledger_detail/export_page`,
    method: "post",
    data,
    responseType: "blob",
  });
};

//导出分账账单
export const ledgerBillPageExport = (data) => {
  return service({
    url: `/order/ledger_detail/export_sum_page`,
    method: "post",
    data,
    responseType: "blob",
  });
};
