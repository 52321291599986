<template>
  <!-- 包车业务弹窗 -->
  <div class="charterPopup">
    <!-- content容器 -->
    <div class="manual-content" :style="'width:' + width + 'px'">
      <!-- header -->
      <div class="header">
        <div class="text">{{ headerText }}</div>
        <div class="close" @click="closeFun()"></div>
      </div>
      <!-- content -->
      <div class="content content-1" :style="{ 'max-height': maxHeight }">
        <div class="box">
          <slot></slot>
        </div>
      </div>
      <!-- footer -->
      <div class="footer">
        <div>
          <el-button v-if="!disabled" class="enter" @click="confirmFun">
            {{ confirmText }}
          </el-button>
          <el-button @click="closeFun">{{ cancelText }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    headerText: {
      // 弹窗标题
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [String, Number],
      default: 480,
    },
    cancelText: {
      type: String,
      default: "取消",
    },
    confirmText: {
      type: String,
      default: "确认",
    },
    maxHeight: {
      type: String,
      default: "500px",
    },
  },
  data() {
    return {};
  },
  methods: {
    //   确认回调按钮
    confirmFun() {
      this.$emit("confirmFun");
    },
    //   关闭窗口
    closeFun() {
      this.$emit("closePopupFun", false);
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-input__inner {
  line-height: 20px;
}
/deep/.el-upload-list__item {
  transition: none !important;
}
/deep/.el-form-item__label {
  line-height: 40px !important;
  padding-right: 10px;
}
.content {
  padding-bottom: 1px;
  max-height: 520px;
  overflow: auto;
  .box {
    margin-top: 10px;
  }
  .row {
    width: 100%;
    height: 32px;
    margin: 10px 0;
    .row-left {
      width: 120px;
      height: 32px;
      text-align: right;
      line-height: 32px;
      padding-right: 14px;
      float: left;
      span {
        color: red;
      }
    }
    .row-right {
      width: calc(100% - 200px);
      float: left;
      height: 32px;
      line-height: 32px;
      span {
        display: inline-block;
        margin-right: 10px;
      }
      .content-2-input {
        margin-right: 10px;
        width: 80px;
      }
    }
  }
}
.charterPopup {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 777;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  .manual-content {
    background: #ffffff;
    border-radius: 6px;
    position: relative;
    padding: 61px 0;
  }
}
.header {
  padding: 18px 24px;
  height: 24px;
  position: absolute;
  left: 0;
  width: calc(100% - 48px);
  top: 0;
  border-bottom: 1px solid #d7d7d7;
  .text {
    margin-top: 8px;
  }
  .close {
    cursor: pointer;
    width: 12px;
    height: 12px;
    background: url("../../assets/images/home/close.png") no-repeat;
    background-size: 100%;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.footer {
  padding: 10px 24px 18px 24px;
  height: 32px;
  position: absolute;
  left: 0;
  width: calc(100% - 48px);
  border-bottom: none !important;
  border-top: 1px solid #d7d7d7;
  bottom: 0 !important;
  .el-button {
    float: right;
    margin-left: 14px;
  }
  .enter {
    background: #336fee;
    color: #ffffff;
  }
}

/deep/ .el-upload--picture-card {
  width: 95px;
  height: 95px;
}
/deep/ .el-upload {
  width: 95px;
  height: 95px;
  line-height: 95px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 95px;
  height: 95px;
  line-height: 95px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 95px;
  height: 95px;
  line-height: 95px;
}
/deep/ .avatar {
  width: 95px;
  height: 95px;
}
</style>
