<template>
  <el-container>
    <router-view />
  </el-container>
</template>
<script>

export default {
  data() {
    return {}
  },
  mounted() {},
  watch: {},

  created() {},
  methods: {}
}
</script>
<style>
.container {
  width: 100%;
}
</style>
