const config = {
  // 广运神马
  dev_gysm: {
    baseUrl: "https://testgytravel.scgycx.net/",
    imgURL: "https://testgytravel.scgycx.net/gyImages/",
    apiBaseUrl: "https://testgytravel.scgycx.net/api/",
    tese: "47.99.134.236:16803//39.104.71.7:12901//192.168.0.163:8082/gps/|| 192.168.0.158:12908  39.104.179.71",
    websocketURL: "wss://testgytravel.scgycx.net/adminSocket/",
    serviceHost: "https://testgytravel.scgycx.net/_AMapService",
    channelNo: "KK_XCX_GYSM",
    companyText: "Copyright © 2024 广元交发科技有限公司",
    filingsText: "蜀ICP备19011569号-1",
    projectText: "广运神马管理平台",
    projectEngText: "Guangyuan travel management platform",
    logoImg: "solutions.png",
    logoLabelIco: "its.ico",
    vision:"9.0.1",
  },
  pro_gysm: {
    baseUrl: "https://gytravel.scgycx.net/",
    imgURL: "https://gytravel.scgycx.net/gyImages/",
    apiBaseUrl: "https://gytravel.scgycx.net/api/",
    tese: "47.99.134.236:16803//39.104.71.7:12901//192.168.0.163:8082/gps/|| 192.168.0.158:12908  39.104.179.71",
    websocketURL: "wss://gytravel.scgycx.net/adminSocket/",
    serviceHost: "https://gytravel.scgycx.net/_AMapService",
    channelNo: "KK_XCX_GYSM",
    companyText: "Copyright © 2024 广元交发科技有限公司",
    filingsText: "蜀ICP备19011569号-1",
    projectText: "广运神马管理平台",
    projectEngText: "Guangyuan travel management platform",
    logoImg: "solutions.png",
    logoLabelIco: "its.ico",
    vision:"9.0.1",
  },
  // 快客行
  dev_kkx: {
    baseUrl: "https://testtravel.inheritech.top/",
    imgURL: "https://testtravel.inheritech.top/gyImages/",
    apiBaseUrl: "https://testtravel.inheritech.top/api/",
    tese: "47.99.134.236:16803//39.104.71.7:12901//192.168.0.163:8082/gps/|| 192.168.0.158:12908  39.104.179.71",
    websocketURL: "wss://testtravel.inheritech.top/adminSocket/",
    serviceHost: "https://testtravel.inheritech.top/_AMapService",
    channelNo: "KK_XCX_KKX",
    companyText: "Copyright © 2021 四川英海瑞特科技有限公司",
    filingsText: "蜀ICP备12028604号-9",
    projectText: "快客行管理平台",
    projectEngText: "Quick transit management platform",
    logoImg: "kuaike.svg",
    logoLabelIco: "kuaike.ico",
    vision:"9.0.1",
  },
  pro_kkx: {
    baseUrl: "https://travel.inheritech.top/",
    imgURL: "https://travel.inheritech.top/gyImages/",
    apiBaseUrl: "https://travel.inheritech.top/api/",
    tese: "47.99.134.236:16803//39.104.71.7:12901//192.168.0.163:8082/gps/|| 192.168.0.158:12908  39.104.179.71",
    websocketURL: "wss://travel.inheritech.top/adminSocket/",
    serviceHost: "https://travel.inheritech.top/_AMapService",
    channelNo: "KK_XCX_KKX",
    companyText: "Copyright © 2021 四川英海瑞特科技有限公司",
    filingsText: "蜀ICP备12028604号-9",
    projectText: "快客行管理平台",
    projectEngText: "Quick transit management platform",
    logoImg: "kuaike.svg",
    logoLabelIco: "kuaike.ico",
    vision:"9.0.1",
  },
  // 巴适到家
  pro_bsdj: {
    baseUrl: "https://travel.bsdjcx.cn/",
    imgURL: "https://travel.bsdjcx.cn/gyImages/",
    apiBaseUrl: "https://travel.bsdjcx.cn/api/",
    tese: "47.99.134.236:16803//39.104.71.7:12901//192.168.0.163:8082/gps/|| 192.168.0.158:12908  39.104.179.71",
    websocketURL: "wss://travel.bsdjcx.cn.cn/adminSocket/",
    serviceHost: "https://travel.bsdjcx.cn/_AMapService",
    channelNo: "KK_XCX_BSDJ",
    companyText: "Copyright © 2024 沐川县巴适到家汽车运输有限公司",
    filingsText: "蜀ICP备2024045003号",
    projectText: "巴适到家出行管理平台",
    projectEngText: "Bashi's Home Travel Management Platform",
    logoImg: "bsdjlogo.png",
    logoLabelIco: "bsdj.ico",
    vision:"9.0.1",
  },
};

const mode = process.env.NODE_ENV;

export default config[mode];
